import { render, staticRenderFns } from "./ApprovalFormSetting.vue?vue&type=template&id=f209d88e&scoped=true&"
import script from "./ApprovalFormSetting.vue?vue&type=script&lang=ts&"
export * from "./ApprovalFormSetting.vue?vue&type=script&lang=ts&"
import style0 from "./ApprovalFormSetting.vue?vue&type=style&index=0&id=f209d88e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f209d88e",
  null
  
)

export default component.exports