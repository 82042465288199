
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon from "@/mixin/VueHoduCommon";

@Component({

}) export default class MessageModal extends Mixins(VueHoduCommon) {

    is_closeable_by_enter : boolean = false;
    check_flag : boolean = false;

    beforeMount() : void {
        // Modal 띄우기 전 기존 포커스 없애기
        let element : Element | null = document.activeElement
        if( element != null ) { $(element).blur(); }
    }

    mounted() {
        this.$nextTick(() => {
            // 버튼이 1개라면 0.5초 정도 기다린 이후 엔터를 누르면 닫히도록 설정
            if( this.message_modal_info.button_text != null && this.message_modal_info.button_text.length == 1 ) {
                setTimeout(() => {
                    this.is_closeable_by_enter = true; 
                    window.addEventListener("keydown", this.firstButtonEnterKey);
                }, 500);
            }
        });
    }

    beforeDestroy() {
        window.removeEventListener("keydown", this.firstButtonEnterKey);
    }

    /**
     * 왼쪽에서부터 첫번째 버튼 클릭 
     */
    async firstButtonClick() : Promise<void> {
        await this.doSetMessageModalInfo({
            "show_message_modal" : false,
            "message_type" : "",
            "message" : "",
            "button_text" : []
        });

        await window['messageModalFirstButtonClick'](this.check_flag);
    }

    /**
     * 왼쪽에서부터 두번째 버튼 클릭
     */
    async secondButtonClick() : Promise<void> {
        await this.doSetMessageModalInfo({
            "show_message_modal" : false,
            "message_type" : "",
            "message" : "",
            "button_text" : []
        });

        await window['messageModalSecondButtonClick'](this.check_flag);
    }

    /**
     * 왼쪽에서부터 세번째 버튼 클릭
     */
    async thirdButtonClick() : Promise<void> {
        await this.doSetMessageModalInfo({
            "show_message_modal" : false,
            "message_type" : "",
            "message" : "",
            "button_text" : []
        });

        await window['messageModalThirdButtonClick'](this.check_flag);
    }

    /**
     * 버튼이 1개일때 엔터키를 눌렀다면 자동으로 닫히도록
     */
    async firstButtonEnterKey(event : any) {
        if( event.keyCode != 13 || this.is_closeable_by_enter == false ) { return; }
        
        this.doSetMessageModalInfo({
            "show_message_modal" : false,
            "message_type" : "",
            "message" : "",
            "button_text" : []
        });
        
        await window['messageModalFirstButtonClick'](this.check_flag);
    }

}
