
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_modal_interface } from '@/model/organization';
import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class OrganizationOffModal extends Mixins(VueHoduCommon) {

    get computedEmpInfo() {

        let emp : any[] = [];

        if( this.employee != null ) {

            const now = moment().format("YYYYMMDD");

            const filter = this.employee.past_emp_info.filter(emp_info => (Number(emp_info.annual_start) <= Number(now) && Number(now) <= Number(emp_info.annual_end)) ) 

            if( filter.length < 1 ) return emp;

            const current_emp_info = filter[0];
            const current_emp_info_index : number = this.employee.past_emp_info.indexOf(current_emp_info);

            emp.push(this.employee.past_emp_info[current_emp_info_index]);
            if( this.employee.past_emp_info.length > (current_emp_info_index + 1) ) {
                emp.push(this.employee.past_emp_info[current_emp_info_index + 1]);
            }
        }

        return emp;
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_off_modal_info !: organization_modal_interface.OrganizationOffModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationOffModalInfo ?: (params : organization_modal_interface.OrganizationOffModalInfo) => void;

    employees : any[] = [];
    employee : any = null;
    
    start_text : string = "";
    end_text : string = "";

    select_option_key : string = "";
    annual_count : string = '15';
    before_annual_count : string = '0';

    is_list : boolean = true;
    is_add : boolean = false;

    copy_annual_info : any = null;     // 추가, 수정시 원래 정보
    selected_annual_info : any = null; // 입력된 값 정보

    async mounted() : Promise<void> {
        // // 여러명 세팅
        // if( this.organization_off_modal_info.user_ids != null && this.organization_off_modal_info.user_ids.length > 0 ) {
        //     this.employee = { "annual_start" : null, "annual_end" : null, "annual_count" : null };
        //     const current_year = new Date().getFullYear();

        //     if( this.employee.annual_start == null ) this.employee.annual_start = `${current_year}0101`;
        //     if( this.employee.annual_end == null ) this.employee.annual_end = `${current_year}1231`;

        //     this.processDateText();

        //     await this.getOrganizationEmp();
        // }
        // // 단일 세팅
        // else {
        //     await this.getOrganizationEmp();
        // }
        // this.datepickerInit();

        await this.getOrganizationEmp();
    }   

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp?user_id=${this.organization_off_modal_info.user_id}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 직원 조회 중 오류 발생");
            }

            if( response.data.data.emp_info.past_emp_info != null && response.data.data.emp_info.past_emp_info.length > 0 ) {
                response.data.data.emp_info.past_emp_info.sort((o1, o2) : number => {
                    const o1_annual_start = o1.annual_start;
                    const o2_annual_start = o2.annual_end;

                    if( o1_annual_start > o2_annual_start ) return 1;
                    if( o1_annual_start < o2_annual_start ) return -1;
                    return 0;
                });
            }

            this.employee = JSON.parse(JSON.stringify(response.data.data.emp_info));

            // if( this.start_text == '' || this.end_text == '' ) {
            //     const current_year = new Date().getFullYear();

            //     if( this.employee.annual_start == null ) this.employee.annual_start = `${current_year}0101`;
            //     if( this.employee.annual_end == null ) this.employee.annual_end = `${current_year}1231`;

            //     this.processDateText();
            // }
        
        } catch(e) {
            alert("직원 정보 조회 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }

        // try {
            
        //     // 1명 연차 설정인 경우
        //     if( !(this.organization_off_modal_info.user_ids != null && this.organization_off_modal_info.user_ids.length > 0) ) {
        //         const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp?user_id=${this.organization_off_modal_info.user_id}`, API_METHOD.GET);

        //         console.log(response);

        //         if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
        //             throw new Error("조직도 직원 조회 중 오류 발생");
        //         }

        //         this.employee = JSON.parse(JSON.stringify(response.data.data.emp_info));

        //         if( this.start_text == '' || this.end_text == '' ) {
        //             const current_year = new Date().getFullYear();

        //             if( this.employee.annual_start == null ) this.employee.annual_start = `${current_year}0101`;
        //             if( this.employee.annual_end == null ) this.employee.annual_end = `${current_year}1231`;

        //             this.processDateText();
        //         }
        //         return;
        //     }

        //     // 여러명 연차 설정인 경우
        //     const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET);

        //     console.log(response);

        //     if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
        //         throw new Error("조직도 직원 조회 중 오류 발생");
        //     }

        //     this.employees.splice(0, this.employees.length);
        //     this.employees = this.employees.concat(response.data.data.emp_info);
            
        // } catch(e) {
        //     alert("직원 정보 조회 중 오류 발생");
        //     this.hodu_error_process(e, false, false, true);
        // }
    }

    /**
     * datepicker 설정
     */
    datepickerInit() : void {
        this.$nextTick(() => {
            const datepicker_option = {
                inline: false,
                showOtherMonths: true,
                selectOtherMonths: true,
                dateFormat: 'yy-mm-dd',
                monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                yearSuffix : '.',
                blankSpace : '',
                changeYear : true,
                yearRange  : '1900:2050',
                onSelect: (dateText, inst) => {
                    const selected_date = new Date(dateText);
                    inst.input.val(`${this.hodu_date_to_format_string(selected_date, "YYYY.MM.DD")}`);
                    
                    if( inst.id == 'modal_annual_start' ) {
                        this.selected_annual_info.annual_start = moment(selected_date).format('YYYYMMDD');

                        // 기간 최소치 1달
                        const target_date = moment(selected_date).add('month', 1).toDate();
                        const annual_end  = moment([this.selected_annual_info.annual_end.substring(0,4), this.selected_annual_info.annual_end.substring(4,6), this.selected_annual_info.annual_end.substring(6,8)].join('.')).toDate();

                        if( target_date.getTime() > annual_end.getTime() ) {
                            this.selected_annual_info.annual_end = moment(target_date).format('YYYYMMDD'); 
                        }
                    }

                    else if( inst.id == 'modal_annual_end' ) {
                        this.selected_annual_info.annual_end = moment(selected_date).format('YYYYMMDD');

                        // 기간 최소치 1달
                        const target_date = moment(selected_date).add('month', -1).toDate();
                        const annual_start  = moment([this.selected_annual_info.annual_start.substring(0,4), this.selected_annual_info.annual_start.substring(4,6), this.selected_annual_info.annual_start.substring(6,8)].join('.')).toDate();

                        if( target_date.getTime() < annual_start.getTime() ) {
                            this.selected_annual_info.annual_start = moment(target_date).format('YYYYMMDD'); 
                        }
                    }

                    this.processDateText();

                    if( this.is_add == true ) {
                        this.select_option_key = `${this.selected_annual_info.annual_start}-${this.selected_annual_info.annual_end}-add`;
                    }
                },
            }

            // @ts-ignore
            $('#modal_annual_start').datepicker(datepicker_option);

            // @ts-ignore
            $('#modal_annual_end').datepicker(datepicker_option);
        });
    }

    /**
     * 날짜 텍스트 가공
     */
    processDateText() : void {
        if( this.selected_annual_info == null ) {
            this.start_text = "";
            this.end_text   = "";
            return;
        }
        this.start_text = [this.selected_annual_info.annual_start.substring(0,4), this.selected_annual_info.annual_start.substring(4,6), this.selected_annual_info.annual_start.substring(6,8)].join('.');
        this.end_text = [this.selected_annual_info.annual_end.substring(0,4), this.selected_annual_info.annual_end.substring(4,6), this.selected_annual_info.annual_end.substring(6,8)].join('.');
    }

    /**
     * 끝자리가 0이 안나오도록 가공
     */
    processDayCount(day_count : string) : string {

        if( typeof day_count != 'string' ) day_count = String(day_count);
        if( day_count.indexOf('.') == -1 ) return day_count;

        let is_find_except_zero = false;

        let process_day_count : string = "";

        const day_count_length : number = day_count.length;
        for( let i = (day_count_length - 1); i >= 0; i-- ) {
            const char = day_count[i];

            if( char == '0' && is_find_except_zero == true ) {
                process_day_count = `${char}${process_day_count}`;
                continue;
            }

            if( char != '0' ) {
                is_find_except_zero = true;
                process_day_count = `${char}${process_day_count}`;
            }
        }   

        // 마지막이 . 이면 제거
        if( process_day_count[process_day_count.length - 1] == '.' ) {
            process_day_count = process_day_count.substring(0, process_day_count.length - 1);
        }
        
        return process_day_count;
    }

    /**
     * 연차 기간 선택
     */
    selectAnnualInfo(emp) : void {
        this.selected_annual_info = JSON.parse(JSON.stringify(emp));
        this.copy_annual_info = JSON.parse(JSON.stringify(this.selected_annual_info));
        this.select_option_key = `${this.selected_annual_info.annual_start}-${this.selected_annual_info.annual_end}`;
        this.annual_count = this.processDayCount(this.selected_annual_info.annual_count);
        this.before_annual_count = this.processDayCount(this.selected_annual_info.contents.before_annual_count);
        this.is_list = false;
        this.is_add = false;
        this.processDateText();
        this.datepickerInit();
    }

    /**
     * select - option 선택값 변경 함수
     */
    async selectChanged(event) : Promise<void> {
        const value = event.target.value;

        if( value == '' ) {
            this.close();
        }
        else {

            // 변경점 체크
            if( this.is_list == false && this.changeCheck() == true ) {
                return;
            }

            let emp = null;
            
            if( value == `${this.employee.annual_start}-${this.employee.annual_end}` ) {
                emp = JSON.parse(JSON.stringify(this.employee));
            }

            for( const past_emp of this.employee.past_emp_info ) {
                if( value == `${past_emp.annual_start}-${past_emp.annual_end}` ) {
                    emp = JSON.parse(JSON.stringify(past_emp));
                    break;
                }
            }

            if( emp == null ) {
                alert("연차 기간을 찾지 못했습니다");
                await this.getOrganizationEmp();
                this.select_option_key = '';
            }

            this.selectAnnualInfo(emp);
        }
    }

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
    yyyymmddToDate(yyyymmdd : string) : Date {
        return new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));
    }

    /**
     * 호두웨어 사용 전 사용한 연차 수 항목 보이는지 여부
     */
    isExistBeforeAnnualCount() : boolean {
        if ( this.selected_annual_info == null ) return false;

        // 신규 생성시에는 무조건 안 보임
        if( this.is_add == true ) {
            return false;
        }

        // 수정중일때 아직 과거 연차기간이 없는 경우 무조건 보임
        if( this.employee.past_emp_info == null || this.employee.past_emp_info.length < 1 ) {
            return true;
        } 

        // 과거 연차 기간중 가장 과거의 항목에만 보임
        for( const past_emp of this.employee.past_emp_info ) {
            if( this.copy_annual_info.annual_start == past_emp.annual_start && this.copy_annual_info.annual_end == past_emp.annual_end ) {
                return this.employee.past_emp_info.indexOf(past_emp) == (this.employee.past_emp_info.length - 1);
            }
        }

        // 그 외 무조건 안 보임
        return false;
    }

    getProfileImage(emp) {
        if( emp.user_pic != null ) {
            return `/app_images/${emp.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((emp.user_id ? emp.user_id : 0) / 10000)}/${emp.user_id}.jpg`;
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        const jquery_target = $(event.target);
        const defaultImage = require('@/assets/images/contents/ic_approval_user_on.png');
        jquery_target.parent().find('p.img').css("background-image", `url(${defaultImage})`);
    }

    /**
     * 새로운 연차기간 추가
     * 1. 가장 미래의 연차기간의 annual_count를 그대로 들고감
     * 2. 가장 미래의 연차기간의 annual_end + 1일을 start로 지정하고 end는 그 1년 후로 지정함
     */
    add() : void {
        if( this.employee == null ) return;

        let target_annual_end_date = this.yyyymmddToDate(this.employee.annual_end);
        let target_annual_count = this.employee.annual_count;
        for( const past_annual of this.employee.past_emp_info ) {
            
            if( target_annual_end_date.getTime() < this.yyyymmddToDate(past_annual.annual_end).getTime() ) {
                target_annual_end_date = this.yyyymmddToDate(past_annual.annual_end);
                target_annual_count = past_annual.annual_count;
            }
        }
        
        const new_annaul_start_date = moment(target_annual_end_date).set('date', moment(target_annual_end_date).get('date') + 1).toDate();
        const new_annaul_end_date = moment(target_annual_end_date).add('year', 1).toDate();

        this.selected_annual_info = JSON.parse(JSON.stringify({
            annual_count : target_annual_count,
            annual_start : this.hodu_date_to_format_string(new_annaul_start_date, 'YYYYMMDD'),
            annual_end : this.hodu_date_to_format_string(new_annaul_end_date, 'YYYYMMDD')
        }));
        this.copy_annual_info = JSON.parse(JSON.stringify(this.selected_annual_info));

        this.select_option_key = `${this.selected_annual_info.annual_start}-${this.selected_annual_info.annual_end}-add`;
        this.annual_count = this.processDayCount(this.selected_annual_info.annual_count);
        this.before_annual_count = '0';

        this.is_list = false;
        this.is_add = true;
        this.processDateText();
        this.datepickerInit();
    }

    /**
     * 수정중일땐 변경점이 있는지 체크후 confirm, 추가중일땐 입력한 사항이 있는지 체크후 confirm
     * 계속 진행 : return false;
     * 멈춤 : return true;
     */
    changeCheck() : boolean {

        if( this.is_add == true ) {

            // 입력사항 체크후 confirm
            if( this.copy_annual_info.annual_start != this.selected_annual_info.annual_start || 
                this.copy_annual_info.annual_end != this.selected_annual_info.annual_end ||
                this.annual_count == '' || isNaN(Number(this.annual_count)) == true || Number(this.annual_count) < 0 ||
                Number(this.copy_annual_info.annual_count) != Number(this.annual_count) ) {
                
                if( confirm("저장하지 않고 진행하면 입력한 내용이 사라집니다\n계속 진행하시겠습니까?") == false ) {
                    this.select_option_key = `${this.selected_annual_info.annual_start}-${this.selected_annual_info.annual_end}-add`;
                    return true;
                }

            }

        } 
        else {

            // 입력사항 체크후 confirm
            if( this.copy_annual_info.annual_start != this.selected_annual_info.annual_start || 
                this.copy_annual_info.annual_end != this.selected_annual_info.annual_end ||
                this.annual_count == '' || isNaN(Number(this.annual_count)) == true || Number(this.annual_count) < 0 ||
                Number(this.copy_annual_info.annual_count) != Number(this.annual_count) ||
                this.before_annual_count == '' || isNaN(Number(this.before_annual_count)) == true || Number(this.before_annual_count) < 0 ||
                Number(this.copy_annual_info.contents.before_annual_count) != Number(this.before_annual_count) ) {

                // 수정사항 체크 confirm
                if( confirm("저장하지 않고 진행하면 입력한 내용이 사라집니다\n계속 진행하시겠습니까?") == false ) {
                    this.select_option_key = `${this.copy_annual_info.annual_start}-${this.copy_annual_info.annual_end}`;
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * 모달 닫기
     */
    close() : void {
        if( this.is_list == true ) {
            this.organization_off_modal_info.callback?.();
            this.doSetOrganizationOffModalInfo?.({ show_modal : false, user_id : 0 });
        }
        else {

            if( this.copy_annual_info == null ) {
                this.select_option_key = '';
                this.is_list = true;
                this.is_add = false;
                this.selected_annual_info = null;
            }

            else if( this.changeCheck() == false ) {
                this.select_option_key = '';
                this.is_list = true;
                this.is_add = false;
                this.selected_annual_info = null;
                this.copy_annual_info = null;
            }
            
        }
    }

    /**
     * 저장
     */
    async save() : Promise<void> {

        // 입력값 있는지 체크
        if( this.annual_count == '' || isNaN(Number(this.annual_count)) == true || Number(this.annual_count) < 0 ) {
            alert("연차수에는 양수인 숫자를 입력해주세요 (소수점 가능)");
            $('#yearOff').focus();
            return;
        }

        if( this.isExistBeforeAnnualCount() == true && ( this.before_annual_count == '' || isNaN(Number(this.before_annual_count)) == true ) || Number(this.annual_count) < 0 ) {
            alert("호두웨어 가입 전 사용 연차 수에는 양수인 숫자를 입력해주세요 (소수점 가능)");
            $('#before_yearOff').focus();
            return;
        }

        await this.getOrganizationEmp();

        // 새로운 연차 기간
        if( this.is_add == true ) { 
            // insert
            try {
                const data = JSON.parse(JSON.stringify(this.employee));
                data.annual_count = Number(this.annual_count);
                data.annual_start = this.selected_annual_info.annual_start;
                data.annual_end   = this.selected_annual_info.annual_end;

                delete(data.contents);

                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp?is_annual=true&is_update=false`, API_METHOD.POST, data);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("직원 연차 기간 추가 중 오류 발생");
                }

                this.copy_annual_info = null;

                await this.getOrganizationEmp();
                this.close();

            } catch(exception) {

                const e = (exception as any);

                // 연차기간 중복 오류
                if( e.response != null && e.response.data != null ) {
                    if( e.response.data.data.error_type ==  "daterange_overlap" ) {
                        alert("직원 연차 기간 추가 중 오류 발생\n기존에 설정한 연차 기간과 중복됩니다");
                    }
                }
                // 일반적인 오류
                else {
                    alert("직원 연차 기간 추가 중 오류 발생");
                    this.hodu_error_process(e, false, false, true);
                }
            }        
        }

        // 기존 연차 기간 수정
        else {
            // update
            try {
                let data : any = null;
                
                if( this.employee.annual_start == this.copy_annual_info.annual_start && this.employee.annual_end == this.copy_annual_info.annual_end ) {
                    data = JSON.parse(JSON.stringify(this.employee));
                }

                if( this.employee.past_emp_info != null && this.employee.past_emp_info.length > 0 ) {
                    for( const past_emp of this.employee.past_emp_info ) {
                        if( past_emp.annual_start == this.copy_annual_info.annual_start && past_emp.annual_end == this.copy_annual_info.annual_end ) {
                            data = JSON.parse(JSON.stringify(past_emp));
                            break;
                        }
                    }
                }

                if( data == null ) {
                    alert("수정 할 연차 정보를 찾을 수 없습니다");
                    await this.getOrganizationEmp();
                    this.close();
                    return;
                }

                data.group_id     = this.scope_group_id;
                data.user_id      = this.employee.user_id;
                data.annual_count = Number(this.annual_count);
                data.annual_start = this.selected_annual_info.annual_start;
                data.annual_end   = this.selected_annual_info.annual_end;

                if( this.isExistBeforeAnnualCount() == true ) {
                    data.contents.before_annual_count = this.before_annual_count.indexOf('.') > -1 ? Number(this.before_annual_count) : Number(`${this.before_annual_count}.00`).toFixed(2);
                }

                data.before_annual_start = this.copy_annual_info.annual_start;
                data.before_annual_end = this.copy_annual_info.annual_end; 

                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp?is_annual=true&is_update=true`, API_METHOD.POST, data);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("직원 연차 수정 중 오류 발생");
                }

                this.copy_annual_info = null;
                
                await this.getOrganizationEmp();
                this.close();

            } catch(exception) {

                const e = (exception as any);
                
                // 연차기간 중복 오류
                if( e.response != null && e.response.data != null ) {
                    if( e.response.data.data.error_type ==  "daterange_overlap" ) {
                        alert("직원 연차 수정 중 오류 발생\n기존에 설정한 연차 기간과 중복됩니다");
                    }
                }
                // 일반적인 오류
                else {
                    alert("직원 연차 수정 중 오류 발생");
                    this.hodu_error_process(e, false, false, true);
                }
            }
        }

        // if( this.organization_off_modal_info.is_only_setting_term == false ) {
        //     if( this.employee.annual_count == null ) {
        //         alert("연차수를 입력해주세요")
        //         return;
        //     }

        //     if( new RegExp(/[0-9]{1,}/).test(this.employee.annual_count) == false || new RegExp(/\.+/).test(this.employee.annual_count) == true || new RegExp(/-+/).test(this.employee.annual_count) == true ) {
        //         alert("연차수에는 정수인 숫자만 입력해주세요")
        //         return;
        //     }
        // }
        
        // // TODO 나중에는 연차 기간을 잘못 설정한경우 수정할 방법이 생겨야함
        // // TODO 기간이 이전 기간과 겹치는지 검사하고 겹치면 안된다고 알려줄것
        // // if(  ) {
        // //     alert(`이전 기간과 겹치면 안됩니다\n이전 기간 : 2021.01.01 ~ 2021.12.31`);
        // //     return;
        // // }

        // try {

        //     const copy_employee = JSON.parse(JSON.stringify(this.employee));
            
        //     // 정보 최신화
        //     await this.getOrganizationEmp();

        //     // API 실행 - 한명 추가 or 수정
        //     if( !(this.organization_off_modal_info.user_ids != null && this.organization_off_modal_info.user_ids.length > 0) ) {
        //         const data = JSON.parse(JSON.stringify(this.employee));
        //         data.annual_start = copy_employee.annual_start
        //         data.annual_end   = copy_employee.annual_end
        //         data.annual_count = Number(copy_employee.annual_count); 

        //         const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp?is_annual=true&is_update=true`, API_METHOD.POST, data);

        //         if( !response || !this.isHttpStatusSuccess(response.status) ) {
        //             throw new Error("조직도 직원 근무 변경 중 오류 발생");
        //         }
        //     }
            
        //     // API 실행 - 여러명 추가 or 수정
        //     else {

        //         const promise_array : Promise<any>[] = [];

        //         // 병렬처리중 하나라도 에러가 존재했음을 알리는 플래그
        //         let error_count : number = 0;
        //         let error_message : string = "";

        //         // user_ids 로 대상 찾기
        //         for( const user_id of this.organization_off_modal_info.user_ids ) {
        //             const target = this.employees.filter(employee => user_id == employee.user_id);

        //             let employee : any = null;

        //             if( target.length < 1 ) {
        //                 employee = {
        //                     "group_id" : this.scope_group_id, 
        //                     "user_id" : user_id, 
        //                     "user_rev" : 1, 
        //                     "dept_id" : null, 
        //                     "pos_id" : null, 
        //                     "start_date" : null, 
        //                     "end_date" : null, 
        //                     "annual_start" : copy_employee.annual_start, 
        //                     "annual_end" : copy_employee.annual_end, 
        //                     "annual_count" : Number(copy_employee.annual_count), 
        //                     "work_type_id" : null,  
        //                     "is_approver" : false
        //                 }
        //             }
        //             else {
        //                 employee = target[0]; 
        //                 employee.annual_start = copy_employee.annual_start;
        //                 employee.annual_end   = copy_employee.annual_end;
        //                 if( this.organization_off_modal_info.is_only_setting_term == false ) {
        //                     employee.annual_count = Number(copy_employee.annual_count);
        //                 }
        //             }

        //             const promise = this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp?is_annual=true&is_update=true`, API_METHOD.POST, employee).catch((e) => {
        //                 if( e.response != null && e.response.data != null ) {
        //                     if( e.response.data.data.error_type ==  "daterange_overlap" ) {
        //                         error_count++;
        //                     }
        //                 }
        //             });

        //             promise_array.push(promise);
        //         }

        //         Promise.all(promise_array).then(() => {

        //             console.log(error_count);

        //             // 에러 메시지 보여주기
        //             if( error_count > 0 ) {
        //                 alert(`연차 기간이 중복된 ${error_count}명의 직원의 연차 설정은 수정 되지 않았습니다`);
        //             }
        //         });
        //     }

        // } catch(e) {
        //     // 연차 단일 설정 중 기간 중복 오류가 발생한 경우
        //     if( e.response != null && e.response.data != null ) {
        //         if( e.response.data.data.error_type ==  "daterange_overlap" ) {
        //             alert("직원 연차 설정 중 오류 발생\n기존에 설정한 연차 기간과 중복됩니다");
        //         }
        //     }
        //     // 일반적인 오류
        //     else {
        //         alert("직원 연차 설정 중 오류 발생");
        //         this.hodu_error_process(e, false, false, true);
        //     }
        // }
    }

    /**
     * 연차 정보 삭제
     */
    async remove(past_emp) : Promise<void> {

        if( confirm(`정말로 직원 연차를 삭제하시겠습니까?`) == false ) {
            return;
        }

        await this.getOrganizationEmp();

        if( this.employee.past_emp_info.length < 2 ) {
            alert("직원 연차 정보는 반드시 하나 이상 존재해야합니다");
            return;
        }

        try {

            let data : any = JSON.parse(JSON.stringify(this.employee));

            data.group_id     = this.scope_group_id;
            data.user_id      = this.employee.user_id;
            data.annual_count = past_emp.annual_count;
            data.annual_start = past_emp.annual_start;
            data.annual_end   = past_emp.annual_end;
            data.is_delete    = true;

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp?is_annual=true&is_update=true`, API_METHOD.POST, data);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("직원 연차 수정 중 오류 발생");
            }

        } catch(e) {
            alert("직원 연차 삭제 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        } finally {
            await this.getOrganizationEmp();
        }
 
    }

}
