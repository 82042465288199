
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { getBrowserInfo, getOSInfo, OWNER_TYPE, API_METHOD, GROUP_TYPE, isIE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

declare let Kakao : any;
import '@/assets/js/kakao'

import firebase from 'firebase/app';
import { updatePushToken } from '@/firebase';

// import appleSignin from 'apple-signin-auth';

import { hodu_local_storage } from '@/lib/HoduLocalStorage';
import { hodu_cookie } from '@/lib/HoduCookie';
import { LoginHospitalModalInfo } from '@/store/modules/ModalInfo';
import { hodu_color } from '@/common/color';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component
export default class Login extends Mixins(VueHoduCommon) {

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetLoginHospitalModalInfo ?: (param : LoginHospitalModalInfo) => void ;

    // 로그인
    id : string = ""; // 아이디 변수 (:value + @input)
    pw : string = ""; // 패스워드 변수 (:value + @input)

    login_type : string = "";

    auto_login_flag = false;

    password_login_mode : string = "email";

    is_hodu_doc : boolean = false;
    is_hodu_home : boolean = false;
    is_hodu_kids : boolean = false;

    domain_group_info : any = null; // null이 아니면 호두 프리미엄 그룹 도메인 서비스 이용

    async mounted() : Promise<void> {

        const host = location.host;

        // const group_domain_service_regexp : RegExp = /.{1,}\.168\.192\.112/;
        const group_domain_service_regexp : RegExp = /.{1,}\.hodu/;

        //! 로컬 로그인 여부
        if( new RegExp(/192\.168\.192\.{1,3}/).test(host) == true || new RegExp(/127\.0\.0\.1/).test(host) == true || new RegExp('localhost').test(host) == true ) {
            // 원하는 로그인 주석해제
            // this.is_hodu_doc  = true;
            // this.is_hodu_home = true;
            // this.is_hodu_kids = true;
        }

        //! 호두닥 로그인 여부
        else if( (new RegExp(/doc\.hodu/).test(host) == true || new RegExp(/doc\.dev\.hodu/).test(host)) == true ) { 
            this.is_hodu_doc = true;
        }

        //! 호두홈 로그인 여부
        else if( (new RegExp(/home\.hodu/).test(host) == true || new RegExp(/home\.dev\.hodu/).test(host)) == true ) {
            this.is_hodu_home = true;
        }

        //! 호두키즈 로그인 여부
        else if( (new RegExp(/kids\.hodu/).test(host) == true || new RegExp(/kids\.dev\.hodu/).test(host)) == true ) {
            this.is_hodu_kids = true;
        }
        
        //! 프리미엄 그룹 도메인 서비스
        else if( ( new RegExp(/app\.hodu/).test(host) == true || new RegExp(/app\.dev\.hodu/).test(host) == true || new RegExp(/web\.hodu/).test(host) == true ) == false && new RegExp(group_domain_service_regexp).test(host) ) {
            const domain = host.substring(0, host.indexOf("."));
            await this.domainValidation(domain);
        }

        if( this.isLogin == true ) {
            this.hodu_router_push('/');
            return;
        }

        if( !Kakao.isInitialized() ){ await Kakao.init('c18796cb68948dbecc66ea05f7fa7773'); }
        
        const auto_login : string | null = hodu_local_storage.getItem("auto_login_flag");
        if( auto_login != null && auto_login == "true" ) { 
            this.auto_login_flag = true;
            await this.autoLogin(); 
        }
        else {
            
            // 로컬스토리지에 없어도 쿠키를 한번 체크한다 (기존에는 로컬스토리지만 사용했지만 사용자가 캐시를 날리면 사라지는 문제로 쿠키를 사용해서 한번 더 체크)
            if( auto_login == null || auto_login == "false" ) {
                const auto_login_cookie = hodu_cookie.get("auto_login_flag");
                if( auto_login_cookie != null && auto_login_cookie == "true" ) {
                    this.auto_login_flag = true; 
                    await this.autoLogin();
                    return; 
                }
            }

            const kakao_login_flag : string | null = hodu_local_storage.getItem("kakao_login_flag");
            if( kakao_login_flag != null && kakao_login_flag == "true" ) {
                $(".kakao_logIn_btn").on("click", async() => { await this.kakaoLoginForm(); }); 
                $(".kakao_logIn_btn").trigger("click");
                $(".kakao_logIn_btn").unbind();
            }
        }

        this.appleLoginEventInit();

        //메뉴 탭
        // $('.login_tabTitle > ul > li').click (function(){
        // 	var borderIndex = $(this).index();
        // 	$('.ware_accnt_div > ul').hide();
        // 	$('.ware_accnt_div > ul').eq(borderIndex).show();
        // 	$(this).addClass('active').siblings().removeClass();

        // 	return false;
        // });
    }

    /**
     * 프리미엄 그룹 도메인 서비스 유효성 검사
     */
    async domainValidation(domain : string) : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/domain/${domain}?is_hodu_sub_domain=true`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.group_info ) {
                throw new Error("프리미엄 그룹 도메인 서비스 유효성 검사 실패");
            }

            this.domain_group_info = response.data.data.group_info;
            
        } catch(e) {
            // 에러시 일반 도메인으로 이동
            this.hodu_error_process(e, false, false, true);
            location.href="https://app.hoduware.com";
        }
    }

    /**
     * 애플 계정 로그인 관련 이벤트
     */
    appleLoginEventInit() {
        
        // meta 태그 redirect-uri 변경
        document.getElementById('appleid-signin-redirect-uri')?.setAttribute('content', `${location.protocol}//${location.hostname}/api/v1/apple`);

        document.addEventListener('AppleIDSignInOnSuccess', async(data) => {
            console.log(data);

            if( !data || !data['detail'] || !data['detail']['authorization'] ) {
                this.hodu_show_dialog('cancel', "애플 계정 로그인 중 오류 발생", ['확인']);
                return;
            }

            const code = data['detail']['authorization']['code'];
            const id_token = data['detail']['authorization']['id_token'];
            const state = data['detail']['authorization']['state'];

            try {

                let token : string | undefined = undefined;
                try {
                    token = ('Notification' in window && Notification.permission == 'granted') ? (await firebase.messaging().getToken()) : undefined;
                } catch(e) {
                    token = undefined;
                    this.hodu_error_process(e, false, false, true);
                }

                const response = await this.hodu_api_call('api/v1/auth/login/APPLE', API_METHOD.POST, { 
                    app_ver                : this.getVersion(),
                    device_os              : "WEB",
                    device_os_ver          : await getBrowserInfo(),
                    device_model           : await getOSInfo(),
                    sns_id                 : id_token,
                    apple_access_token     : code,
                    device_token           : token 
                });

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("애플 계정 로그인 중 오류 발생");
                }
                
                console.log(response);
                this.loginSuccessCallback(response);

            } catch(e) {
                this.loginFailCallback(e);
            }
            
        });

        document.addEventListener('AppleIDSignInOnFailure', (error) => {
            console.log(error);

            if( error && (error as any).detail && (error as any).detail.error ) {

                if( (error as any).detail.error == "popup_closed_by_user" || (error as any).detail.error == "user_cancelled_authorize" ) {
                    return;
                } 

            }

            this.hodu_show_dialog('cancel', "애플 계정 로그인 중 오류 발생", ['확인']);
        });
    }
    
    /**
     * 아이디에서 엔터시 로그인시도
     */
    idKeyDown(event) : void {
        if( event.keyCode != 13 ) {
            return;
        }

        this.login();
    }
    
    /**
     * 비밀번호에서 엔터시 로그인시도
     */
    pwKeyDown(event) : void {
        if( event.keyCode != 13 ) {
            return;
        }

        this.login();
    }

    /**
     * 로그인
     */
    async login():Promise<void>{
        const vue = this;
        const loginSuccessCallback = vue.loginSuccessCallback;
        const loginFailCallback    = vue.loginFailCallback;
        
        if( this.id == null || this.id.trim() == ""){
            this.hodu_show_dialog('alert', `${ this.password_login_mode == 'email' ? '이메일을' : '휴대폰번호를' } 입력 해주세요`, ['확인'], [() => { $('#ware_userId').focus(); }]);
            return;
        }

        // 이메일로그인 모드 && 개발 아이디가 아니라면 이메일 형식 체크
        if( this.password_login_mode == 'email' && !( this.id.trim() == "3" || this.id.trim() == "4" || this.id.trim() == "5" || this.id.trim() == "6" || this.id.trim() == "7" ) ) {
            // 이메일 형식 체크
            if(! /\S+@\S+\.\S+/.test(this.id.trim()) ){
                this.hodu_show_dialog('alert', '이메일 형식을 확인해주세요', ['확인'], [() => { $('#ware_userId').focus(); }]);
                return;
            }
        }    

        if( this.pw == null || this.pw.trim() == ""){
            this.hodu_show_dialog('alert', '패스워드를 입력 해주세요', ['확인'], [() => { $('#ware_userPwd').focus(); }]);
            return;
        }

        let token : string | undefined = undefined;
        try {
            token = ('Notification' in window && Notification.permission == 'granted') ? (await firebase.messaging().getToken()) : undefined;
        } catch(e) {
            token = undefined;
            this.hodu_error_process(e, false, false, true);
        }

        this.login_type = this.password_login_mode == 'email' ? 'EMAIL' : 'PHONE';
        await this.hodu_api_call(`/api/v1/auth/login/${this.password_login_mode == 'email' ? 'EMAIL' : 'PHONE'}`, API_METHOD.POST, {
            sns_id        : this.id.trim(),
            password      : this.pw.trim(),
            app_ver       : this.getVersion(),
            device_os     : "WEB",
            device_os_ver : await getBrowserInfo(),
            device_model  : await getOSInfo(),
            device_token  : token
        })
        .then((response) => loginSuccessCallback(response))
        .catch((e)=> loginFailCallback(e));
    }

    /**
     * 카카오톡 로그인
     */
    async kakaoLogin() : Promise<void> {

        const access_token = Kakao.Auth.getAccessToken();
        
        /**
         * access_token 있다면
         */
        if( access_token ) {

            let token : string | undefined = undefined;
            try {
                token = ('Notification' in window && Notification.permission == 'granted') ? (await firebase.messaging().getToken()) : undefined;
            } catch(e) {
                token = undefined;
                this.hodu_error_process(e, false, false, true);
            }

            this.login_type = 'KAKAOTALK';
            await this.hodu_api_call(`/api/v1/auth/login/KAKAOTALK`, API_METHOD.POST, {
                app_ver                : this.getVersion(),
                device_os              : "WEB",
                device_os_ver          : await getBrowserInfo(),
                device_model           : await getOSInfo(),
                kakaotalk_access_token : access_token,
                device_token           : token
            }) 
            .then((response) => this.loginSuccessCallback(response))
            .catch((e) => this.kakaoLoginForm()); // 실패시 LoginForm
        }

        /**
         * access_token 없다면
         */
        else { this.kakaoLoginForm(); }
        
    }

    /**
     * kakaoLoginForm
     */
    async kakaoLoginForm() : Promise<void> {
        const vue = this;

        await Kakao.Auth.loginForm({
            success: async(authObj) => {

                let token : string | undefined = undefined;
                try {
                    token = ('Notification' in window && Notification.permission == 'granted') ? (await firebase.messaging().getToken()) : undefined;
                } catch(e) {
                    token = undefined;
                    this.hodu_error_process(e, false, false, true);
                }
                
                vue.login_type = 'KAKAOTALK';
                await vue.hodu_api_call(`/api/v1/auth/login/KAKAOTALK`, API_METHOD.POST, {
                    app_ver                : vue.getVersion(),
                    device_os              : "WEB",
                    device_os_ver          : await getBrowserInfo(),
                    device_model           : await getOSInfo(),
                    kakaotalk_access_token : authObj.access_token,
                    device_token           : token
                }) 
                .then((response) => vue.loginSuccessCallback(response))
                .catch((e) => vue.loginFailCallback(e));
                
            },
            fail: async(e) => vue.loginFailCallback(e)
        });
    }

    /**
     * 구글 로그인
     */
    async googleLogin() : Promise<void> {

        window['gapi'].load('auth2', async() => {
            const auth2 = window['gapi'].auth2.init({
                client_id: '48808503160-k10pbn6thi0ko0easfuid8e4lq4afta9.apps.googleusercontent.com',
            });

            console.log(auth2);

            auth2.grantOfflineAccess({ scope: 'profile email', prompt: 'select_account' }).then(async(result) => {
                console.log(result);
                const access_code = result.code;

                try {
                    let token : string | undefined = undefined;
                    try {
                        token = ('Notification' in window && Notification.permission == 'granted') ? (await firebase.messaging().getToken()) : undefined;
                    } catch(e) {
                        token = undefined;
                        this.hodu_error_process(e, false, false, true);
                    }
                
                    this.login_type = 'GOOGLE';
                    await this.hodu_api_call(`/api/v1/auth/login/GOOGLE`, API_METHOD.POST, {
                        sns_id        : "",
                        app_ver       : this.getVersion(),
                        device_os     : "WEB",
                        device_os_ver : await getBrowserInfo(),
                        device_model  : await getOSInfo(),
                        device_token  : token,
                        google_code   : access_code
                    }) 
                    .then((response) => this.loginSuccessCallback(response))
                    .catch((e) => this.loginFailCallback(e)); // 실패시 LoginForm

                } catch(e) {
                    this.hodu_show_dialog('cancel', "구글 계정 로그인 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }

            });
        }); 
    }

    /**
     * 로그인 성공 콜백
     */
    async loginSuccessCallback(response) : Promise<void> {
        
        if(sessionStorage == null || localStorage == null || response.data.data.session_token == null){
            return;
        }

        // 핸드폰 로그인은 미인증시 이용불가
        // if( this.login_type == 'PHONE' && response.data.data.user.auth_info.auth_check == false ) {
        //     alert("휴대폰 번호 로그인시 인증이 필요합니다");
        //     return;
        // }

        sessionStorage.setItem("session_token", response.data.data.session_token);
        hodu_local_storage.setItem("session_token", response.data.data.session_token);
        hodu_local_storage.setItem("kakao_login_flag", String(this.login_type == "KAKAOTALK"));
        hodu_cookie.set("session_token", response.data.data.session_token);

        // 호두닥 로그인
        if ( this.is_hodu_doc == true ) {
            this.getHoduDList(response);
            return;
        }

        // 호두홈 로그인
        else if( this.is_hodu_home == true ) {
            this.getHoduHList(response);
            return;
        }

        // 호두키즈 로그인
        else if( this.is_hodu_kids == true ) {
            this.getHoduKidsList(response);
            return;
        }

        // 호두 프리미엄 그룹 도메인 로그인 서비스
        else if( this.domain_group_info != null ) {
            await this.hodu_domain_service(response);
            return;
        }

        const login_callback : Function = async() => {

            // 유저 정보 세팅
            this.doLogin({
                isLogin           : true,
                user_id           : response.data.data.user.user_id,
                user_type         : this.login_type,
                user_email        : response.data.data.user.user_email,
                user_name         : response.data.data.user.user_name,
                user_phone_number : response.data.data.user.user_phone_number,
                country_code      : response.data.data.user.country_code,
                is_temp_password  : response.data.data.is_temp_password,
                user_preference   : response.data.data.user_preference,
                user_group_role   : response.data.data.group_role,
                user_team_role    : response.data.data.team_role,
                template_map      : response.data.data.template_map,
                auth_info         : response.data.data.user.auth_info,
            });
    
            // 초기화 되어야하는 조건들 초기화
            this.doSetScheduleSearchConfig({}); 
    
            // 로컬 데이터 설정 -> DB 값으로 변경됨
            // const local_data : string | null = hodu_local_storage.getItem(`${this.user_id}`);
            // if( local_data != null ) {
            //     const temp_local_data : LocalStorageInfo = JSON.parse(local_data);
            //     local_storage_info.schedule_search_config = temp_local_data.schedule_search_config;
            //     this.doSetScheduleSearchConfig(local_storage_info.schedule_search_config);
            // }
            this.getFilterInfo();
    
            // 일반 로그인
            this.doSetCalendarId(`personal-${response.data.data.user.user_id}`);
            this.doSetScope(OWNER_TYPE.PERSONAL);  
            this.doSetScopeGroupId(0);
            this.doSetScopeTeamId(0);
            this.doSetScopeGroupTeamOption({
                group_team_name : '',
                group_team_color : '#FFFFFF',
                group_team_image : '',
                group_team_descript : '',
                biz_id : '',
                biz_type : ''
            });  
    
            await this.hodu_loading_timer_exit();
            await this.hodu_loading();

            await this.calendar_preference_sync(false, false);

            this.hodu_router_push('/');
    
            // 자동로그인 관련 설정
            hodu_local_storage.setItem('auto_login_flag', String(this.auto_login_flag));
            hodu_cookie.set('auto_login_flag', String(this.auto_login_flag));
        };

        this.doSetUserId(response.data.data.user.user_id);

        // 휴대폰 로그인인데 인증이 안된 경우
        if( this.login_type == 'PHONE' && response.data.data.user.auth_info.auth_check == false ) {
            this.auth(login_callback, true);
            return;
        }

        // 다음에 인증 누르지 않은 경우, 어느 순간 앱에서 사용 안하길래 제거함
        // if( response.data.data.user.auth_info.auth_check == false && response.data.data.user.auth_info.auth_check_later == false ) {
        //     this.auth(login_callback, false);
        //     return;
        // }

        await login_callback();
    }

    /**
     * 로그인 실패 콜백
     */
    loginFailCallback(e) {
        this.hodu_error_process(e, true, false);
    }

    /**
     * 호두닥 리스트 조회 후 이동
     */
    async getHoduDList(login_response : any, is_auto_login : boolean = false) {
        await this.hodu_api_call(`api/v1/user/me/hodudoc`, API_METHOD.GET)
            .then(async(response) => {
                let jsonData = JSON.parse(JSON.stringify(response.data.data));
                const list : any[] = jsonData.hodu_d_list;
                
                if( list.length < 1 ) {
                    this.hodu_show_dialog('cancel', '호두닥 정보가 없습니다\n관리자에게 문의 해주세요', ['확인']);
                    return;
                }

                const login_callback : Function = async() => {
                    if ( list.length > 1 && this.doSetLoginHospitalModalInfo ) {
                        this.doSetLoginHospitalModalInfo({
                            show_modal    : true,
                            hospital_list : list,
                            callback      : async (biz_id) => {
                                let lo_hospital;
                                for ( const hospital of list ) {
                                    if ( hospital.biz_id == biz_id ) {
                                        lo_hospital = JSON.parse(JSON.stringify(hospital))
                                    }
                                }
                                if ( lo_hospital == null ) {
                                    lo_hospital = JSON.parse(JSON.stringify(list[0]))
                                }
                                
                                // 자동 로그인 유저 정보 세팅
                                if( is_auto_login == true ) {
                                    this.doLogin({
                                        isLogin           : true,
                                        user_id           : login_response.data.data.user.user_id,
                                        user_type         : login_response.data.data.last_login_type,
                                        user_email        : login_response.data.data.user.user_email,
                                        user_name         : login_response.data.data.user.user_name,
                                        user_phone_number : login_response.data.data.user.user_phone_number,
                                        country_code      : login_response.data.data.user.country_code,
                                        is_temp_password  : login_response.data.data.is_temp_password,
                                        user_preference   : login_response.data.data.user_preference,
                                        user_group_role   : [],
                                        user_team_role    : [],
                                        template_map      : login_response.data.data.template_map,
                                        auth_info         : login_response.data.data.user.auth_info,
                                    });
    
                                    await this.get_group_role_service();
                                }
                                
                                // 수동 로그인 유저 정보 세팅
                                else {
                                    this.doLogin({
                                        isLogin           : true,
                                        user_id           : login_response.data.data.user.user_id,
                                        user_type         : this.login_type,
                                        user_email        : login_response.data.data.user.user_email,
                                        user_name         : login_response.data.data.user.user_name,
                                        user_phone_number : login_response.data.data.user.user_phone_number,
                                        country_code      : login_response.data.data.user.country_code,
                                        is_temp_password  : login_response.data.data.is_temp_password,
                                        user_preference   : login_response.data.data.user_preference,
                                        user_group_role   : login_response.data.data.group_role,
                                        user_team_role    : login_response.data.data.team_role,
                                        template_map      : login_response.data.data.template_map,
                                        auth_info         : login_response.data.data.user.auth_info,
                                    });
                                }
                                
                                // 초기화 되어야하는 조건들 초기화
                                this.doSetScheduleSearchConfig({}); 
    
                                // 로컬 데이터 설정 -> DB 값으로 변경됨
                                // const local_data : string | null = hodu_local_storage.getItem(`${this.user_id}`);
                                // if( local_data != null ) {
                                //     const temp_local_data : LocalStorageInfo = JSON.parse(local_data);
                                //     local_storage_info.schedule_search_config = temp_local_data.schedule_search_config;
                                //     this.doSetScheduleSearchConfig(local_storage_info.schedule_search_config);
                                // }
                                this.getFilterInfo();
    
                                this.doSetCalendarId(`group-${lo_hospital.group_id}`);
                                this.doSetScope(OWNER_TYPE.GROUP);  
                                this.doSetScopeGroupId(lo_hospital.group_id);
                                this.doSetScopeTeamId(0);
                                this.doSetScopeGroupTeamOption({
                                    group_team_name : lo_hospital.group_name,
                                    group_team_color : lo_hospital.group_color,
                                    group_team_image : '',
                                    group_team_descript : '',
                                    biz_id : lo_hospital.biz_id,
                                    biz_type : GROUP_TYPE.BIZD
                                });
    
                                await this.hodu_loading_timer_exit();
                                await this.hodu_loading();

                                await this.calendar_preference_sync(false, false);

                                this.hodu_router_push('/');
                            }
                        })
                    }
                    //! 병원이 하나만 있을때
                    else{

                        const hospital = JSON.parse(JSON.stringify(list[0]))
    
                        // 자동 로그인 유저 정보 세팅
                        if( is_auto_login == true ) {
                            this.doLogin({
                                isLogin           : true,
                                user_id           : login_response.data.data.user.user_id,
                                user_type         : login_response.data.data.last_login_type,
                                user_email        : login_response.data.data.user.user_email,
                                user_name         : login_response.data.data.user.user_name,
                                user_phone_number : login_response.data.data.user.user_phone_number,
                                country_code      : login_response.data.data.user.country_code,
                                is_temp_password  : login_response.data.data.is_temp_password,
                                user_preference   : login_response.data.data.user_preference,
                                user_group_role   : [],
                                user_team_role    : [],
                                template_map      : login_response.data.data.template_map,
                                auth_info         : login_response.data.data.user.auth_info,
                            });
    
                            await this.get_group_role_service();
                        }
                        
                        // 수동 로그인 유저 정보 세팅
                        else {
                            this.doLogin({
                                isLogin           : true,
                                user_id           : login_response.data.data.user.user_id,
                                user_type         : this.login_type,
                                user_email        : login_response.data.data.user.user_email,
                                user_name         : login_response.data.data.user.user_name,
                                user_phone_number : login_response.data.data.user.user_phone_number,
                                country_code      : login_response.data.data.user.country_code,
                                is_temp_password  : login_response.data.data.is_temp_password,
                                user_preference   : login_response.data.data.user_preference,
                                user_group_role   : login_response.data.data.group_role,
                                user_team_role    : login_response.data.data.team_role,
                                template_map      : login_response.data.data.template_map,
                                auth_info         : login_response.data.data.user.auth_info,
                            });
                        }
                    
                        // 초기화 되어야하는 조건들 초기화
                        this.doSetScheduleSearchConfig({}); 
    
                        // 로컬 데이터 설정 -> DB 값으로 변경됨
                        // const local_data : string | null = hodu_local_storage.getItem(`${this.user_id}`);
                        // if( local_data != null ) {
                        //     const temp_local_data : LocalStorageInfo = JSON.parse(local_data);
                        //     local_storage_info.schedule_search_config = temp_local_data.schedule_search_config;
                        //     this.doSetScheduleSearchConfig(local_storage_info.schedule_search_config);
                        // }
                        this.getFilterInfo();
    
                        this.doSetCalendarId(`group-${hospital.group_id}`);
                        this.doSetScope(OWNER_TYPE.GROUP);  
                        this.doSetScopeGroupId(hospital.group_id);
                        this.doSetScopeTeamId(0);
                        this.doSetScopeGroupTeamOption({
                            group_team_name : hospital.group_name,
                            group_team_color : hospital.group_color,
                            group_team_image : '',
                            group_team_descript : '',
                            biz_id : hospital.biz_id,
                            biz_type : GROUP_TYPE.BIZD
                        });
    
                        await this.hodu_loading_timer_exit();
                        await this.hodu_loading();

                        await this.calendar_preference_sync(false, false);

                        this.hodu_router_push('/');
                    }
        
                    hodu_local_storage.setItem('auto_login_flag', String(this.auto_login_flag));
                    hodu_cookie.set('auto_login_flag', String(this.auto_login_flag));
                };

                this.doSetUserId(login_response.data.data.user.user_id);
                if( login_response.data.data.user.auth_info.auth_check == false && login_response.data.data.user.auth_info.auth_check_later == false ) {
                    this.auth(login_callback, false);
                    return;
                }

                // 휴대폰 로그인인데 인증이 안된 경우
                if( this.login_type == 'PHONE' && login_response.data.data.user.auth_info.auth_check == false ) {
                    this.auth(login_callback, true);
                    return;
                }

                await login_callback();
                
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 호두홈 정보 조회
     */
    async getHoduHList(login_response : any, is_auto_login : boolean = false) : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/user/me/hoduhome`, API_METHOD.GET);
            
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.hodu_h_list ) {
                throw new Error("호두홈 정보 조회 중 오류 발생");
            }

            const list : any[] = response.data.data.hodu_h_list;
            
            if( list.length < 1 ) {
                this.hodu_show_dialog('cancel', '호두홈 정보가 없습니다\n관리자에게 문의 해주세요', ['확인']);
                return;
            }

            // 하나만 존재하는 경우 바로 로그인
            if( list.length == 1 ) {
                const home = JSON.parse(JSON.stringify(list[0]))

                const login_callback : Function = async() => {
                    // 자동 로그인 유저 정보 세팅
                    if( is_auto_login == true ) {
                        this.doLogin({
                            isLogin           : true,
                            user_id           : login_response.data.data.user.user_id,
                            user_type         : login_response.data.data.last_login_type,
                            user_email        : login_response.data.data.user.user_email,
                            user_name         : login_response.data.data.user.user_name,
                            user_phone_number : login_response.data.data.user.user_phone_number,
                            country_code      : login_response.data.data.user.country_code,
                            is_temp_password  : login_response.data.data.is_temp_password,
                            user_preference   : login_response.data.data.user_preference,
                            user_group_role   : [],
                            user_team_role    : [],
                            template_map      : login_response.data.data.template_map,
                            auth_info         : login_response.data.data.user.auth_info,
                        });
    
                        await this.get_group_role_service();
                    }
                    
                    // 수동 로그인 유저 정보 세팅
                    else {
                        this.doLogin({
                            isLogin           : true,
                            user_id           : login_response.data.data.user.user_id,
                            user_type         : this.login_type,
                            user_email        : login_response.data.data.user.user_email,
                            user_name         : login_response.data.data.user.user_name,
                            user_phone_number : login_response.data.data.user.user_phone_number,
                            country_code      : login_response.data.data.user.country_code,
                            is_temp_password  : login_response.data.data.is_temp_password,
                            user_preference   : login_response.data.data.user_preference,
                            user_group_role   : login_response.data.data.group_role,
                            user_team_role    : login_response.data.data.team_role,
                            template_map      : login_response.data.data.template_map,
                            auth_info         : login_response.data.data.user.auth_info,
                        });
                    }
                    
                    // 초기화 되어야하는 조건들 초기화
                    this.doSetScheduleSearchConfig({}); 
    
                    // 로컬 데이터 설정 -> DB 값으로 변경됨
                    // const local_data : string | null = hodu_local_storage.getItem(`${this.user_id}`);
                    // if( local_data != null ) {
                    //     const temp_local_data : LocalStorageInfo = JSON.parse(local_data);
                    //     local_storage_info.schedule_search_config = temp_local_data.schedule_search_config;
                    //     this.doSetScheduleSearchConfig(local_storage_info.schedule_search_config);
                    // }
                    this.getFilterInfo();
    
                    this.doSetCalendarId(`group-${home.group_id}`);
                    this.doSetScope(OWNER_TYPE.GROUP);  
                    this.doSetScopeGroupId(home.group_id);
                    this.doSetScopeTeamId(0);
                    this.doSetScopeGroupTeamOption({
                        group_team_name : home.group_name,
                        group_team_color : home.group_color,
                        group_team_image : '',
                        group_team_descript : '',
                        biz_id : home.biz_id,
                        biz_type : GROUP_TYPE.BIZH,
                        login_mode : "HOME"
                    });
    
                    await this.hodu_loading_timer_exit();
                    await this.hodu_loading();

                    await this.calendar_preference_sync(false, false);

                    this.hodu_router_push('/');
    
                    hodu_local_storage.setItem('auto_login_flag', String(this.auto_login_flag));
                    hodu_cookie.set('auto_login_flag', String(this.auto_login_flag));
                };

                this.doSetUserId(login_response.data.data.user.user_id);
                if( login_response.data.data.user.auth_info.auth_check == false ) {
                    this.auth(login_callback, true);
                    return;
                }

                await login_callback();
                return;
            }

            const login_callback : Function = async() => {

                // 두개 이상 존재하는 경우 선택해서 로그인
                this.doSetLoginHospitalModalInfo?.({
                    show_modal : true,
                    hospital_list : list,
                    callback : async (biz_id) => {
                        let lo_home;
                        for ( const home of list ) {
                            if ( home.biz_id == biz_id ) {
                                lo_home = JSON.parse(JSON.stringify(home))
                            }
                        }
    
                        if ( lo_home == null ) {
                            lo_home = JSON.parse(JSON.stringify(list[0]))
                        }
                        
                        // 자동 로그인 유저 정보 세팅
                        if( is_auto_login == true ) {
                            this.doLogin({
                                isLogin           : true,
                                user_id           : login_response.data.data.user.user_id,
                                user_type         : login_response.data.data.last_login_type,
                                user_email        : login_response.data.data.user.user_email,
                                user_name         : login_response.data.data.user.user_name,
                                user_phone_number : login_response.data.data.user.user_phone_number,
                                country_code      : login_response.data.data.user.country_code,
                                is_temp_password  : login_response.data.data.is_temp_password,
                                user_preference   : login_response.data.data.user_preference,
                                user_group_role   : [],
                                user_team_role    : [],
                                template_map      : login_response.data.data.template_map,
                                auth_info         : login_response.data.data.user.auth_info,
                            });
    
                            await this.get_group_role_service();
                        }
                        
                        // 수동 로그인 유저 정보 세팅
                        else {
                            this.doLogin({
                                isLogin           : true,
                                user_id           : login_response.data.data.user.user_id,
                                user_type         : this.login_type,
                                user_email        : login_response.data.data.user.user_email,
                                user_name         : login_response.data.data.user.user_name,
                                user_phone_number : login_response.data.data.user.user_phone_number,
                                country_code      : login_response.data.data.user.country_code,
                                is_temp_password  : login_response.data.data.is_temp_password,
                                user_preference   : login_response.data.data.user_preference,
                                user_group_role   : login_response.data.data.group_role,
                                user_team_role    : login_response.data.data.team_role,
                                template_map      : login_response.data.data.template_map,
                                auth_info         : login_response.data.data.user.auth_info,
                            });
                        }
                        
                        // 초기화 되어야하는 조건들 초기화
                        this.doSetScheduleSearchConfig({}); 
    
                        // 로컬 데이터 설정 -> DB 값으로 변경됨
                        // const local_data : string | null = hodu_local_storage.getItem(`${this.user_id}`);
                        // if( local_data != null ) {
                        //     const temp_local_data : LocalStorageInfo = JSON.parse(local_data);
                        //     local_storage_info.schedule_search_config = temp_local_data.schedule_search_config;
                        //     this.doSetScheduleSearchConfig(local_storage_info.schedule_search_config);
                        // }
                        this.getFilterInfo();
    
                        this.doSetCalendarId(`group-${lo_home.group_id}`);
                        this.doSetScope(OWNER_TYPE.GROUP);  
                        this.doSetScopeGroupId(lo_home.group_id);
                        this.doSetScopeTeamId(0);
                        this.doSetScopeGroupTeamOption({
                            group_team_name : lo_home.group_name,
                            group_team_color : lo_home.group_color,
                            group_team_image : '',
                            group_team_descript : '',
                            biz_id : lo_home.biz_id,
                            biz_type : GROUP_TYPE.BIZH,
                            login_mode : "HOME"
                        });
    
                        await this.hodu_loading_timer_exit();
                        await this.hodu_loading();

                        await this.calendar_preference_sync(false, false);

                        this.hodu_router_push('/');
                    }
                        
                });
    
                hodu_local_storage.setItem('auto_login_flag', String(this.auto_login_flag));
                hodu_cookie.set('auto_login_flag', String(this.auto_login_flag));
            };

            this.doSetUserId(login_response.data.data.user.user_id);
            if( login_response.data.data.user.auth_info.auth_check == false ) {
                this.auth(login_callback, true);
                return;
            }

            await login_callback();

        } catch(e) {
            this.hodu_error_process(e, true, false);
        }
    }

    /**
     * 호두키즈 정보 조회
     */
    async getHoduKidsList(login_response : any, is_auto_login : boolean = false) : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/user/me/hodukids`, API_METHOD.GET);
            
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.hodu_kids_list ) {
                throw new Error("호두키즈 정보 조회 중 오류 발생");
            }

            const list : any[] = response.data.data.hodu_kids_list;
            
            if( list.length < 1 ) {
                this.hodu_show_dialog('cancel', '호두키즈 정보가 없습니다\n관리자에게 문의 해주세요', ['확인']);
                return;
            }

            // 하나만 존재하는 경우 바로 로그인
            if( list.length == 1 ) {
                const kids = JSON.parse(JSON.stringify(list[0]));

                const login_callback : Function = async() => {

                    // 자동 로그인 유저 정보 세팅
                    if( is_auto_login == true ) {
                        this.doLogin({
                            isLogin           : true,
                            user_id           : login_response.data.data.user.user_id,
                            user_type         : login_response.data.data.last_login_type,
                            user_email        : login_response.data.data.user.user_email,
                            user_name         : login_response.data.data.user.user_name,
                            user_phone_number : login_response.data.data.user.user_phone_number,
                            country_code      : login_response.data.data.user.country_code,
                            is_temp_password  : login_response.data.data.is_temp_password,
                            user_preference   : login_response.data.data.user_preference,
                            user_group_role   : [],
                            user_team_role    : [],
                            template_map      : login_response.data.data.template_map,
                            auth_info         : login_response.data.data.user.auth_info,
                        });
    
                        await this.get_group_role_service();
                    }
                    
                    // 수동 로그인 유저 정보 세팅
                    else {
                        this.doLogin({
                            isLogin           : true,
                            user_id           : login_response.data.data.user.user_id,
                            user_type         : this.login_type,
                            user_email        : login_response.data.data.user.user_email,
                            user_name         : login_response.data.data.user.user_name,
                            user_phone_number : login_response.data.data.user.user_phone_number,
                            country_code      : login_response.data.data.user.country_code,
                            is_temp_password  : login_response.data.data.is_temp_password,
                            user_preference   : login_response.data.data.user_preference,
                            user_group_role   : login_response.data.data.group_role,
                            user_team_role    : login_response.data.data.team_role,
                            template_map      : login_response.data.data.template_map,
                            auth_info         : login_response.data.data.user.auth_info,
                        });
                    }
                    
                    // 초기화 되어야하는 조건들 초기화
                    this.doSetScheduleSearchConfig({}); 
    
                    // 로컬 데이터 설정 -> DB 값으로 변경됨
                    // const local_data : string | null = hodu_local_storage.getItem(`${this.user_id}`);
                    // if( local_data != null ) {
                    //     const temp_local_data : LocalStorageInfo = JSON.parse(local_data);
                    //     local_storage_info.schedule_search_config = temp_local_data.schedule_search_config;
                    //     this.doSetScheduleSearchConfig(local_storage_info.schedule_search_config);
                    // }
                    this.getFilterInfo();
    
                    this.doSetCalendarId(`group-${kids.group_id}`);
                    this.doSetScope(OWNER_TYPE.GROUP);  
                    this.doSetScopeGroupId(kids.group_id);
                    this.doSetScopeTeamId(0);
                    this.doSetScopeGroupTeamOption({
                        group_team_name : kids.group_name,
                        group_team_color : kids.group_color,
                        group_team_image : '',
                        group_team_descript : '',
                        biz_id : kids.biz_id,
                        biz_type : GROUP_TYPE.BIZC,
                        login_mode : "KIDS"
                    });
    
                    await this.hodu_loading_timer_exit();
                    await this.hodu_loading();

                    await this.calendar_preference_sync(false, false);

                    this.hodu_router_push('/');
    
                    hodu_local_storage.setItem('auto_login_flag', String(this.auto_login_flag));
                    hodu_cookie.set('auto_login_flag', String(this.auto_login_flag));
                };

                this.doSetUserId(login_response.data.data.user.user_id);
                if( login_response.data.data.user.auth_info.auth_check == false ) {
                    this.auth(login_callback, true);
                    return;
                }

                await login_callback();
                return;
            }

            const login_callback : Function = async() => {

                // 두개 이상 존재하는 경우 선택해서 로그인
                this.doSetLoginHospitalModalInfo?.({
                    show_modal : true,
                    hospital_list : list,
                    callback : async (biz_id) => {
                        let lo_kids;
                        for ( const home of list ) {
                            if ( home.biz_id == biz_id ) {
                                lo_kids = JSON.parse(JSON.stringify(home))
                            }
                        }
    
                        if ( lo_kids == null ) {
                            lo_kids = JSON.parse(JSON.stringify(list[0]))
                        }
                        
                        // 자동 로그인 유저 정보 세팅
                        if( is_auto_login == true ) {
                            this.doLogin({
                                isLogin           : true,
                                user_id           : login_response.data.data.user.user_id,
                                user_type         : login_response.data.data.last_login_type,
                                user_email        : login_response.data.data.user.user_email,
                                user_name         : login_response.data.data.user.user_name,
                                user_phone_number : login_response.data.data.user.user_phone_number,
                                country_code      : login_response.data.data.user.country_code,
                                is_temp_password  : login_response.data.data.is_temp_password,
                                user_preference   : login_response.data.data.user_preference,
                                user_group_role   : [],
                                user_team_role    : [],
                                template_map      : login_response.data.data.template_map,
                                auth_info         : login_response.data.data.user.auth_info,
                            });
    
                            await this.get_group_role_service();
                        }
                        
                        // 수동 로그인 유저 정보 세팅
                        else {
                            this.doLogin({
                                isLogin           : true,
                                user_id           : login_response.data.data.user.user_id,
                                user_type         : this.login_type,
                                user_email        : login_response.data.data.user.user_email,
                                user_name         : login_response.data.data.user.user_name,
                                user_phone_number : login_response.data.data.user.user_phone_number,
                                country_code      : login_response.data.data.user.country_code,
                                is_temp_password  : login_response.data.data.is_temp_password,
                                user_preference   : login_response.data.data.user_preference,
                                user_group_role   : login_response.data.data.group_role,
                                user_team_role    : login_response.data.data.team_role,
                                template_map      : login_response.data.data.template_map,
                                auth_info         : login_response.data.data.user.auth_info,
                            });
                        }
                        
                        // 초기화 되어야하는 조건들 초기화
                        this.doSetScheduleSearchConfig({}); 
    
                        // 로컬 데이터 설정 -> DB 값으로 변경됨
                        // const local_data : string | null = hodu_local_storage.getItem(`${this.user_id}`);
                        // if( local_data != null ) {
                        //     const temp_local_data : LocalStorageInfo = JSON.parse(local_data);
                        //     local_storage_info.schedule_search_config = temp_local_data.schedule_search_config;
                        //     this.doSetScheduleSearchConfig(local_storage_info.schedule_search_config);
                        // }
                        this.getFilterInfo();
    
                        this.doSetCalendarId(`group-${lo_kids.group_id}`);
                        this.doSetScope(OWNER_TYPE.GROUP);  
                        this.doSetScopeGroupId(lo_kids.group_id);
                        this.doSetScopeTeamId(0);
                        this.doSetScopeGroupTeamOption({
                            group_team_name : lo_kids.group_name,
                            group_team_color : lo_kids.group_color,
                            group_team_image : '',
                            group_team_descript : '',
                            biz_id : lo_kids.biz_id,
                            biz_type : GROUP_TYPE.BIZC,
                            login_mode : "KIDS"
                        });
    
                        await this.hodu_loading_timer_exit();
                        await this.hodu_loading();

                        await this.calendar_preference_sync(false, false);

                        this.hodu_router_push('/');
                    }
                        
                });
    
                hodu_local_storage.setItem('auto_login_flag', String(this.auto_login_flag));
                hodu_cookie.set('auto_login_flag', String(this.auto_login_flag));
            };

            this.doSetUserId(login_response.data.data.user.user_id);
            if( login_response.data.data.user.auth_info.auth_check == false ) {
                this.auth(login_callback, true);
                return;
            }

            await login_callback();

        } catch(e) {
            this.hodu_error_process(e, true, false);
        }
    }

    /**
     * 호두 프리미엄 그룹 도메인 서비스 처리
     */
    async hodu_domain_service(response: any, is_auto_login : boolean = false) : Promise<void> {
        let is_join_group : boolean = false;

        const login_callback : Function = async() => {

            // 자동 로그인 유저 정보 세팅
            if( is_auto_login == true ) {
                
                this.doLogin({
                    isLogin           : true,
                    user_id           : response.data.data.user.user_id,
                    user_type         : response.data.data.last_login_type,
                    user_email        : response.data.data.user.user_email,
                    user_name         : response.data.data.user.user_name,
                    user_phone_number : response.data.data.user.user_phone_number,
                    country_code      : response.data.data.user.country_code,
                    is_temp_password  : response.data.data.is_temp_password,
                    user_preference   : response.data.data.user_preference,
                    user_group_role   : [],
                    user_team_role    : [],
                    template_map      : response.data.data.template_map,
                    auth_info         : response.data.data.user.auth_info,
                });
            }
            
            // 수동 로그인 유저 정보 세팅
            else {
                this.doLogin({
                    isLogin           : true,
                    user_id           : response.data.data.user.user_id,
                    user_type         : this.login_type,
                    user_email        : response.data.data.user.user_email,
                    user_name         : response.data.data.user.user_name,
                    user_phone_number : response.data.data.user.user_phone_number,
                    country_code      : response.data.data.user.country_code,
                    is_temp_password  : response.data.data.is_temp_password,
                    user_preference   : response.data.data.user_preference,
                    user_group_role   : [],
                    user_team_role    : [],
                    template_map      : response.data.data.template_map,
                    auth_info         : response.data.data.user.auth_info,
                });
            }
    
            // 그룹 정보 동기화
            await this.get_group_role_service();
    
            // 해당 사용자의 그룹 가입 여부 체크
            let group_color = hodu_color.hodu_dc_7;
            for( const group_data of this.hodu_c_group_data ) {
                if( group_data.group_id != this.domain_group_info.group_id ) continue;
                is_join_group = true;
                group_color = group_data.color;
                break;
            }
    
            if( is_join_group == false ) {
    
                if( this.login_type == "KAKAOTALK" ) { 
                    if(!Kakao.isInitialized()){ await Kakao.init('c18796cb68948dbecc66ea05f7fa7773'); }
                    Kakao.Auth.logout((data) => {}); 
                }
    
                // 로그인 정보 없애기
                this.doLogin({
                    isLogin           : false,
                    user_id           : 0,
                    user_type         : "",
                    user_email        : "",
                    user_name         : "",
                    user_phone_number : "",
                    country_code      : "",
                    is_temp_password  : false,
                    user_preference   : null,
                    user_group_role   : null,
                    user_team_role    : null,
                    template_map      : null,
                    auth_info         : {
                        auth_check: false, 
                        auth_check_date: null, 
                        auth_delay_date: null, 
                        auth_check_later: false
                    },
                    schedule_search_config : {
                        past_schedule     : true,
                        personal_schedule : true,
                        my_schedule       : true,
                        shared_schedule   : true,
                        group_schedule    : true,
                        hodu_c_schedule   : true,
                        group_filter      : [],
                        team_filter       : [],    
                    }
                });
    
                this.hodu_show_dialog("cancel", "해당 그룹에 가입되지 않은 계정입니다", ['확인'], [() => { this.hodu_router_push('/login'); }]);
                return;
            }
    
            else {
                // 초기화 되어야하는 조건들 초기화
                this.doSetScheduleSearchConfig({}); 
    
                // 로컬 데이터 설정 -> DB 값으로 변경됨
                // const local_data : string | null = hodu_local_storage.getItem(`${this.user_id}`);
                // if( local_data != null ) {
                //     const temp_local_data : LocalStorageInfo = JSON.parse(local_data);
                //     local_storage_info.schedule_search_config = temp_local_data.schedule_search_config;
                //     this.doSetScheduleSearchConfig(local_storage_info.schedule_search_config);
                // }
                this.getFilterInfo();
    
                this.doSetCalendarId(`group-${this.domain_group_info.group_id}`);
                this.doSetScope(OWNER_TYPE.GROUP);  
                this.doSetScopeGroupId(this.domain_group_info.group_id);
                this.doSetScopeTeamId(0);
                this.doSetScopeGroupTeamOption({
                    group_team_name : this.domain_group_info.group_info.group_name,
                    group_team_color : group_color,
                    group_team_image : '',
                    group_team_descript : '',
                    biz_id : this.domain_group_info.biz_id,
                    biz_type : GROUP_TYPE.BIZC,
                    is_domain_service : true
                });
    
                await this.hodu_loading_timer_exit();
                await this.hodu_loading();

                await this.calendar_preference_sync(false, false);

                this.hodu_router_push('/');
    
                hodu_local_storage.setItem('auto_login_flag', String(this.auto_login_flag));
                hodu_cookie.set('auto_login_flag', String(this.auto_login_flag));
            }

        };

        this.doSetUserId(response.data.data.user.user_id);

        // 휴대폰 로그인인데 인증이 안된 경우
        if( this.login_type == 'PHONE' && response.data.data.user.auth_info.auth_check == false ) {
            this.auth(login_callback, true);
            return;
        }

        // 다음에 인증 누르지 않은 경우, 어느 순간 앱에서 사용 안하길래 제거함
        // if( response.data.data.user.auth_info.auth_check == false && response.data.data.user.auth_info.auth_check_later == false ) {
        //     this.auth(login_callback, false);
        //     return;
        // }

        await login_callback();
    }

    /**
     * 비밀번호 찾기
     */
    findPassword() : void {
        this.hodu_router_push('/find/password');
    }

    /**
     * 자동 로그인 - 유저 정보 불러오기
     */
    async autoLogin() : Promise<void> {
        const vue = this;

        let device_uid : string | null = hodu_local_storage.getItem('device_uid');
        let session_token : string | null = hodu_local_storage.getItem('session_token');

        const device_uid_cookie = hodu_cookie.get('device_uid');
        const session_token_cookie = hodu_cookie.get('session_token');
        
        // device_uid 값 session_token값 둘중 하나라도 제대로 안되어있다면 쿠키를 체크 한다
        if( device_uid == null || device_uid.length < 1 || session_token == null || session_token.length < 1 ) {
            hodu_local_storage.remove('device_uid');
            hodu_local_storage.remove('session_token');

            // 쿠키도 이상하다면 자동로그인 최종 실패
            if( device_uid_cookie == null || device_uid_cookie.length < 1 || session_token_cookie == null || session_token_cookie.length < 1 ) {
                return;
            }

            // 쿠키라도 정상이라면 쿠키 값 사용
            hodu_local_storage.setItem('device_uid', device_uid_cookie);
            hodu_local_storage.setItem('session_token', session_token_cookie);

            device_uid = device_uid_cookie;
            session_token = session_token_cookie;
        }

        // 세션스토리지가 없다면 나간다
        if( sessionStorage == null ) {
            return;
        }

        sessionStorage.setItem("device_uid", device_uid);
        sessionStorage.setItem("session_token", session_token);
        
        vue.$nextTick(async() => {

            // 유저 정보 가져오기 API
            try {
                const response = await this.hodu_api_call('api/v1/user/me', API_METHOD.GET)

                console.log(response);

                if( response == null || !this.isHttpStatusSuccess(response.status) || response.data == null || response.data.data == null ) {
                    throw new Error("자동 로그인 실패");
                }

                // 알림을 지원하지 않는다면 아무것도 하지않는다, 지원 하면 푸시토큰 업데이트
                if ('Notification' in window && Notification.permission == 'granted') {
                    updatePushToken(vue);
                }

                this.login_type = response.data.data.last_login_type;
                
                // 호두닥 로그인
                if ( this.is_hodu_doc ) {
                    this.getHoduDList(response, true);
                    return;
                }

                // 호두홈 로그인
                else if( this.is_hodu_home == true ) {
                    this.getHoduHList(response, true);
                    return;
                }

                // 호두키즈 로그인
                else if( this.is_hodu_kids == true ) {
                    this.getHoduKidsList(response, true);
                    return;
                }

                // 도메인 서비스 로그인
                else if( this.domain_group_info != null ) {
                    await this.hodu_domain_service(response, true);
                    return;
                }

                const login_callback : Function = async() => {
                    
                    await this.hodu_loading_timer_exit();
                    await this.hodu_loading();

                    // 유저 정보 세팅
                    Promise.all([vue.doLogin({
                        isLogin           : true,
                        user_id           : response.data.data.user.user_id,
                        user_type         : response.data.data.last_login_type,
                        user_email        : response.data.data.user.user_email,
                        user_name         : response.data.data.user.user_name,
                        user_phone_number : response.data.data.user.user_phone_number,
                        is_temp_password  : response.data.data.is_temp_password,
                        user_preference   : response.data.data.user_preference,
                        user_group_role   : [],
                        user_team_role    : [],
                        template_map      : response.data.data.template_map,
                        auth_info         : response.data.data.user.auth_info
                    }), vue.get_group_role_service()]).then(async() => {
    
                        // 초기화 되어야하는 조건들 초기화
                        this.doSetScheduleSearchConfig({}); 
    
                        // 로컬 데이터 설정 -> DB 값으로 변경됨
                        // const local_data : string | null = hodu_local_storage.getItem(`${this.user_id}`);
                        // if( local_data != null ) {
                        //     const temp_local_data : LocalStorageInfo = JSON.parse(local_data);
                        //     local_storage_info.schedule_search_config = temp_local_data.schedule_search_config;
                        //     this.doSetScheduleSearchConfig(local_storage_info.schedule_search_config);
                        // }
                        this.getFilterInfo();
    
                        // 일반 로그인
                        this.doSetCalendarId(`personal-${response.data.data.user.user_id}`);
                        this.doSetScope(OWNER_TYPE.PERSONAL);  
                        this.doSetScopeGroupId(0);
                        this.doSetScopeTeamId(0);
                        this.doSetScopeGroupTeamOption({
                            group_team_name : '',
                            group_team_color : '#FFFFFF',
                            group_team_image : '',
                            group_team_descript : '',
                            biz_id : '',
                            biz_type : ''
                        });

                        await this.calendar_preference_sync(false, false);

                        this.hodu_router_push('/');
    
                        hodu_local_storage.setItem('auto_login_flag', String(this.auto_login_flag));
                        hodu_cookie.set('auto_login_flag', String(this.auto_login_flag));
                    });

                };

                this.doSetUserId(response.data.data.user.user_id);
                
                // 휴대폰 로그인인데 인증이 안된 경우
                if( this.login_type == 'PHONE' && response.data.data.user.auth_info.auth_check == false ) {
                    this.auth(login_callback, true);
                    return;
                }

                // 다음에 인증 누르지 않은 경우, 어느 순간 앱에서 사용 안하길래 제거함
                // if( response.data.data.user.auth_info.auth_check == false && response.data.data.user.auth_info.auth_check_later == false ) {
                //     this.auth(login_callback, false);
                //     return;
                // }

                await login_callback();
            }

            catch(e) {
                this.autoLoginFail(e);
            }

        });
    }

    /**
     * 자동 로그인 실패
     */
    autoLoginFail(e) : void {

        try {
            const device_uid_cookie = hodu_cookie.get('device_uid');
            const session_token_cookie = hodu_cookie.get('session_token');

            this.hodu_show_dialog("cancel", "세션 오류, 자동 로그인 실패", ["확인"], [() => {}]);
                    
            // 세션 오류 Exception Report
            let error_message = ``;

            if( e.response ) {
                error_message += e.response.data;
            }

            if( localStorage != null ) {
                error_message += `\n[local] device_uid : ${hodu_local_storage.getItem("device_uid")}\n[local] session_token : ${hodu_local_storage.getItem("session_token")}`;
            }
            
            if( sessionStorage != null ) {
                error_message += `\n[session] device_uid : ${sessionStorage.getItem("device_uid")}\n[session] session_token : ${sessionStorage.getItem("session_token")}`;
            }

            error_message += `\n[cookie] device_uid : ${device_uid_cookie}\n[cookie] session_token : ${session_token_cookie}`;

            let session_error = new Error(error_message);
            this.hodu_error_process(session_error, false, false, true);

            // 자동로그인 관련 설정
            hodu_local_storage.setItem('auto_login_flag', String(false));
            hodu_cookie.delete("auto_login_flag");

            // 세션 없애기
            if(sessionStorage != null && localStorage != null){
                sessionStorage.setItem("session_token", "");
                localStorage.setItem("session_token"  , "");
                hodu_cookie.delete("session_token");
            }
            
            // 로그인 정보 없애기
            this.doLogin({
                isLogin           : false,
                user_id           : 0,
                user_type         : "",
                user_email        : "",
                user_name         : "",
                user_phone_number : "",
                country_code      : "",
                is_temp_password  : false,
                user_preference   : null,
                user_group_role   : null,
                user_team_role    : null,
                template_map      : null,
                auth_info         : {
                    auth_check: false, 
                    auth_check_date: null, 
                    auth_delay_date: null, 
                    auth_check_later: false
                },
                schedule_search_config : {
                    past_schedule     : true,
                    personal_schedule : true,
                    my_schedule       : true,
                    shared_schedule   : true,
                    group_schedule    : true,
                    hodu_c_schedule   : true,
                    group_filter      : [],
                    team_filter       : [],    
                }
            });
                
            hodu_local_storage.remove('device_uid');
            hodu_local_storage.remove('session_token');
            hodu_cookie.delete('device_uid');
            hodu_cookie.delete('session_token');
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    isExistAdditionalLogin() : boolean {
        return isIE();
    }

    /**
     * URL에 따른 로그인 화면 텍스트 반환
     */
    getLoginName() : string {
        if( this.is_hodu_doc == true ) return "HoduDoc";
        if( this.is_hodu_home == true ) return "HoduHome";
        if( this.is_hodu_kids == true ) return "HoduKids";
        if( this.domain_group_info != null ) return this.domain_group_info.display_name ? this.domain_group_info.display_name : "";
        return "";
    }

    /**
     * 인증
     */
    auth(callback : Function, is_auth_service : boolean) : void {

        try {
            let popup : Window | null = null;

            window['skip_button_select'] = false;

            window['auth_callback'] = (user_info) => {
                try {

                    // 호두닥, 호두홈, 호두키즈 접속은 미인증시 이용 불가
                    if( is_auth_service == true && user_info.auth_info.auth_check == false ) {
                        this.hodu_show_dialog('cancel', "휴대폰 인증 후에만 이용 할 수 있는 서비스입니다", ['취소', '인증'], [
                            () => {},
                            () => {
                                this.auth(callback, is_auth_service);
                            }
                        ]);
                        return;
                    }

                    // 핸드폰 로그인이라면 인증시에만 사용 가능
                    if( this.login_type == 'PHONE' && user_info.auth_info.auth_check == false ) {
                        this.hodu_show_dialog('cancel', "휴대폰 인증 후에만 이용 할 수 있는 서비스입니다", ['취소', '인증'], [
                            () => {},
                            () => {
                                this.auth(callback, is_auth_service);
                            }
                        ]);
                        return;
                    }

                    callback?.();
                    this.doSetAuthInfo(user_info.auth_info);
                    this.doSetUserPhoneNumber(user_info.user_phone_number);
                    this.doSetCountryCode(user_info.country_code);

                    // console.log(authResult);
                    // this.firebase_auth(authResult, auth_check_later, (user_info) => {
                    //     try {
                    //         console.log(user_info);

                    //         // 호두닥, 호두홈, 호두키즈 접속은 미인증시 이용 불가
                    //         if( is_auth_service == true && user_info.auth_info.auth_check == false ) {
                    //             this.hodu_show_dialog('cancel', "휴대폰 인증 후에만 이용 할 수 있는 서비스입니다", ['취소', '인증'], [
                    //                 () => {},
                    //                 () => {
                    //                     this.auth(callback, is_auth_service);
                    //                 }
                    //             ]);
                    //             return;
                    //         }

                    //         // 핸드폰 로그인이라면 인증시에만 사용 가능
                    //         if( this.login_type == 'PHONE' && user_info.auth_info.auth_check == false ) {
                    //             this.hodu_show_dialog('cancel', "휴대폰 인증 후에만 이용 할 수 있는 서비스입니다", ['취소', '인증'], [
                    //                 () => {},
                    //                 () => {
                    //                     this.auth(callback, is_auth_service);
                    //                 }
                    //             ]);
                    //             return;
                    //         }

                    //         callback?.();
                    //         this.doSetAuthInfo(user_info.auth_info);
                    //         this.doSetUserPhoneNumber(user_info.user_phone_number);
                    //         this.doSetCountryCode(user_info.country_code);

                    //     } catch(e) {
                    //         this.hodu_error_process(e, false, false, true);
                    //     }
                    // });
                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                } finally {
                    popup?.close();
                }
            }

            const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
            const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

            const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
            const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

            const systemZoom = width / window.screen.availWidth;


            const w = 500;
            const h = 626;
            const l = (width - w) / 2 / systemZoom + dualScreenLeft;
            const t = (height - h) / 2 / systemZoom + dualScreenTop;

            popup = window.open(`/naver-auth.html?device_uid=${this.get_device_uid()}&session_token=${this.get_session_token()}`, `naver-auth`, `toolbar=0, scrollbars=0, resizable=0, width=${w}, height=${h}, left=${l}, top=${t}`);

        } catch(e) {
            console.log(e);
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 비밀번호 로그인 모드 변경
     */
    changePasswordLoginMode(mode : string) : void {
        this.password_login_mode = mode;
        this.id = "";
        this.pw = "";
    }

}

