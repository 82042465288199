
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { ResizeObserver } from 'vue-resize';
import { namespace } from 'vuex-class';
const MenuInfo = namespace('MenuInfo');

@Component({
    components: {
        ResizeObserver
    },
})
export default class RightEtc extends Mixins(VueHoduCommon) {
    @MenuInfo.Action doSetEtcMenuOpen ?: any;

    mounted() : void {
        this.setScroll();
    }

    goHoduNoticeList() : void {
        this.doSetEtcMenuOpen(false);
        this.hodu_router_push(`/hodu/${new Date().getTime()}/notice`);
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height : number | undefined = $('.noti_titlebox.mEtc').outerHeight();

        // @ts-ignore
        $('#etcNotiWrap').mCustomScrollbar({
            axis : 'y',
            setHeight : window.innerHeight - ( title_height == null ? 0 : title_height ),
            scrollbarPosition : 'outside',
        });
    }

    /**
     * 주요기능으로 이동
     */
    goFeatures() : void {
        let url : string = '';

        if(location.port == "80" || location.port == "443"){
            url = '//hodu.app/#/features';
        } else {
            url = `//hodu.app:${location.port}/#/features`;
        }

        let win : any = window.open(url, '_blank');
        win.focus();
    }

    /**
     * 매뉴얼로 이동
     */
    goManual() : void {
        let url : string = '';

        if(location.port == "80" || location.port == "443"){
            url = '//hodu.app/#/manual';
        } else {
            url = `//hodu.app:${location.port}/#/manual`;
        }

        let win : any = window.open(url, '_blank');
        win.focus();
    }

    /**
     * 관리자 매뉴얼로 이동
     */
    goAdminManual() : void {
        // let url : string = '';

        // if(location.port == "80" || location.port == "443"){
        //     url = '//hodu.app/#/manual';
        // } else {
        //     url = `//hodu.app:${location.port}/#/manual`;
        // }

        // let win : any = window.open(url, '_blank');
        // win.focus();

        this.$http({
            url : 'app_images/hodu_admin_manual.pdf',
            method : 'GET',
            responseType: 'blob'
        }).then(async(response) => {
            const blob = new Blob([response.data], { "type" : "application/pdf" });

            // @ts-ignore
            if ( window.navigator && window.navigator.msSaveOrOpenBlob ) {
                // @ts-ignore
                window.navigator.msSaveOrOpenBlob(blob, "hodu_admin_manual.pdf"); // for IE
                return;
            }

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'hodu_admin_manual.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            let newWin = window.open(url);
            if( newWin == null ) return;
            newWin.focus();
        })
        .catch((e) => {
            alert("파일 다운로드 실패");
        });
    }

    /**
     * 이용약관 팝업
     */
    goTermsOfUse() : void {
        let url : string = '';

        if(location.port == "80" || location.port == "443"){
            url = '//hodu.app/#/termsOfUse';
        } else {
            url = `//hodu.app:${location.port}/#/termsOfUse`;
        }

        let win : any = window.open(url, '_blank');
        win.focus();
    }

    /**
     * 개인정보취급방침
     */
    goPrivacyPolicy() : void {
        let url : string = '';

        if(location.port == "80" || location.port == "443"){
            url = '//hodu.app/#/privacyPolicy';
        } else {
            url = `//hodu.app:${location.port}/#/privacyPolicy`;
        }

        let win : any = window.open(url, '_blank');
        win.focus();
    }

    /**
     * 고객센터
     */
    goCustomerCenter() : void {
        let url : string = '';

        if(location.port == "80" || location.port == "443"){
            url = '//hodu.app/#/serviceCenter';
        } else {
            url = `//hodu.app:${location.port}/#/serviceCenter`;
        }

        let win : any = window.open(url, '_blank');
        win.focus();
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#etcNotiWrap').mCustomScrollbar('destroy');
    
        this.setScroll();
    }
}
