
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { CommonInputModalInfo } from '@/store/modules/ModalInfo';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class CommonInputModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State common_input_modal_info !: CommonInputModalInfo;

    selected_radio : string = "";
    is_wrong : boolean = false;

    check_flag : boolean = false;

    mounted() : void {
        if( this.hasRadio() == true ) this.selected_radio = this.common_input_modal_info.radio.list[0].value;
        $('#commonInputModal .input_modal_content').focus();
    }

    hasRadio() : boolean {
        return this.common_input_modal_info.radio != null && 
               this.common_input_modal_info.radio.list !=  null && 
               this.common_input_modal_info.radio.list.length > 0;
    }

    /**
     * 잘못된 입력 (에러메세지는 modal 사용하는곳에서 제어)
     */
    wrong() : void {
        this.is_wrong = true;
    }

    /**
     * 텍스트 입력
     */
    input(event) : void {
        this.is_wrong = false;
        this.common_input_modal_info.content = event.target.value;
    }

    /**
     * 취소
     */
    cancel() : void {
        this.common_input_modal_info.cancel?.();
        this.close();
    }

    /**
     * 값 체크
     */
    check() : boolean {

        if( this.common_input_modal_info.content == null || this.common_input_modal_info.content.trim().length < 1 ) {
            this.is_wrong = true;
            $('.input_modal_content').focus();
            return false;
        }

        return true;
    }

    /**
     * 저장
     */
    save() : void {

        // 값 체크
        if( this.check() == false ) return;

        this.common_input_modal_info.save?.(this.common_input_modal_info.content, this.selected_radio, this.check_flag);
        this.close();
    }

    close() : void {
        this.doSetCommonInputModalInfo?.({ show_modal : false });
    }

}
