
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { OWNER_TYPE, EVENT_SUB_TYPE, CRUD_TYPE, API_METHOD, SHARE_OPTION } from "@/mixin/VueHoduCommon";

import { t_event, t_event_file, t_event_alarm, t_event_location, t_event_vote } from '@/model/event';
import { t_vote_item, t_event_attend, t_work_template, t_work_status, t_user } from '@/model/osm';

import { namespace } from 'vuex-class';
const EventInfo = namespace('EventInfo');
const WorkInfo  = namespace('WorkInfo');
const ModalInfo = namespace('ModalInfo');

import { EventOriginalDate, EventShareInfo } from '@/store/modules/EventInfo';
import { EventEmailShareModalInfo, EventHistoryModalInfo, EventReadModalInfo } from '@/store/modules/ModalInfo';

import moment from 'moment';
import 'moment-lunar';

import { RRule, RRuleSet, rrulestr } from 'rrule'
import { GroupTeamInfo } from '@/store/modules/GroupInfo';

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

import { ResizeObserver } from 'vue-resize';
import { hodu_local_storage, local_storage_info, LocalStorageInfo } from '../lib/HoduLocalStorage';

declare let Kakao : any;
import '@/assets/js/kakao';

@Component({
    components: {
        ResizeObserver
    },
}) export default class Event extends Mixins(VueHoduCommon) {

    get readCount() : number {
        
        for( const read of this.read_info ) {
            if( read.repeat_seq == this.repeat_seq ) {
                return read.count;
            }
        }

        return 0;
    }

    /**
     * EventInfo.State
     */
    @EventInfo.State  event                      !: t_event;
    @EventInfo.State  event_crud_type            !: string
    @EventInfo.State  event_original_date        !: EventOriginalDate;
    @EventInfo.State  selected_location          !: t_event_location;
    @EventInfo.State  event_share_info           !: EventShareInfo;
    @EventInfo.State  is_moved_by_short_create   !: boolean;

    /**
     * EventInfo.Action
     */
    @EventInfo.Action doSetRepeatModifyAction    ?: any;  
    @EventInfo.Action doSetLocationSearchQuery   ?: any;
    @EventInfo.Action doSetSelectedLocation      ?: any;
    @EventInfo.Action doSetEventImagePreviewInfo ?: any;
    @EventInfo.Action doSetVote                  ?: any;
    @EventInfo.Action doSetVoteEndDateLimit      ?: any;
    @EventInfo.Action doSetVoteModifyIndex       ?: any;

    /**
     * @WorkInfo.Action
     */
    @WorkInfo.Action doSetWorkTemplateId ?: any;
    @WorkInfo.Action doSetWorkStatusInfo ?: any;

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetShowEventRepeatModify    ?: any;
    @ModalInfo.Action doSetShowEventImagePreview    ?: any;
    @ModalInfo.Action doSetShowEventDaumMap         ?: any;
    @ModalInfo.Action doSetShowEventShare           ?: any;
    @ModalInfo.Action doSetEventShareStatusInfo     ?: any;
    @ModalInfo.Action doSetEventEmailShareModalInfo ?: (parms : EventEmailShareModalInfo) => void;
    @ModalInfo.Action doSetShowEventAttendResult    ?: any;
    @ModalInfo.Action doSetShowEventVoteCreate      ?: any;
    @ModalInfo.Action doSetShowEventVoteResult      ?: any;
    @ModalInfo.Action doSetShowEventReply           ?: any;
    @ModalInfo.Action doSetShowEventWorkProcess     ?: any;
    @ModalInfo.Action doSetEventWorkAssignmentInfo  ?: any;
    @ModalInfo.Action doSetShowWorkStatus           ?: any;
    @ModalInfo.Action doSetShowWorkDefaultStatus    ?: any;
    @ModalInfo.Action doSetEventHistoryModalInfo    ?: (params : EventHistoryModalInfo) => void;
    @ModalInfo.Action doSetEventReadModalInfo       ?: (params : EventReadModalInfo) => void;

    share_infos : any[] = [];
    share_info : any = null;

    date_term : number = 0;
    group_team_list : any = [];
    colorToneMode : string = "dc";
    group_color : string = "transparent";

    start_date  : string = "";
    start_time  : string = "";
    start_hour  : string = "";
    start_min   : string = "";
    start_am_pm : string = "";

    end_date  : string = "";
    end_time  : string = "";
    end_hour  : string = "";
    end_min   : string = "";
    end_am_pm : string = "";

    lunar_date_text                    : string = ""; // 음력 텍스트

    recurrence_html                    : string = ""; // 일반적으로 가운데 텍스트와 같음
    recurrence_start_text              : string = ""; // ex) 2019.10.17 목요일 [반복 시작일 텍스트]   
    recurrence_start_for_month_text    : string = ""; // ex) 매 월 17일 [매월 반복 ~일 텍스트]
    recurrence_start_for_month_th_text : string = ""; // ex) 매 월 3번째 목요일 [매월 반복 ~번째 ~요일 텍스트] 
    recurrence_start_for_year_text     : string = ""; // ex) 매 10월 17일 [매년 반복 ~월 ~일 텍스트 ]
    recurrence_start_for_year_th_text  : string = ""; // ex) 매 10월 3번째 목요일 [매년 반복 ~월 ~번째 주 ~ 요일 텍스트]
    recurrence_end_text                : string = ""; // ex) 2019.10.17 목요일 [반복종료일 텍스트]

    day_repeat   : boolean = true;
    week_repeat  : boolean = false;
    month_repeat : boolean = false;
    year_repeat  : boolean = false;

    repeat_infinite : boolean = true;
    month_year_left_option : boolean = true;
    repeat_value : number | null = null;

    selected_day_of_week : number[] = []; // ex) [0, 1, 2, 3, 4, 5, 6] => 일 월 화 수 목 금 토

    alarm_model : string[] = [];

    mapSearchQuery : string = "";

    // 새로 첨부할 이미지, 파일 객체 담는 곳  { index : number, file : File }
    image_files : any = []; 
    all_files   : any = [];

    image_max_cnt : number = 4;
    image_drag : boolean = false;

    file_permission : boolean = false;
    file_drag : boolean = false;

    // 공유 옵션
    share_option : SHARE_OPTION = SHARE_OPTION.SHARE;
    selected_friend_user_ids : number[] = [];
    group_user_selected_key : string[] = [];
    team_user_selected_key : string[] = [];
    selected_user_ids : number[] = [];
    selected_group_ids : number[] = [];
    selected_team_ids : number[] = [];
    
    push_yn : string = 'Y';

    // 업무
    work_template_list  : t_work_template[] = []; // 업무 템플릿 리스트 전체
    flow_template_list  : t_work_template[] = []; // 단계 업무 템플릿 리스트
    check_template_list : t_work_template[] = []; // 체크 업무 템플릿 리스트

    target_user_list       : t_user[]               = [];    // 업무 대상이 될수있는 그룹원, 팀원   
    selected_work_template : t_work_template | null = null;  // 선택된 업무 템플릿
    assignment_user_list   : number[]               = [];    // 업무자 user_id 리스트
    work_status_list       : t_work_status[]        = [];    // 업무자별 업무 상태
    user_work_status       : t_work_status | null   = null;  // 사용자의 업무 상태

    all_member_count : number = 0;

    show_work_template : boolean = false;
    view_template_type : string  = 'ALL';

    // 조회에서 사용하는 변수
    is_attendable   : boolean = false;
    attend_data     : t_event_attend | null = null;
    vote_result     : t_vote_item | null = null;
    new_vote_index  : number[] = [];
    is_exist_reply  : boolean = false;

    attendable_user_count : number = 0;
    attend_user_count : number = 0;
    vote_user_count : number[] = [];
    read_info : any[] = [];

    // 수정할때 사용되는 변수
    repeat_modify_code : string = ''; // 비어있음 : 일반 수정 
                                      // A : 전체 일정 수정 
                                      // F : 이후 일정 수정
                                      // E : 이 일정만 수정

    // layout 조건
    group_select_open : boolean = false;
    color_select_open : boolean = false;
    time_from_open    : boolean = false;
    time_to_open      : boolean = false;
    repeat_open       : boolean = false;
    alarm_list_open   : boolean = false;
    read_share_flag   : boolean = false;

    // 권한
    is_createable : boolean = false; // 생성 권한
    is_copyable   : boolean = false; // 복사 권한
    is_editable   : boolean = false; // 수정 권한 (일정 수정)
    is_deleteable : boolean = false; // 삭제 권한 (일정 삭제)
    is_shared     : boolean = false; // 공유 받아서 볼 수 있는 일정 임을 나타내는 플래그
    is_premium_event : boolean = false;

    detail_scroll_height : number = 0;

    recurrence_end : Date = new Date();

    repeat_seq : number = 0;

    show_private_tooltip : boolean = false;

    async beforeMount() : Promise<void> {

        // 들어올때 event_id가 있는 경우 조회
        if( this.event_crud_type != CRUD_TYPE.CREATE ) {
            await this.getEvent();
            await this.makeDateString();
            await this.makeStringByRrule();
        }
    }

    async mounted() : Promise<void> {

        //hover
        // $('.tooltipSpan').hover(function(){
        //     $('.requiredToolBox').css("display", "block");
        // }, function(){
        //     $('.requiredToolBox').css("display", "none");
        // });

        const vue = this;
        await this.setDatePicker();

        // 생성일때만 
        if( this.event_crud_type == CRUD_TYPE.CREATE ) {
            this.makeDateString();

            // 반복값이 있는 일정을 복사하고 새로고침했을때
            if( this.event.event_data.schedule_date.rrule != null ) {
                this.makeStringByRrule();
            }

            // 프로젝트가 아닌 임시보관함 이벤트들
            if( this.event.is_temp == true && this.event.event_sub_type != EVENT_SUB_TYPE.WORK ) {
                this.share_option = (this.event.share_option ? this.event.share_option : SHARE_OPTION.SHARE);
                this.selected_friend_user_ids = JSON.parse(JSON.stringify((this.event.selected_friend_user_ids ? this.event.selected_friend_user_ids : [])));
                this.group_user_selected_key = JSON.parse(JSON.stringify((this.event.group_user_selected_key ? this.event.group_user_selected_key : [])));
                this.team_user_selected_key = JSON.parse(JSON.stringify((this.event.team_user_selected_key ? this.event.team_user_selected_key : [])));
                this.selected_user_ids = JSON.parse(JSON.stringify((this.event.selected_user_ids ? this.event.selected_user_ids : [])));
                this.selected_group_ids = JSON.parse(JSON.stringify((this.event.selected_group_ids ? this.event.selected_group_ids : [])));
                this.selected_team_ids = JSON.parse(JSON.stringify((this.event.selected_team_ids ? this.event.selected_team_ids : [])));

                // selected_friend_user_ids, group_user_selected_key, team_user_selected_key 실제 데이터와 비교해서 선택못하는건 제외하자
                this.selected_friend_user_ids = this.selected_friend_user_ids.filter(user_id => this.getFriend(user_id) != null);
                this.group_user_selected_key = this.group_user_selected_key.filter(key => this.group_info_list.filter(group_info => (group_info.group_id == Number(key.split[0]))).length > 0);
                this.team_user_selected_key = this.team_user_selected_key.filter(key => this.group_info_list.filter(group_info => (group_info.team_id == Number(key.split[0]))).length > 0);

                this.selected_user_ids = this.selected_user_ids.filter(user_id => (this.selected_friend_user_ids.indexOf(user_id) > -1) ||
                                                                                  (this.group_user_selected_key.filter(key => user_id == Number(key.split[1]))).length > 0 || 
                                                                                  (this.team_user_selected_key.filter(key => user_id == Number(key.split[1]))).length > 0 );
                this.selected_group_ids = this.selected_group_ids.filter(group_id => this.group_info_list.filter(group_info => (group_info.team_id == group_id)).length > 0);
                this.selected_team_ids = this.selected_team_ids.filter(team_id => this.group_info_list.filter(group_info => (group_info.team_id == team_id)).length > 0);
            }
        }

        // 일정 조회 일때만
        else if( this.event_crud_type == CRUD_TYPE.READ && this.event.event_sub_type != EVENT_SUB_TYPE.WORK ) {
            await this.kakaoShareInit();
        }
        
        await this.get_group_role_service();
        await this.permissionCheck();

        // 일정 등록시 시간선택부분 마우스 오버시 가운데 화살표 데코
		$("#s_eventFrYmd, #s_FrTm").mouseover(() => { $(".timeTd .inputgroup").addClass("left"); });
		$("#s_eventFrYmd, #s_FrTm").mouseleave(() => { $(".timeTd .inputgroup").removeClass("left"); });
		$("#s_eventToYmd, #s_ToTm").mouseover(() => { $(".timeTd .inputgroup").addClass("right"); });
		$("#s_eventToYmd, #s_ToTm").mouseleave(() => { $(".timeTd .inputgroup").removeClass("right"); });

        /**
         * 선택된 영역이 ~~이 아니라면 팝업 닫기
         */
        $('html').on("click", (e) => {
            if( e.target.id != "s_FrTm" && e.target.id != "s_frHour" && e.target.id != "s_frMin" && e.target.id != "s_frAmpm" ) { this.time_from_open = false; }
            if( e.target.id != "s_ToTm" && e.target.id != "s_toHour" && e.target.id != "s_toMin" && e.target.id != "s_toAmpm" ) { this.time_to_open   = false; }
        });

        await this.setScroll();
        
        // 그룹 정보 가져오기
        await this.getGroupInformation();

        // 알람
        if( this.event.event_data.alarm != null ){
            const alarm_count : number = this.event.event_data.alarm.length;
            for( let i = 0; i < alarm_count; i++ ){
                this.alarm_model.push(this.event.event_data.alarm[i].trigger);
            }
        }

        // 업무 생성
        if( this.event_crud_type == CRUD_TYPE.CREATE && this.event.event_sub_type == EVENT_SUB_TYPE.WORK ) {

            const temp_template_id = this.event.event_data.work?.template_id ?? "";

            await this.getAllAssignmentUser();
            await this.getWorkTepmplate();

            if( this.event.is_temp == true && this.event.event_data.work != null ) {

                // 선택된 프로젝트 템플릿
                for( const template of this.work_template_list ) {
                    if( template.template_id == temp_template_id ) {
                        this.selectWorkTemplate(template);
                        break;
                    }
                }

                // 선택된 담당자
                let users_temp : number[] | undefined = this.event.event_data.work.assign_user_ids;
                let users : number[] = users_temp ? users_temp : [];

                // 전체
                const all_users = JSON.parse(JSON.stringify(this.assignment_user_list));

                this.assignment_user_list.splice(0, this.assignment_user_list.length);

                // 그롭 또는 팀 멤버와 저장된 담당자 목록 비교해서 그룹 또는 팀에 존재하는 사람만 추가
                for( const all_user_id of all_users ) {
                    for( const selected_user_id of users ) {
                        if( all_user_id == selected_user_id ) {
                            this.assignment_user_list.push(selected_user_id);
                            break;
                        }
                    }
                }

            }
        }

        window["changeSeletedLocation"] = this.changeSeletedLocation;
        window["setShareInfo"]          = this.setShareInfo;
        window["createVote"]            = this.createVote;
        window["updateVote"]            = this.updateVote;
        window["deleteVote"]            = this.deleteVote;
        window["getEvent"]              = this.getEvent;
        window["repeatModify"]          = this.repeatModify;
        window["repeatDelete"]          = this.repeatDelete;
        window["setEventWorkData"]      = this.setEventWorkData;

        await this.getPushYnPreference();

        this.recurrence_end = this.event.event_data.schedule_date.recurrence_end;
    }

    beforeDestroy() : void {
        $('html').unbind();
    }

    /**
     * 스크롤 설정
     */
    async setScroll() : Promise<void> {
        const windowOuterHeight : number | undefined = $(window).outerHeight();
        const titleBoxOuterHeight : number | undefined = $('.title_box').outerHeight();
        
        this.detail_scroll_height = (windowOuterHeight == null ? 0 : windowOuterHeight) - (titleBoxOuterHeight == null ? 0 : titleBoxOuterHeight);

        // @ts-ignore schDetailScroll 스크롤
        // $('#schDetailScroll').mCustomScrollbar({
        //     axis : 'y',
        //     setHeight : (windowOuterHeight == null ? 0 : windowOuterHeight) - (titleBoxOuterHeight == null ? 0 : titleBoxOuterHeight),
        //     scrollbarPosition : 'outside',
        //     mouseWheelPixels:350,
        // });
        
        // 업무에서는 그룹TR 사용 안함
        if( this.event.event_sub_type != EVENT_SUB_TYPE.WORK ) {
            // @ts-ignore schedule_group_div 스크롤
            $('#schedule_group_div').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels:200,
                autoDraggerLength : false,
            });
        }

        // @ts-ignore alarmDivScroll 스크롤
        $('.alarmDivScroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 200,
            autoDraggerLength : false,
        });
    }

    /**
     * push_yn 플래그 업데이트
     */
    async getPushYnPreference() : Promise<void> {
        try {
            let push_yn_preference = await this.get_user_preference("push_yn", false);

            if( push_yn_preference == null ) {
                push_yn_preference = { "user_id" : this.user_id, "cate" : "push_yn", "preference" : { "WEB" : { "report" : true, "meetinglog" : true, "work" : true, "basic" : true } }, "audit_modified" : new Date() };
            }

            let preference = push_yn_preference['preference'];

            if( preference == null ) {
                preference = { "WEB" : { "report" : true, "meetinglog" : true, "work" : true, "basic" : true } };
            }

            let web_push_yn_preference = preference['WEB'];

            if( web_push_yn_preference == null ) {
                web_push_yn_preference = { "report" : true, "meetinglog" : true, "work" : true, "basic" : true };
            }
            
            if( this.isReport(this.event.event_sub_type as EVENT_SUB_TYPE) ) {
                const report_push_yn = web_push_yn_preference['report'];
                this.push_yn = report_push_yn == null || report_push_yn == true ? 'Y' : 'N';
            }

            else if( this.isMeetingLog(this.event.event_sub_type as EVENT_SUB_TYPE) ) {
                const meetinglog_push_yn = web_push_yn_preference['meetinglog'];
                this.push_yn = meetinglog_push_yn == null || meetinglog_push_yn == true ? 'Y' : 'N';
            }

            else if( this.isWork(this.event.event_sub_type as EVENT_SUB_TYPE) ) {
                const work_push_yn = web_push_yn_preference['work'];
                this.push_yn = work_push_yn == null || work_push_yn == true ? 'Y' : 'N';
            }

            else if( this.isEvent(this.event.event_sub_type as EVENT_SUB_TYPE) ) {
                const event_push_yn = web_push_yn_preference['basic'];
                this.push_yn = event_push_yn == null || event_push_yn == true ? 'Y' : 'N';
            }

            console.log(this.push_yn);
            
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.push_yn = 'Y';
        }
    }

    /**
     * datepicker 설정
     */
    async setDatePicker() : Promise<void> { 
        const vue = this;

        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: async function (dateText, inst) {
                const id : string | undefined = $(this).attr('id');

                if( id == null ){
                    return;
                }
                
                const select_date : Date = new Date(dateText); 

                inst.input.val(`${dateText} ${vue.getDayOfWeek(select_date)}`);

                // 시작일 변경
                if( id == 's_eventFrYmd'){
                    
                    // start 원래의 시간, 분 유지
                    select_date.setHours(vue.event.event_data.schedule_date.start instanceof Date 
                                       ? vue.event.event_data.schedule_date.start.getHours() 
                                       : new Date(vue.event.event_data.schedule_date.start).getHours());

                    select_date.setMinutes(vue.event.event_data.schedule_date.start instanceof Date 
                                         ? vue.event.event_data.schedule_date.start.getMinutes() 
                                         : new Date(vue.event.event_data.schedule_date.start).getMinutes()); 

                    // date_term 유지 준비 
                    const term_date : Date = new Date(select_date.getTime());

                    // end 원래의 시간, 분 유지
                    term_date.setHours(vue.event.event_data.schedule_date.end instanceof Date 
                                       ? vue.event.event_data.schedule_date.end.getHours() 
                                       : new Date(vue.event.event_data.schedule_date.end).getHours());

                    term_date.setMinutes(vue.event.event_data.schedule_date.end instanceof Date 
                                         ? vue.event.event_data.schedule_date.end.getMinutes() 
                                         : new Date(vue.event.event_data.schedule_date.end).getMinutes()); 

                    // date_term 유지
                    term_date.setDate(term_date.getDate() + vue.date_term);

                    // 종료일이 시작일 보다 느릴때 시작일의 +1일을 해준다 ex) 시작일이 오후 11:50, 종료일이 다음날 오전 12:00 일때 시작 년월일을 바꾸는 경우
                    if( term_date.getTime() < select_date.getTime() ) {
                        term_date.setDate(select_date.getDate() + 1);
                    }

                    // 반복이 없다면 recurrence_end를 변경 시켜줌
                    if( vue.event.event_data.schedule_date.rrule == null || vue.event.event_data.schedule_date.rrule == "" ){
                        vue.event.event_data.schedule_date.recurrence_end = term_date;
                    } 
                    
                    // 반복이 있다면 시작일은 반복종료일 보다 느릴 수 없다
                    else {
                        const start_for_compare : Date = new Date(select_date);
                        start_for_compare.setHours(0);
                        start_for_compare.setMinutes(0);
                        start_for_compare.setSeconds(0);
                        start_for_compare.setMilliseconds(0);

                        const recurrence_end_for_compare : Date = new Date(vue.event.event_data.schedule_date.recurrence_end);
                        recurrence_end_for_compare.setHours(0);
                        recurrence_end_for_compare.setMinutes(0);
                        recurrence_end_for_compare.setSeconds(0);
                        recurrence_end_for_compare.setMilliseconds(0);

                        if( vue.repeat_infinite == false && start_for_compare.getTime() > recurrence_end_for_compare.getTime() ) {
                            vue.hodu_show_dialog('alert', '시작일은 반복 종료일 보다 느릴 수 없습니다', ['확인']);
                            inst.input.val(`${moment(new Date(vue.event.event_data.schedule_date.start)).format('YYYY.MM.DD')} ${vue.getDayOfWeek(new Date(vue.event.event_data.schedule_date.start))}`);
                            return;
                        }
                    }

                    // date 적용
                    vue.event.event_data.schedule_date.start = select_date;
                    vue.event.event_data.schedule_date.end   = term_date;

                // 종료일 변경
                } else {

                    // end 원래의 시간, 분 유지
                    select_date.setHours(vue.event.event_data.schedule_date.end instanceof Date 
                                       ? vue.event.event_data.schedule_date.end.getHours() 
                                       : new Date(vue.event.event_data.schedule_date.end).getHours());

                    select_date.setMinutes(vue.event.event_data.schedule_date.end instanceof Date 
                                         ? vue.event.event_data.schedule_date.end.getMinutes() 
                                         : new Date(vue.event.event_data.schedule_date.end).getMinutes()); 

                    // 시작일과 종료일이 완벽히 같은 시간이라면 => 종료일의 시간을 + 10분 함
                    if( new Date(vue.event.event_data.schedule_date.start).getTime() == select_date.getTime() ) {
                        select_date.setMinutes(select_date.getMinutes() + 10);
                    
                    // 종료일이 더 옛날 날짜라면 => 시작일은 종료일로 변경 시작일 시간의 + 1시간
                    } else if( new Date(vue.event.event_data.schedule_date.start).getTime() > select_date.getTime() ){
                        const new_start : Date = new Date(select_date.getTime());
                        new_start.setHours(new Date(vue.event.event_data.schedule_date.start).getHours());
                        new_start.setMinutes(new Date(vue.event.event_data.schedule_date.start).getMinutes());
                        vue.event.event_data.schedule_date.start = new_start;

                        select_date.setHours(new Date(vue.event.event_data.schedule_date.start).getHours() + 1);
                        select_date.setMinutes(new Date(vue.event.event_data.schedule_date.start).getMinutes());
                    }

                    // date 적용
                    vue.event.event_data.schedule_date.end = select_date;

                    // 반복이 없다면 recurrence_end를 변경 시켜줌
                    if( vue.event.event_data.schedule_date.rrule == null || vue.event.event_data.schedule_date.rrule == "" ){
                        vue.event.event_data.schedule_date.recurrence_end = select_date;
                    }

                    // date-term 변경
                    vue.date_term = vue.getDateDiff(vue.event.event_data.schedule_date.start, vue.event.event_data.schedule_date.end);

                    // date_term이 0보다 클때 연속일정 아니라면 일반 일정
                    vue.event.event_data.schedule_date.isContinuos = ( vue.date_term > 0 );
                    
                }
                
                // 음력인 경우
                if( vue.event.event_data.schedule_date.lunar_yn == true ) {
                    vue.event.event_data.schedule_date.lunar_start = await vue.hodu_solar_to_lunar(vue.event.event_data.schedule_date.start);
                    vue.event.event_data.schedule_date.lunar_end   = vue.event.event_data.schedule_date.lunar_start;
                }
                
                // rrule이 존재한다면 Rrule을 다시 만든다
                if( vue.event.event_data.schedule_date.rrule != null && vue.event.event_data.schedule_date.rrule.length > 0 ) {
                    vue.makeRrule();
                }

                vue.makeDateString();
            },
        };
        
        // 일정 조회가 아닐때만
        if( this.event_crud_type != CRUD_TYPE.READ ) {
            // @ts-ignore
            $('#s_eventFrYmd, #s_eventToYmd').datepicker(option);
        }

        let recurrence_option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                const select_date : Date = new Date(dateText);
                
                const start_for_compare : Date = new Date(vue.event.event_data.schedule_date.start);
                start_for_compare.setHours(0);
                start_for_compare.setMinutes(0);
                start_for_compare.setSeconds(0);
                start_for_compare.setMilliseconds(0);

                if( select_date.getTime() < start_for_compare.getTime() ) {
                    vue.hodu_show_dialog('alert', '반복 종료일이 시작일 보다 빠를 수 없습니다', ['확인']);
                    inst.input.val(`${moment(new Date(vue.event.event_data.schedule_date.recurrence_end)).format('YYYY.MM.DD')} ${vue.getDayOfWeek(new Date(vue.event.event_data.schedule_date.recurrence_end))}요일`);
                    return;
                }

                inst.input.val(`${dateText} ${vue.getDayOfWeek(select_date)}요일`);

                vue.recurrence_end = new Date(select_date);
                vue.recurrence_end.setHours(new Date(vue.event.event_data.schedule_date.end).getHours());
                vue.recurrence_end.setMinutes(new Date(vue.event.event_data.schedule_date.end).getMinutes());
                
                // setTimeout(() => { vue.makeRrule() }, 1);
                vue.makeDateString();
            }
        }

        // @ts-ignore
        $('#dayRecurrenceEnd, #weekRecurrenceEnd, #monthRecurrenceEnd, #yearRecurrenceEnd, #lunarRecurrenceEnd').datepicker(recurrence_option);
    }

    /**
     * 날짜 텍스트 만들기
     */
    async makeDateString() : Promise<void> {
        const start : Date = this.event.event_data.schedule_date.start instanceof Date 
                             ? this.event.event_data.schedule_date.start 
                             : new Date(this.event.event_data.schedule_date.start);

        this.start_date  = `${start.getFullYear()}.${`0${start.getMonth() + 1}`.slice(-2)}.${`0${start.getDate()}`.slice(-2)} ${this.getDayOfWeek(start)}`;
        this.start_hour  = `0${start.getHours() <= 12 ? ( start.getHours() == 0 ? 12 : start.getHours() ) : start.getHours() - 12}`.slice(-2);
        this.start_min   = `0${start.getMinutes()}`.slice(-2);
        this.start_am_pm = start.getHours() < 12 ? "AM" : "PM";
        const string_start_am_pm : string = start.getHours() < 12 ? "오전" : "오후";
        this.start_time  = `${string_start_am_pm} ${this.start_hour}:${this.start_min}`;

        const end : Date = this.event.event_data.schedule_date.end instanceof Date 
                           ? this.event.event_data.schedule_date.end 
                           : new Date(this.event.event_data.schedule_date.end);

        this.end_date  = `${end.getFullYear()}.${`0${end.getMonth() + 1}`.slice(-2)}.${`0${end.getDate()}`.slice(-2)} ${this.getDayOfWeek(end)}`;
        this.end_hour  = `0${end.getHours() <= 12 ? ( end.getHours() == 0 ? 12 : end.getHours() ) : end.getHours() - 12}`.slice(-2);
        this.end_min   = `0${end.getMinutes()}`.slice(-2);
        this.end_am_pm = end.getHours() < 12 ? "AM" : "PM";
        const string_end_am_pm : string = end.getHours() < 12 ? "오전" : "오후";
        this.end_time  = `${string_end_am_pm} ${this.end_hour}:${this.end_min}`;

        const weekOfMonthNumber : number = this.getWeekOfMonthNumber(new Date(this.event.event_data.schedule_date.start));

        // 음력 날짜 적용
        this.lunar_date_text = (await this.hodu_solar_to_lunar(this.event.event_data.schedule_date.start)).replace(/-/ig, '.');
        
        // 반복 날짜 적용
        this.recurrence_start_text              = `${moment(this.event.event_data.schedule_date.start).format('YYYY.MM.DD')} ${this.getDayOfWeek(new Date(this.event.event_data.schedule_date.start))}요일`;
        this.recurrence_start_for_month_text    = `매 월 ${new Date(this.event.event_data.schedule_date.start).getDate()}일`;
        this.recurrence_start_for_month_th_text = `매 월 ${weekOfMonthNumber}번째 ${this.getDayOfWeek(new Date(this.event.event_data.schedule_date.start))}요일`;
        this.recurrence_start_for_year_text     = `매 ${new Date(this.event.event_data.schedule_date.start).getMonth() + 1}월 ${new Date(this.event.event_data.schedule_date.start).getDate()}일`;
        this.recurrence_start_for_year_th_text  = `매 ${new Date(this.event.event_data.schedule_date.start).getMonth() + 1}월 ${weekOfMonthNumber}번째 ${this.getDayOfWeek(new Date(this.event.event_data.schedule_date.start))}요일`; 
        this.recurrence_end_text                = `${moment(this.event.event_data.schedule_date.recurrence_end).format('YYYY.MM.DD')} ${this.getDayOfWeek(new Date(this.event.event_data.schedule_date.recurrence_end))}요일`;
    }

    /**
     * 한 글자 요일 텍스트 가져오기 
     */
    getDayOfWeek(date : Date) : string {

        if( date == null ){
            return "";
        }

        switch( date.getDay() ){
            case 0:
                return "일";

            case 1:
                return "월";

            case 2:
                return "화";

            case 3:
                return "수";

            case 4:
                return "목";

            case 5:
                return "금";

            case 6:
                return "토";

            default:
                return "?";
        }
    }

    /**
     * 해당 월의 몇 번째 요일인지 구하기
     */
    getWeekOfMonthNumber(target_date : Date) : number {
        const first_date : Date = new Date(target_date.getTime());
        first_date.setDate(1);

        const first_day_of_week : number = first_date.getDay();
        let first_target_week_date : number = 0; // 처음 같은 요일이 나오는 일 

        // 해당 월의 첫번째 같은 요일을 구한다
        if( first_day_of_week > target_date.getDay() ) {
            first_target_week_date = 7 - ( first_day_of_week - target_date.getDay() );
        } else {
            first_target_week_date = 1 + ( target_date.getDay() - first_day_of_week );
        }

        // 해당 월의 몇 번째 주인지 구한다
        let week_of_month_number = 0;
        let target_date_number = target_date.getDate();
        for( let i = first_target_week_date; i <= target_date_number; i += 7) {
            week_of_month_number++;
        }

        return week_of_month_number;
    }

    /**
     * 그룹 정보 가공
     */
    async getGroupInformation() : Promise<void> {
        
        // 임시저장이 아니고 개인 캘린더일때만 개인일정을 넣는다 && 임시보관함일때 일반 일정이라면 개인 일정을 넣는다
        if( (this.event.is_temp != true && this.scope == OWNER_TYPE.PERSONAL) || (this.event.is_temp == true && this.isSchedule(this.event.event_sub_type as EVENT_SUB_TYPE) == true) ) {
            this.group_team_list.push({
                "target" : OWNER_TYPE.PERSONAL,
                "target_id" : this.user_id,
                "target_name" : "개인일정",
                "target_color" : "transparent",
                "max_image_count" : 4
            });
        }

        // 로컬 데이터 받아오기
        const local_data : string | null = hodu_local_storage.getItem(`${this.user_id}`);
        const temp_local_data : LocalStorageInfo = JSON.parse(local_data ?? '{}');
        if( this.event_crud_type == CRUD_TYPE.CREATE ) {
            this.event.event_data.color = temp_local_data.last_event_created_color != null && temp_local_data.last_event_created_color.length > 0 ? temp_local_data.last_event_created_color : "#477FFF";
        }

        // group_info가 없으면 수행 할 필요 없음
        if( this.group_info_list == null ){
            return;
        }
        
        // 가공 시작
        const group_info_list_length : number = this.group_info_list.length;
        const user_group_role_length : number = this.user_group_role.length;
        for( let i = 0; i < group_info_list_length; i++ ) {

            const group_info = this.group_info_list[i];

            // 인증 안된 상태라면 호두홈, 호두키즈 일정 생성 불가
            if( (this.auth_info == null || this.auth_info.auth_check == false) && (group_info.biz_type == 'BIZH' || group_info.group_info.project_type == 'kids') ) {
                continue;
            }
            
            // group_role 가져와서 그룹 권한 체크 후 데이터 쌓기
            for( let k = 0; k < user_group_role_length; k++) {
                if( group_info.group_id != this.user_group_role[k].group_id ) continue;
                    
                // 일정인 경우 일정 권한체크
                if( this.isSchedule(this.event.event_sub_type as EVENT_SUB_TYPE) == true && this.user_group_role[k].group_permmision.event.create == false ){
                    break;
                }

                // 업무일지인 경우 업무일지 권한체크
                if( this.isReport(this.event.event_sub_type as EVENT_SUB_TYPE) == true && this.user_group_role[k].group_permmision.report.create == false ){
                    break;
                }

                // 회의록인 경우 회의록 권한체크
                if( this.isMeetingLog(this.event.event_sub_type as EVENT_SUB_TYPE) == true && this.user_group_role[k].group_permmision.meetinglog.create == false ){
                    break;
                }

                // 프로젝트인 경우 프로젝트 권한체크
                if( this.isWork(this.event.event_sub_type as EVENT_SUB_TYPE) == true && this.user_group_role[k].group_permmision.work.create == false ){
                    break;
                }

                // 임시저장된 일정은 제외
                if( this.event.is_temp != true ) {
                    // 그룹&팀 달력인데 BIZ_ID가 같지않다면 제외시킨다
                    if( this.scope != OWNER_TYPE.PERSONAL && group_info.biz_id != this.scope_group_team_option.biz_id ) {
                        break;
                    }
                }

                // 그룹 가공 정보 추가
                this.group_team_list.push({
                    "target" : "GROUP",
                    "target_id" : group_info.group_id,
                    "target_name" : group_info.group_name,
                    "target_color" : group_info.color,
                    "max_image_count" : group_info.limit_event_image_count,
                    "max_file_count" : group_info.limit_event_attachment
                });
            }

            if( group_info.teams == null || group_info.teams.length < 1 ){
                continue;
            }
            
            const teams_length : number = group_info.teams.length
            const user_team_role_length : number = this.user_team_role.length;
            for( let j = 0; j < teams_length; j++ ) {

                const team_info = group_info.teams[j];

                for( let k = 0; k < user_team_role_length; k++) {

                    const team_role = this.user_team_role[k];

                    if( team_info.team_id != team_role.team_id ) continue;
                        
                    // 일정인 경우 일정 권한체크
                    if( this.isSchedule(this.event.event_sub_type as EVENT_SUB_TYPE) == true && team_role.team_permmision.event.create == false ){
                        break;
                    }

                    // 업무일지인 경우 업무일지 권한체크
                    if( this.isReport(this.event.event_sub_type as EVENT_SUB_TYPE) == true && team_role.team_permmision.report.create == false ){
                        break;
                    }

                    // 회의록인 경우 회의록 권한체크
                    if( this.isMeetingLog(this.event.event_sub_type as EVENT_SUB_TYPE) == true && team_role.team_permmision.meetinglog.create == false ){
                        break;
                    }

                    // 프로젝트인 경우 프로젝트 권한체크
                    if( this.isWork(this.event.event_sub_type as EVENT_SUB_TYPE) == true && team_role.team_permmision.work.create == false ){
                        break;
                    }

                    // 임시저장된 일정은 제외
                    if( this.event.is_temp != true ) {
                        // 그룹&팀 달력인데 BIZ_ID가 같지않다면 제외시킨다
                        if( this.scope != OWNER_TYPE.PERSONAL && team_info.biz_id != this.scope_group_team_option.biz_id ) {
                            break;
                        }
                    }

                    // 팀 가공 정보 추가
                    this.group_team_list.push({
                        "target" : "TEAM",
                        "target_id" : team_info.team_id,
                        "target_name" : team_info.team_name,
                        "target_color" : team_info.color,
                        "max_image_count" : group_info.limit_event_image_count,
                        "max_file_count" : group_info.limit_event_attachment
                    });
                }
            }
        }

        // 그룹 정보
        let target_group_team : any = null;
        const group_team_list_count : number = this.group_team_list.length;

        // 개인 일정인 경우
        if( this.event.event_type == OWNER_TYPE.PERSONAL ) {
            this.image_max_cnt = 4;
            this.file_permission = true;
            this.group_color = 'transparent';
            target_group_team = {};
        }

        // 그룹, 팀 일정인 경우
        else {

            for( let i = 0; i < group_team_list_count; i++ ){
                switch( this.event.event_type ){

                    case OWNER_TYPE.GROUP:
                        if( this.group_team_list[i].target != "GROUP" || this.event.group_id != this.group_team_list[i].target_id ){
                            continue;
                        }

                        this.image_max_cnt = this.group_team_list[i].max_image_count;
                        this.file_permission = this.group_team_list[i].max_file_count > 0;
                        if( this.share_info == null || this.share_info.share_scope != OWNER_TYPE.PERSONAL || (this.is_shared == false && this.share_info.share_scope == OWNER_TYPE.PERSONAL) ) {
                            this.group_color = this.group_team_list[i].target_color;
                        }
                        target_group_team = this.group_team_list[i];
                        break;

                    case OWNER_TYPE.TEAM:
                        if( this.group_team_list[i].target != "TEAM" || this.event.team_id != this.group_team_list[i].target_id ){
                            continue;
                        }

                        this.image_max_cnt = this.group_team_list[i].max_image_count;
                        this.file_permission = this.group_team_list[i].max_file_count > 0;
                        if( this.share_info == null || this.share_info.share_scope != OWNER_TYPE.PERSONAL || (this.is_shared == false && this.share_info.share_scope == OWNER_TYPE.PERSONAL) ) {
                            this.group_color = this.group_team_list[i].target_color;
                        }
                        target_group_team = this.group_team_list[i];
                        break;
                }
            }
        } 

        // 없는 경우 공유된 일정이므로 group_role, team_role 에서 찾는다
        if( target_group_team == null ) {
            switch( this.event.event_type ) {
                case OWNER_TYPE.GROUP:
                    for( const group of this.group_info_list ) {
                        if( group.group_id != this.event.group_id ) continue;
                        this.image_max_cnt = group.limit_event_image_count;
                        this.file_permission = group.limit_event_attachment > 0;
                        if( this.share_info == null || this.share_info.share_scope != OWNER_TYPE.PERSONAL || (this.is_shared == false && this.share_info.share_scope == OWNER_TYPE.PERSONAL) ) {
                            this.group_color = group.color;
                        }
                        target_group_team = group;
                        break;
                    } 
                    break;

                case OWNER_TYPE.TEAM:
                    for( const group of this.group_info_list ) {
                        if( group.teams == null ) continue;

                        for( const team of group.teams ) {
                            if( team.team_id != this.event.team_id ) continue;
                            this.image_max_cnt = team.limit_event_image_count;
                            this.file_permission = team.limit_event_attachment > 0;
                            if( this.share_info == null || this.share_info.share_scope != OWNER_TYPE.PERSONAL || (this.is_shared == false && this.share_info.share_scope == OWNER_TYPE.PERSONAL) ) {
                                this.group_color = team.color;
                            }
                            target_group_team = team;
                            break;
                        }

                        if( target_group_team != null ) break;
                        
                        break;
                    }
                    break;
            }
        }

        // 개인달력에서 일정 생성이 아니라면 나간다, EventShortCreate에서 들어온 경우에도 바뀌지 않고 나간다
        if( this.scope != OWNER_TYPE.PERSONAL || this.event.event_sub_type != EVENT_SUB_TYPE.SCHEDULE || this.event_crud_type != CRUD_TYPE.CREATE || this.is_moved_by_short_create == true ) {
            return;
        }

        local_storage_info.last_event_created_scope = temp_local_data.last_event_created_scope;

        // 이전에 선택한 그룹&팀이 없다면 나간다
        if( local_storage_info == null || local_storage_info.last_event_created_scope == null || local_storage_info.last_event_created_scope.scope == null || 
            local_storage_info.last_event_created_scope.scope.length < 1 || local_storage_info.last_event_created_scope.scope_id == null || local_storage_info.last_event_created_scope.scope_id < 1 ) {
            return;
        }

        // 마지막에 선택한 그룹&팀 정보 찾기
        for( let i = 0; i < group_team_list_count; i++ ){
            switch( local_storage_info.last_event_created_scope.scope ) {
                case "GROUP":
                    if( this.group_team_list[i].target != "GROUP" || local_storage_info.last_event_created_scope.scope_id != this.group_team_list[i].target_id ){
                        continue;
                    }

                    this.event.event_type = local_storage_info.last_event_created_scope.scope;
                    this.event.calendar_id = `group-${this.group_team_list[i].target_id}`;
                    this.event.user_id = 0;
                    this.event.group_id = local_storage_info.last_event_created_scope.scope_id;
                    this.event.event_data.event_owner_group_id = local_storage_info.last_event_created_scope.scope_id;
                    this.event.team_id = 0;
                    this.event.event_data.event_owner_team_id = 0;
                    this.event.event_data.event_owner_group_name = this.group_team_list[i].target_name;

                    this.image_max_cnt = this.group_team_list[i].max_image_count;
                    this.file_permission = this.group_team_list[i].max_file_count > 0;
                    if( this.share_info == null || this.share_info.share_scope != OWNER_TYPE.PERSONAL || (this.is_shared == false && this.share_info.share_scope == OWNER_TYPE.PERSONAL) ) {
                        this.group_color = this.group_team_list[i].target_color;
                    }
                    break;

                case "TEAM":
                    if( this.group_team_list[i].target != "TEAM" || local_storage_info.last_event_created_scope.scope_id != this.group_team_list[i].target_id ){
                        continue;
                    }

                    this.event.event_type = local_storage_info.last_event_created_scope.scope;
                    this.event.calendar_id = `team-${this.group_team_list[i].target_id}`;
                    this.event.user_id = 0;
                    this.event.group_id = 0;
                    this.event.event_data.event_owner_group_id = 0;
                    this.event.team_id = local_storage_info.last_event_created_scope.scope_id;
                    this.event.event_data.event_owner_team_id = local_storage_info.last_event_created_scope.scope_id;
                    this.event.event_data.event_owner_team_name = this.group_team_list[i].target_name;

                    this.image_max_cnt = this.group_team_list[i].max_image_count;
                    this.file_permission = this.group_team_list[i].max_file_count > 0;
                    if( this.share_info == null || this.share_info.share_scope != OWNER_TYPE.PERSONAL || (this.is_shared == false && this.share_info.share_scope == OWNER_TYPE.PERSONAL) ) {
                        this.group_color = this.group_team_list[i].target_color;
                    }
                    break;

                default:
                    this.image_max_cnt = 4;
                    this.file_permission = true;
                    this.group_color = 'transparent';
                    break;
            }
        }
    }

    /**
     * 그룹 선택창 열기
     */
    groupSelectListOpen() : void {
        if( this.event_crud_type == CRUD_TYPE.READ ) {
            return;
        }

        this.group_select_open = !this.group_select_open;
    }

    /**
     * 그룹, 팀 선택
     */
    async group_team_select(group_team : any) : Promise<void> {
        this.event.event_type = group_team.target;
        this.group_color      = group_team.target_color;
        
        switch(group_team.target) {
            case OWNER_TYPE.PERSONAL:
                this.event.calendar_id                       = `personal-${this.user_id}`;
                this.event.user_id                           = this.user_id;
                this.event.group_id                          = 0;
                this.event.team_id                           = 0;
                this.event.event_data.event_owner_group_id   = 0;
                this.event.event_data.event_owner_group_name = "";
                this.event.event_data.event_owner_team_id    = 0;
                this.event.event_data.event_owner_team_name  = "";

                // if( this.event.event_data.attachment != null && this.event.event_data.attachment.files != null ) {
                //     this.event.event_data.attachment.files.splice(0, this.event.event_data.attachment.files.length);
                // }

                this.event.event_data.attend = false;
                this.event.event_data.vote.splice(0, this.event.event_data.vote.length);
                break;

            case OWNER_TYPE.GROUP:
                this.event.calendar_id                       = `group-${group_team.target_id}`;
                this.event.user_id                           = 0;
                this.event.group_id                          = group_team.target_id;
                this.event.team_id                           = 0;
                this.event.event_data.event_owner_group_id   = group_team.target_id;
                this.event.event_data.event_owner_group_name = group_team.target_name;
                this.event.event_data.event_owner_team_id    = 0;
                this.event.event_data.event_owner_team_name  = "";
                break;

            case OWNER_TYPE.TEAM:
                this.event.calendar_id                       = `team-${group_team.target_id}`;
                this.event.user_id                           = 0;
                this.event.group_id                          = 0;
                this.event.team_id                           = group_team.target_id;
                this.event.event_data.event_owner_group_id   = 0;
                this.event.event_data.event_owner_group_name = "";
                this.event.event_data.event_owner_team_id    = group_team.target_id;
                this.event.event_data.event_owner_team_name  = group_team.target_name;
                break;
        }

        // 사진 첨부 최대 이미지 개수 관련 설정 (정해진 이미지 개수 보다 많다면 없앤다)
        this.image_max_cnt = group_team.max_image_count;
        this.file_permission = this.event.group_id == 0 && this.event.team_id == 0 ? true : group_team.max_file_count > 0;
        if( this.event.event_data.attachment != null && this.event.event_data.attachment.imgs != null && this.event.event_data.attachment.imgs.length > this.image_max_cnt ){
            const file_count : number = this.event.event_data.attachment.imgs.length;
            for( let i = (file_count - 1); i >= this.image_max_cnt; i-- ) {
                this.event.event_data.attachment.imgs.splice(i, 1);
            }
        }

        this.group_select_open = false;

        await this.permissionCheck();
    }

    /**
     * 일정 색상 변경 창 열기
     */
    colorSelectListOpen() : void {
        this.color_select_open = !this.color_select_open;
    }

    /**
     * 일정 색상 변경
     */
    changeColor(e : Event, color : string, colorMode : string) : void {
        this.event.event_data.color = color;
        this.colorToneMode          = colorMode;
        this.color_select_open      = false;

        // 조회 일때 일정 개인설정 데이터 변경
        if( this.event_crud_type == CRUD_TYPE.READ ) {
            this.updateEventIndividual();
        }
    }

    /**
     * 시작 시간 변경 창 열기
     */
    timeFromListOpen() : void {
        if( this.event_crud_type == CRUD_TYPE.READ ) {
            return;
        }

        this.time_from_open = !this.time_from_open;
    }

    /**
     * 종료 시간 변경 창 열기
     */
    timeToListOpen() : void {
        if( this.event_crud_type == CRUD_TYPE.READ ) {
            return;
        }

        this.time_to_open = !this.time_to_open;
    }

    /**
     * 시작일 시간 변경
     */
    selectStartTimeHour(event) : void {
        const startHours : number = this.start_am_pm == "AM" && Number(event.target.value) == 12 
                                    ? 0 
                                    : (this.start_am_pm == "AM" && Number(event.target.value) != 12) || (this.start_am_pm == "PM" && Number(event.target.value) == 12)
                                    ? Number(event.target.value)
                                    : Number(event.target.value) + 12;

        const start : Date = new Date(this.event.event_data.schedule_date.start);
        start.setHours(startHours)
        const end   : Date = new Date(this.event.event_data.schedule_date.end); 

        // 23시 일때
        if( start.getHours() == 23 ){
            
            // 50분인 경우
            if( start.getMinutes() == 50 ){
               end.setTime(start.getTime());
               end.setMinutes(start.getMinutes() + 10);

            // 0~40분 인 경우
            } else {
               end.setTime(start.getTime());
               end.setMinutes(50);
            }
           
        // 23시가 아니라면 + 1시간
        } else {
            end.setTime(start.getTime());
            end.setHours(start.getHours() + 1);
            end.setMinutes(start.getMinutes());
        }

        // 날짜 세팅
        this.event.event_data.schedule_date.start = start;
        if( this.date_term < 1 ) { this.event.event_data.schedule_date.end = end; }

        this.makeDateString();
    }

    /**
     * 시작일 분 변경
     */
    selectStartTimeMin(event) : void {
        const start : Date = new Date(this.event.event_data.schedule_date.start);
        start.setMinutes(Number(event.target.value))
        const end   : Date = new Date(this.event.event_data.schedule_date.end); 

        // 23시 일때
        if( start.getHours() == 23 ){
            
            // 50분인 경우
            if( start.getMinutes() == 50 ){
               end.setTime(start.getTime());
               end.setMinutes(start.getMinutes() + 10);

            // 0~40분 인 경우
            } else {
               end.setTime(start.getTime());
               end.setMinutes(50);
            }
           
        // 23시가 아니라면 + 1시간
        } else {
            end.setTime(start.getTime());
            end.setHours(start.getHours() + 1);
            end.setMinutes(start.getMinutes());
        }

        // 날짜 세팅
        this.event.event_data.schedule_date.start = start;
        if( this.date_term < 1 ) { this.event.event_data.schedule_date.end = end; }

        this.makeDateString();
    }

    /**
     * 시작일 오전 오후 변경
     */
    selectStartTimeAmPm(event) : void {
        
        // 이미 같은 값이라면 return
        if( this.start_am_pm == event.target.value ){
            return;
        }

        const start : Date = new Date(this.event.event_data.schedule_date.start);
        const end   : Date = new Date(this.event.event_data.schedule_date.end); 

        switch( event.target.value ){
            case "AM":
                start.setHours(start.getHours() - 12);
                break;
            
            case "PM":
                start.setHours(start.getHours() + 12);
                break;
        }
        
        // 23시 일때
        if( start.getHours() == 23 ){
            
            // 50분인 경우
            if( start.getMinutes() == 50 ){
               end.setTime(start.getTime());
               end.setMinutes(start.getMinutes() + 10);

            // 0~40분 인 경우
            } else {
               end.setTime(start.getTime());
               end.setMinutes(50);
            }
           
        // 23시가 아니라면 + 1시간
        } else {
            end.setTime(start.getTime());
            end.setHours(start.getHours() + 1);
            end.setMinutes(start.getMinutes());
        }

        // 날짜 세팅
        this.event.event_data.schedule_date.start = start;
        if( this.date_term < 1 ) { this.event.event_data.schedule_date.end = end; }

        this.makeDateString();
    }

    /**
     * 종료일 시간 변경
     */
    selectEndTimeHour(event) : void {
        const endHours : number = this.end_am_pm == "AM" && Number(event.target.value) == 12 
                                  ? 0 
                                  : (this.end_am_pm == "AM" && Number(event.target.value) != 12) || (this.end_am_pm == "PM" && Number(event.target.value) == 12)
                                    ? Number(event.target.value)
                                    : Number(event.target.value) + 12;

        const start : Date = new Date(this.event.event_data.schedule_date.start);
        const end   : Date = new Date(this.event.event_data.schedule_date.end); 
        end.setHours(endHours);
        
        // 완벽히 같은 시간은 10분을 더해준다
        if( start.getTime() == end.getTime() ) {
            end.setMinutes(end.getMinutes() + 10);

        // 종료일이 시작일보다 작다면 alert
        } else if ( start.getTime() > end.getTime() ) {
            $(event.target).val(this.end_hour);
            alert("일정 종료시간을 확인하세요");
            return;
        }

        // 날짜 세팅
        this.event.event_data.schedule_date.start = start;
        this.event.event_data.schedule_date.end   = end;

        this.makeDateString();
    }

    /**
     * 종료일 분 변경
     */
    selectEndTimeMin(event) : void {
        const start : Date = new Date(this.event.event_data.schedule_date.start);
        const end   : Date = new Date(this.event.event_data.schedule_date.end); 
        end.setMinutes(Number(event.target.value));

        // 완벽히 같은 시간은 10분을 더해준다
        if( start.getTime() == end.getTime() ) {
            end.setMinutes(end.getMinutes() + 10);

        // 종료일이 시작일보다 작다면 alert
        } else if ( start.getTime() > end.getTime() ) {
            $(event.target).val(this.end_min);
            alert("일정 종료시간을 확인하세요");
            return;
        }

        // 날짜 세팅
        this.event.event_data.schedule_date.start = start;
        this.event.event_data.schedule_date.end   = end;

        this.makeDateString();
    }

    /**
     * 종료일 오전 오후 변경
     */
    selectEndTimeAmPm(event) : void {

        // 이미 같은 값이라면 return
        if( this.end_am_pm == event.target.value ){
            return;
        }

        const start : Date = new Date(this.event.event_data.schedule_date.start);
        const end   : Date = new Date(this.event.event_data.schedule_date.end); 

        switch( event.target.value ){
            case "AM":
                end.setHours(end.getHours() - 12);
                break;
            
            case "PM":
                end.setHours(end.getHours() + 12);
                break;
        }

        // 완벽히 같은 시간은 10분을 더해준다
        if( start.getTime() == end.getTime() ) {
            end.setMinutes(end.getMinutes() + 10);

        // 종료일이 시작일보다 작다면 alert
        } else if ( start.getTime() > end.getTime() ) {
            $(event.target).val(this.end_am_pm);
            alert("일정 종료시간을 확인하세요");
            return;
        }

        // 날짜 세팅
        this.event.event_data.schedule_date.start = start;
        this.event.event_data.schedule_date.end   = end;

        this.makeDateString();
    }

    /**
     * 날짜 영역에서 '오늘' 눌렀을때 
     */
    goToday() : void {
        
        const target_date : Date = new Date();
        let start_date : Date = new Date(target_date.getTime());
        let end_date   : Date = new Date(target_date.getTime());

        // 시작 시간이 '정시' 거나 '23시' 일 경우는 그대로 사용한다 
        if( target_date.getMinutes() != 0 && target_date.getHours() != 23 ){
           start_date.setHours(target_date.getHours() + 1);
        }
        
        start_date.setMinutes(0);
        start_date.setSeconds(0);
        start_date.setMilliseconds(0);

        // 시작시간이 23시라면 23시 50분 고정, 아니라면 시작시간 + 1시간에 0분
        if( start_date.getHours() == 23 ){
            end_date.setHours(23);
            end_date.setMinutes(50);
        } else {
            end_date.setHours(start_date.getHours() + 1);
            end_date.setMinutes(0);
        }

        end_date.setSeconds(0);
        end_date.setMilliseconds(0);

        this.event.event_data.schedule_date.start = start_date;
        this.event.event_data.schedule_date.end   = end_date;

        this.makeDateString();
    }

    @Watch('event.event_data.schedule_date.lunar_yn')
    async changeRepeatInfinite() : Promise<void> {
        this.event.event_data.schedule_date.rrule = undefined;
        this.recurrence_html = "";

        // lunar_start, lunar_end 관리
        if( this.event.event_data.schedule_date.lunar_yn == true ) {
            const lunar_date : string = await this.hodu_solar_to_lunar(this.event.event_data.schedule_date.start);
            
            // const lunar_start : Date = new Date(lunar_date.getTime());
            // const lunar_end   : Date = new Date(lunar_date.getTime());

            // lunar_start.setHours(0);
            // lunar_start.setMinutes(0);
            // lunar_start.setSeconds(0);
            // lunar_start.setMilliseconds(0);

            // lunar_end.setHours(23);
            // lunar_end.setMinutes(59);
            // lunar_end.setSeconds(59);
            // lunar_end.setMilliseconds(0);

            this.event.event_data.schedule_date.lunar_start = lunar_date;
            this.event.event_data.schedule_date.lunar_end   = lunar_date;

            this.day_repeat = false;
            this.week_repeat = false;
            this.month_repeat = false;
            this.year_repeat = true;

        } else {
            this.event.event_data.schedule_date.lunar_start = undefined;
            this.event.event_data.schedule_date.lunar_end   = undefined;
            
            this.day_repeat = true;
            this.week_repeat = false;
            this.month_repeat = false;
            this.year_repeat = false;
        }
    }
    
    /**
     * 반복 설정 창 열기
     */
    repeatOpen() : void {
        this.repeat_open     = true;
        this.alarm_list_open = false;

        // 기본값 1로
        if( !this.repeat_value || this.repeat_value == null ) {
            this.repeat_value = 1;
        }
    }

    /**
     * 반복 설정 창 닫기
     */
    async repeatClose() : Promise<void> {
        this.repeat_open = false;
        
        if( this.event.event_data.schedule_date.rrule == null ) {
            this.recurrence_end = this.event.event_data.schedule_date.recurrence_end;
            this.repeat_infinite = true;
            this.repeat_value = null;
            this.day_repeat = true;
            this.week_repeat = false;
            this.month_repeat = false;
            this.year_repeat = false;
            return;
        }

        // 원래대로 돌려놓음
        await this.makeStringByRrule();
        
        if( this.repeat_infinite == false ) this.recurrence_end = this.event.event_data.schedule_date.recurrence_end;
    }

    /**
     * 일 반복 열기
     */
    repeatDayOpen() : void {
        if( this.day_repeat == true ) {
            return;
        }

        this.day_repeat   = true;
        this.week_repeat  = false;
        this.month_repeat = false;
        this.year_repeat  = false;

        this.repeat_value = 1;
        this.month_year_left_option = true;
    }   

    /**
     * 주 반복 열기
     */
    repeatWeekOpen() : void {
        if( this.week_repeat == true ) {
            return;
        }

        this.day_repeat   = false;
        this.week_repeat  = true;
        this.month_repeat = false;
        this.year_repeat  = false;

        this.repeat_value = 1;
        this.month_year_left_option = true;
    }

    /**
     * 월 반복 열기
     */
    repeatMonthOpen() : void {
        if( this.month_repeat == true ) {
            return;
        }
        
        this.day_repeat   = false;
        this.week_repeat  = false;
        this.month_repeat = true;
        this.year_repeat  = false;

        this.repeat_value = 1;
        this.month_year_left_option = true;
    }

    /**
     * 년 반복 열기
     */
    repeatYearOpen() : void {
        if( this.year_repeat == true ) {
            return;
        }

        this.day_repeat   = false;
        this.week_repeat  = false;
        this.month_repeat = false;
        this.year_repeat  = true;

        this.repeat_value = 1;
        this.month_year_left_option = true;
    }
    
    /**
     * 주반복 요일 클릭
     */
    dayOfWeekClick( day_of_week_index : number ) : void {
        if( this.selected_day_of_week.indexOf(day_of_week_index) == -1 ) {
            this.selected_day_of_week.push(day_of_week_index);
        } else {
            this.selected_day_of_week.splice(this.selected_day_of_week.indexOf(day_of_week_index), 1);
        }

        this.selected_day_of_week.sort((o1, o2) : number => {

            const o1_sort_value = (o1 + 1) % 7;
            const o2_sort_value = (o2 + 1) % 7;

            if( o1_sort_value > o2_sort_value ) return  1;
            if( o1_sort_value < o2_sort_value ) return -1;

            return 0;
        });
    }

    /**
     * 월, 년 반복 모드 바꾸기
     */
    changeRepeatModeForMonthAndYear(mode : boolean) : void {
        this.month_year_left_option = mode;
    }

    /**
     * 반복 저장
     */
    async saveRepeat() : Promise<void> {
        // 음력이 아니라면 input값이 있어야 한다 || 주반복일때 선택된 요일이 없다면 rrule 없음
        if( ( this.event.event_data.schedule_date.lunar_yn == false && ( this.repeat_value == null || !this.repeat_value || this.repeat_value == 0 ) ) ||
              this.week_repeat == true && this.selected_day_of_week.length < 1 ) {
            return;
        }

        if( this.repeat_infinite == true ) {
            this.event.event_data.schedule_date.recurrence_end = new Date('2050-12-31T23:59:00');
        }
        else {
            this.event.event_data.schedule_date.recurrence_end = this.recurrence_end;
        }

        await this.makeDateString();
        await this.makeRrule();

        this.repeat_open = false;
    }

    /**
     * RRULE 제조
     */
    async makeRrule() : Promise<void> {

        // 매일 반복
        if( this.day_repeat == true ) {
            this.event.event_data.schedule_date.rrule = `FREQ=DAILY;INTERVAL=${this.repeat_value};`;
            this.recurrence_html = `<span class="highlight">${ this.repeat_value == 1 ? "매" : this.repeat_value }일</span> ${this.repeat_value == 1 ? "" : "마다" } 반복`;
        }

        // 매주 반복
        else if( this.week_repeat == true ) {
            let by_day_text : string = "";
            let by_day_hangle_text : string = "";

            // BYDAY 텍스트 제작
            const day_of_week_count : number = this.selected_day_of_week.length;
            for( let i = 0; i < day_of_week_count; i++ ) {
                by_day_text += this.getDayOfWeekForRrule(this.selected_day_of_week[i]);
                by_day_hangle_text += this.getDayOfWeekForRrule(this.selected_day_of_week[i], true);

                if( i != (day_of_week_count - 1) ) {
                    by_day_text += ",";
                    by_day_hangle_text += ",";
                }
            }

            this.event.event_data.schedule_date.rrule = `FREQ=WEEKLY;INTERVAL=${ this.repeat_value };BYDAY=${by_day_text};`;
            this.recurrence_html = `<span class="highlight">${ this.repeat_value == 1 ? "매" : this.repeat_value }주</span> ${this.repeat_value == 1 ? "" : "마다" } <span class="highlight">${by_day_hangle_text}요일</span>에 반복`;
        }

        // 매월 반복
        else if( this.month_repeat == true ) {
            let by_day_or_by_month_text : string = "";
            let recurrence_at_html : string = ""; // ~~에 해당하는 반복 텍스트 

            // 매 월 n일
            if( this.month_year_left_option == true ) {
                by_day_or_by_month_text = `BYMONTHDAY=${new Date(this.event.event_data.schedule_date.start).getDate()}`;
                recurrence_at_html = `${new Date(this.event.event_data.schedule_date.start).getDate()}일`;
            }
            
            // 매 월 n번째 m요일
            else {

                // Date.getDay() 는 일요일이 0부터이므로 일요일이 6이 되도록 계산해줘야한다 
                const day = (new Date(this.event.event_data.schedule_date.start).getDay() + 6) % 7; 
                by_day_or_by_month_text = `BYDAY=${this.getWeekOfMonthNumber(new Date(this.event.event_data.schedule_date.start))}${this.getDayOfWeekForRrule(day)}`
                recurrence_at_html = `${this.getWeekOfMonthNumber(new Date(this.event.event_data.schedule_date.start))}번째 ${this.getDayOfWeekForRrule(day, true)}요일`;
            }

            this.event.event_data.schedule_date.rrule = `FREQ=MONTHLY;INTERVAL=${this.repeat_value};${by_day_or_by_month_text};`;
            this.recurrence_html = `<span class="highlight">${ this.repeat_value == 1 ? "매" : `${this.repeat_value}개` }월</span> ${this.repeat_value == 1 ? "" : "마다" } <span class="highlight">${recurrence_at_html}</span>에 반복`;
        } 
        
        // 매년 반복
        else if( this.year_repeat == true ) {
            let by_day_and_by_month_text : string = "";
            let recurrence_at_html : string = `${new Date(this.event.event_data.schedule_date.start).getDate()}일`;

            // 매 n월 m번째 o요일
            if( this.month_year_left_option == false ) {

                // Date.getDay() 는 일요일이 0부터이므로 일요일이 6이 되도록 계산해줘야한다 
                const day = (new Date(this.event.event_data.schedule_date.start).getDay() + 6) % 7;
                by_day_and_by_month_text = `BYDAY=${this.getWeekOfMonthNumber(new Date(this.event.event_data.schedule_date.start))}${this.getDayOfWeekForRrule(day)};BYMONTH=${new Date(this.event.event_data.schedule_date.start).getMonth() + 1};`;
                recurrence_at_html = `${this.getWeekOfMonthNumber(new Date(this.event.event_data.schedule_date.start))}번째 ${this.getDayOfWeekForRrule(day, true)}요일`;
            }

            this.event.event_data.schedule_date.rrule = `FREQ=YEARLY;INTERVAL=${ this.event.event_data.schedule_date.lunar_yn == true ? 1 : this.repeat_value };${by_day_and_by_month_text};`;
            
            // 양력
            if( this.event.event_data.schedule_date.lunar_yn == false ) {
                this.recurrence_html = `<span class="highlight">${ this.repeat_value == 1 ? "매" : `${this.repeat_value}` }년</span> ${this.repeat_value == 1 ? "" : "마다" } <span class="highlight">${new Date(this.event.event_data.schedule_date.start).getMonth() + 1}월 ${recurrence_at_html}</span>에 반복`;
            }

            // 음력
            else {

                // 음력 날짜 제대로 없을시
                if( this.event.event_data.schedule_date.lunar_start == null ) {
                    this.event.event_data.schedule_date.rrule = undefined;
                    this.recurrence_html = "";
                    return;
                }

                // 음력 문자열 생성
                const lunar_arr : string[] = this.event.event_data.schedule_date.lunar_start.substring(0, 10).split('-');
                
                // 1 ~ 9월이라면 01 ~ 09가 아닌 1 ~ 9 로 나오도록 문자열을 뽑아냄
                let month : string = lunar_arr[1];
                month = month.replace(/(0)(.)/, '$2');

                // 1 ~ 9일이라면 01 ~ 09가 아닌 1 ~ 9 로 나오도록 문자열을 뽑아냄
                let date : string = lunar_arr[2];
                date = date.replace(/(0)(.)/, '$2');

                this.recurrence_html = `<span class="highlight">매년</span> 음력 <span class="highlight">${month}월 ${date}일</span>에 반복`;
            }

        // 그 외의 경우 (에러)
        } else {
            this.event.event_data.schedule_date.rrule = undefined;
        }

        // rrule 존재시 마지막 세미콜론 제거
        while ( this.event.event_data.schedule_date.rrule != undefined && 
            ( this.event.event_data.schedule_date.rrule.lastIndexOf(';') == this.event.event_data.schedule_date.rrule.length - 1) ){
            this.event.event_data.schedule_date.rrule = this.event.event_data.schedule_date.rrule.substring(0, this.event.event_data.schedule_date.rrule.length - 1);
        }

        this.recurrence_html += `<span class="endDay">종료일</span> <span class="repeatEndDate"> ${ this.repeat_infinite == true ? "없음" : this.recurrence_end_text } </span>`;
    }

    /**
     * 일정을 조회 할때 Rrule을 기반으로 html 생성
     */
    async makeStringByRrule() : Promise<void> {
        
        // RRULE이 없다면 빠져 나감
        if( this.event.event_data.schedule_date.rrule == null || this.event.event_data.schedule_date.rrule.length < 1 ) {
            return;
        }

        // reccurence_end를 보고 2050년 12월 31일 23시 59분이면 계속 반복 아니라면 일반 반복
        const infinite_date : Date = new Date();
        infinite_date.setFullYear(2050);
        infinite_date.setMonth(11);
        infinite_date.setDate(31);
        infinite_date.setHours(23);
        infinite_date.setMinutes(59);
        infinite_date.setSeconds(0);
        infinite_date.setMilliseconds(0);

        this.repeat_infinite = infinite_date.getTime() <= new Date(this.event.event_data.schedule_date.recurrence_end).getTime();

        // 일 ~ 년 반복 초기화
        this.day_repeat   = false;
        this.week_repeat  = false;
        this.month_repeat = false;
        this.year_repeat  = false;

        // rrule을 RRule.js 객체로 변환
        const rruleSet : RRule | RRuleSet = rrulestr(this.event.event_data.schedule_date.rrule);

        this.repeat_value = rruleSet.options.interval;
        
        // 일 반복
        if( rruleSet.options.freq == RRule.DAILY ) {
            this.day_repeat = true;
        }

        // 주 반복
        else if ( rruleSet.options.freq == RRule.WEEKLY ) {
            this.week_repeat = true;
            this.selected_day_of_week = this.selected_day_of_week.concat(rruleSet.options.byweekday);
        }

        // 월 반복
        else if ( rruleSet.options.freq == RRule.MONTHLY ) {
            this.month_repeat = true;
            this.month_year_left_option = (rruleSet.origOptions.byweekday == null);
        }

        // 년 반복
        else if ( rruleSet.options.freq == RRule.YEARLY ) {
            this.year_repeat = true;
            this.month_year_left_option = (rruleSet.origOptions.byweekday == null);
        }

        await this.makeRrule();
    }

    /**
     * Rrule용 요일 텍스트를 반환한다 (MO, TU, WE, TH, FR, SA, SU)
     */
    getDayOfWeekForRrule(dayNumber : number, for_hangle : boolean = false) : string {
        switch( dayNumber ) { 
            case 6:
                return for_hangle == false ? "SU" : "일";

            case 0:
                return for_hangle == false ? "MO" : "월";
            
            case 1:
                return for_hangle == false ? "TU" : "화";

            case 2:
                return for_hangle == false ? "WE" : "수";
            
            case 3:
                return for_hangle == false ? "TH" : "목";

            case 4:
                return for_hangle == false ? "FR" : "금";

            case 5:
                return for_hangle == false ? "SA" : "토";
        }

        return "";
    }

    /**
     * 반복 제거
     */
    removeRepeat() : void {

        if( this.event_crud_type == CRUD_TYPE.READ || this.event.event_data.schedule_date.rrule == null || this.event.event_data.schedule_date.rrule == '' ) {
            return;
        }

        // 양력
        if( this.event.event_data.schedule_date.lunar_yn == false ) {
            this.day_repeat   = true;
            this.week_repeat  = false;
            this.month_repeat = false;
            this.year_repeat  = false;
        }

        // 음력
        else {
            this.day_repeat   = false;
            this.week_repeat  = false;
            this.month_repeat = false;
            this.year_repeat  = true;
        }

        this.event.event_data.schedule_date.rrule = undefined;
        this.event.event_data.schedule_date.recurrence_end = new Date(this.event.event_data.schedule_date.end);
        this.recurrence_end = this.event.event_data.schedule_date.recurrence_end;
        this.recurrence_html = "";
        this.repeat_value = null;
        this.repeat_infinite = true;

        // 주반복 요일 제거
        this.selected_day_of_week.splice(0, this.selected_day_of_week.length);

        this.makeDateString();
        this.makeStringByRrule();
    }

    /**
     * 알람 설정 창 toggle
     */
    alarmListOpen() : void {
        this.alarm_list_open = !this.alarm_list_open;
    }

    /**
     * 알람 값 체크 - 해당 알람이 존재하면 true, 없다면 false 반환
     */
    alarmCheck(trigger : string, alarm_gb : string) : boolean {
        if( this.event.event_data.alarm == null ) {
            this.event.event_data.alarm = [];
        }
    
        const alarm_count : number = this.event.event_data.alarm.length;
        for( let i = 0; i < alarm_count; i++ ) {
            if( this.event.event_data.alarm[i].alarm_gb == alarm_gb && this.event.event_data.alarm[i].trigger == trigger ) {
                return true;
            }
        }
        return false;
    }

    /**
     * 알람 세팅
     */
    async alarmSetting(trigger : string, alarm_gb : string, description : string) : Promise<void> {
        if( this.event.event_data.alarm == null ) {
            this.event.event_data.alarm = [];
        }

        // 중복 검사 (중복이라면 off) )
        const alarm_count : number = this.event.event_data.alarm.length;
        for( let i = 0; i < alarm_count; i++ ) {
            if( this.event.event_data.alarm[i].alarm_gb == alarm_gb && this.event.event_data.alarm[i].trigger == trigger ) {
                this.event.event_data.alarm.splice(i, 1);
                return;
            }
        }

        // 알람 추가
        this.event.event_data.alarm.push({
            trigger,
            description,
            alarm_gb,
            action : "",
            repeat : 0, 
        });

        await this.alarmSort();

        if( this.event_crud_type == CRUD_TYPE.READ ) {
            await this.updateEventIndividual();
        }
    }

    /**
     * 알람 취소(위쪽)
     */
    async alarmOff(alarm : t_event_alarm) : Promise<void> {
        if( this.event.event_data.alarm == null || this.event.event_data.alarm.length < 1 ){
            return;
        }

        // 알람 제거용 인덱스 구하기
        const alarm_index : number = this.event.event_data.alarm.indexOf(alarm);
        if( alarm_index == -1 ){
            return;
        }

        // 알람 모델 제거용 인덱스 구하기
        const model_index : number = this.alarm_model.indexOf(alarm.trigger);
        if( model_index == -1 ){
            return;
        }

        // 알람 제거
        this.alarm_model.splice(model_index, 1);
        this.event.event_data.alarm.splice(alarm_index, 1);

        if( this.event_crud_type == CRUD_TYPE.READ ) {
            await this.updateEventIndividual();
        }
    }

    /**
     * 알람 sort (정시에 가까울수록 앞에 둔다)
     */
    async alarmSort() : Promise<void> {
        if( this.event.event_data.alarm == null || this.event.event_data.alarm.length < 1 ){
            return;
        }

        // trigger minute 기준으로 변환
        await this.event.event_data.alarm.sort((a : t_event_alarm, b : t_event_alarm) : number => {
            
            // 알람 A 데이터 가공
            const a_alarm      : string[] = a.trigger.split(' ');

            if( a_alarm.length < 2 ) {
                return 0;
            }

            let   a_timeMinute : number   = Number(a_alarm[0]);

            switch(a_alarm[1]){
                case 'hour':
                    a_timeMinute *= 60; // hour to minute
                    break;

                case 'day':
                    a_timeMinute *= 60 * 24; // day to minute
                    break;
            }

            // 알람 B 데이터 가공
            const b_alarm      : string[] = b.trigger.split(' ');

            if( b_alarm.length < 2 ) {
                return 0;
            }

            let   b_timeMinute : number   = Number(b_alarm[0]);

            switch(b_alarm[1]){
                case 'hour':
                    b_timeMinute *= 60; // hour to minute
                    break;

                case 'day':
                    b_timeMinute *= 60 * 24; // day to minute
                    break;
            }

            return a_timeMinute < b_timeMinute ? -1 : 1;
        });

        // trigger minute 기준으로 변환
        await this.alarm_model.sort((a : string, b : string) : number => {
            
            // 알람 A 데이터 가공
            const a_alarm      : string[] = a.split(' ');

            if( a_alarm.length < 2 ) {
                return 0;
            }

            let   a_timeMinute : number   = Number(a_alarm[0]);

            switch(a_alarm[1]){
                case 'hour':
                    a_timeMinute *= 60; // hour to minute
                    break;

                case 'day':
                    a_timeMinute *= 60 * 24; // day to minute
                    break;
            }

            // 알람 B 데이터 가공
            const b_alarm      : string[] = b.split(' ');

            if( b_alarm.length < 2 ) {
                return 0;
            }

            let   b_timeMinute : number   = Number(b_alarm[0]);

            switch(b_alarm[1]){
                case 'hour':
                    b_timeMinute *= 60; // hour to minute
                    break;

                case 'day':
                    b_timeMinute *= 60 * 24; // day to minute
                    break;
            }

            return a_timeMinute < b_timeMinute ? -1 : 1;
        });
    }

    /**
     * 키다운 이벤트 감지 : 엔터키 누르면 검색
     */
    locationKeyDown(event) : void {
        if( event.keyCode != 13 ) {
            return;
        }

        this.locationSearchModalOpen();
    }

    /**
     * 장소 검색 Modal Open
     */
    locationSearchModalOpen() : void {
        this.doSetLocationSearchQuery(this.mapSearchQuery.trim());
        this.doSetShowEventDaumMap(true);
    }

    /**
     * 장소 선택값 반영
     */
    changeSeletedLocation() : void {       
        if( this.event.event_data.location == null ) {
            this.event.event_data.location = [];
        }

        this.event.event_data.location.push(JSON.parse(JSON.stringify(this.selected_location)));
        this.mapSearchQuery = "";
    }

    /**
     * 장소 보기
     */
    viewLocation(location : t_event_location) : void {
        this.doSetSelectedLocation(location);
        this.doSetShowEventDaumMap(true);
    }

    /**
     * 장소 삭제
     */
    deleteLocation(location : t_event_location) : void {
        if( this.event.event_data.location == null ) {
            return;
        }

        const location_index : number = this.event.event_data.location.indexOf(location);

        if( location_index == -1 ){
            return;
        }

        this.event.event_data.location.splice(location_index, 1);
    }

    /**
     * 전화번호 입력
     */
    inputTel(e : any) : void {
        if( this.event.event_data.contacts == null || this.event.event_data.contacts.length < 1 ) {
            this.event.event_data.contacts = [{}];
        }

        this.event.event_data.contacts[0].tel = (e.target.value).replace(/[^0-9-+]+/ig, '');
        this.$forceUpdate()
    }

    /**
     * 일정 조회일때 노트 개인 데이터 저장
     */
    @Debounce(100)
    @Watch('event.event_data.note')
    changeNote(after, before) : void {

        after = after ? after : "";
        before = before ? before : "";

        if( this.event_crud_type != CRUD_TYPE.READ ) return;
        if( after == before ) return;
        
        this.updateEventIndividual();
    }

    /**
     * 일정 이미지 추가
     */
    addImageNormal(event) : void {
        const files : File[] = event.target.files;
        if( files.length == 0 ) { return; }
        this.addImage(files);
    }

    /**
     * 이미지 객체 생성
     */
    async addImage(files : File[]) : Promise<void> {
        const vue = this;

        if( this.event.event_data.attachment == null ){
            this.event.event_data.attachment = {
                imgs : [],
                files : []
            }
        }

        if( this.event.event_data.attachment.imgs == null ){
            this.event.event_data.attachment.imgs = [];
        }
        
        let file_count : number = files.length;
        let end_count  : number = 0;

        // 이미지가 최대치 만큼 이미 차 있는 경우
        if( this.event.event_data.attachment.imgs.length >= this.image_max_cnt ) {
            alert(`일정 이미지 최대 개수는 ${this.image_max_cnt}개 입니다`);
            $('#cdImg').val("");
            return;
        }

        // 이미지 여유 공간이 있지만 선택한 이미지 개수 + 기존에 있던 이미지 개수가 최대치를 넘은 경우
        if( this.event.event_data.attachment.imgs.length + file_count > this.image_max_cnt ) {
            if( files instanceof FileList ) {
                files = Array.prototype.slice.call(files, 0, (this.image_max_cnt - this.event.event_data.attachment.imgs.length));
            }

            else {
                alert(`일정 이미지 최대 개수는 ${this.image_max_cnt}개 입니다`);
                $('#cdImg').val("");
                return;
            }

            file_count = files.length;
        }

        await this.hodu_show_indicator();

        for( let i = 0; i < file_count; i++ ) {
            await this.fileReaderPromise(files[i])
                .then(async(pe_fr : any) => {

                    if(pe_fr.target == null || pe_fr.target.result == null || vue.event.event_data.attachment == null || vue.event.event_data.attachment.imgs == null ){
                        return;
                    }
                    
                    let base64url : string = "";

                    if( pe_fr.target.result instanceof ArrayBuffer ){
                        const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                        const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                        base64url = decodeURIComponent(url);
                    } else {
                        base64url = pe_fr.target.result;
                    }

                    // 이미지 리사이즈
                    const blob : Blob = await vue.hodu_image_resize(base64url);
                    
                    // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                    let resize_file : File = files[i];
                    try{
                        resize_file = await this.hodu_blob_to_file(blob, files[i].name);
                    }catch(e){
                        try {
                            (blob as any).lastModifiedDate = new Date();
                            (blob as any).name = files[i].name;
                            resize_file = (blob as any);
                        } catch(e) {
                            this.hodu_error_process(e, false, false, true);
                        }
                    }

                    vue.event.event_data.attachment.imgs.push({
                        name: resize_file.name,         // 원본 파일명
                        mimeType: resize_file.type,     // MIME TYPE
                        url: URL.createObjectURL(blob), // 파일 경로
                        size: resize_file.size,         // 파일 크기
                        date: new Date()                // 저장 날짜
                    })

                    // 업로드용 파일 객체 담기
                    vue.image_files.push({
                        index : ( vue.event.event_data.attachment.imgs.length - 1 ), 
                        file : resize_file
                    });

                    // 마지막 파일 로드 후 input 값 비우기 [안 비우면 똑같은 사진 multiple 아니면 안들어감]
                    if( file_count == ++end_count ) { $('#cdImg').val(""); }

                })
                .catch((e) => {
                    console.log('filereader promise error',e);
                });
        }

        await this.hodu_hide_indicator();
    }

    /**
     * 파일리더 promise
     */
    fileReaderPromise(file : File | Blob) : Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader : FileReader = new FileReader();
            fileReader.onload = (fr) => resolve(fr);
            fileReader.onerror = () => reject();
            fileReader.readAsDataURL(file);
        });
    }

    /**
     * 이미지 드래그해서 이미지 영역에 올려놨을때
     */
    imageDragOver(event) : void {
        event.dataTransfer.dropEffect = 'copy';
        this.image_drag = true;
    }

    /**
     * 이미지 드래그해서 이미지 영역에서 벗어났을때
     */
    imageDragLeave() : void {
        this.image_drag = false;
    }

    /**
     * 이미지를 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    imageDrop(event) : void {
        this.image_drag = false;
        this.addImage(event.dataTransfer.files);
    }

    /**
     * 이미지 삭제
     */
    deleteImage(image : t_event_file) : void {
        if( this.event.event_data.attachment == null || this.event.event_data.attachment.imgs == null ) {
            return;
        }

        const image_index : number = this.event.event_data.attachment.imgs.indexOf(image);

        if( image_index == -1 ){
            return;
        }

        this.event.event_data.attachment.imgs.splice(image_index, 1);

        // 새로 올리는 파일을 검사해서 같이 삭제한다
        const image_files_count : number = this.image_files.length;
        for( let i = (image_files_count - 1); i >= 0; i--) {
            
            // 삭제하는 인덱스보다 큰 객체는 index를 1 줄인다
            if( this.image_files[i].index > image_index ) {
                this.image_files[i].index--;
            }

            // 삭제할 인덱스가 보이면 삭제후 나간다
            else if( this.image_files[i].index == image_index ) {
                this.image_files.splice(i, 1);
                break;
            }
        }
    } 

    /**
     * 이미지 미리보기
     */
    imagePreviewOpen(image : t_event_file) : void {
        // 일정 조회(읽기모드)에서만 사용가능
        if( this.event_crud_type != CRUD_TYPE.READ ) {
            return;
        }

        // 객체에 첨부 객체 or 이미지객체가 없다면 무시
        if( this.event.event_data.attachment == null || this.event.event_data.attachment.imgs == null ) {
            return;
        }

        const image_files : t_event_file[] = this.event.event_data.attachment.imgs;
        const image_index : number         = this.event.event_data.attachment.imgs.indexOf(image);

        if( image_index == -1 ){
            return;
        }

        this.doSetEventImagePreviewInfo({
            "selected_index" : image_index,
            "files" : image_files
        });
        this.doSetShowEventImagePreview(true);
    }

    /**
     * 파일 추가 - +버튼 눌러서 추가
     */
    addFileNormal(event) : void {
        const vue = this;
        const files : File[] = event.target.files;
        this.addFile(files);
    }

    /**
     * 파일 객체 추가
     */
    addFile(files : File[]) : void {
        if( this.event.event_data.attachment == null ){
            this.event.event_data.attachment = {
                imgs : [],
                files : []
            }
        }

        if( this.event.event_data.attachment.files == null ){
            this.event.event_data.attachment.files = [];
        }
        
        const file_count : number = files.length;
        const event_files : t_event_file[] = [];
        
        const is_premium = this.is_premium_group(this.event.group_id > 0 ? 'GROUP' : this.event!.team_id > 0 ? 'TEAM' : '', this.event.group_id > 0 ? this.event.group_id : this.event.team_id > 0 ? this.event.team_id : 0);

        let max_size : number = is_premium ? this.DEFAULT_FILE_MAX_SIZE : this.NORMAL_GROUP_FILE_MAX_SIZE;
        let max_size_text : string = is_premium ? this.DEFAULT_FILE_MAX_SIZE_TEXT : this.NORMAL_GROUP_FILE_MAX_SIZE_TEXT;

        // 파일 용량 체크 후 t_event_file로 만들어 push
        for( let i = 0; i < file_count; i++ ) {
            if( files[i].size > max_size ) {
                alert(`${max_size_text} 이하의 파일만 업로드 가능 합니다`);
                $('#cdFile').val("");
                return;
            }

            // 확장자가 없는 파일
            if( files[i].name.lastIndexOf('.') == -1 ) {
                alert("확장자가 있는 파일만 업로드 가능 합니다");
                $('#cdFile').val("");
                return;
            } 
            
            // 확장자 제한 확인
            if( this.file_extension_forbidden.indexOf(files[i].name.substring(files[i].name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                alert(`${ files[i].name.substring(files[i].name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`);
                $('#cdFile').val("");
                return;
            }
            
            event_files.push({
                name: files[i].name,     // 원본 파일명
                mimeType: files[i].type, // MIME TYPE
                url: "",                 // 파일 경로
                size: files[i].size,     // 파일 크기
                date: new Date()         // 저장 날짜
            })

            // 업로드용 파일 객체 담기
            this.all_files.push({
                index : ( this.event.event_data.attachment.files.length + i ), 
                file : files[i]
            });
        }

        // 배열 합치기
        this.event.event_data.attachment.files = this.event.event_data.attachment.files.concat(event_files);

        // 파일 input 비우기
        $('#cdFile').val("");
    }

     
    /**
     * 파일 삭제
     */
    deleteFile(file : t_event_file) : void {
        if( this.event.event_data.attachment == null || this.event.event_data.attachment.files == null ) {
            return;
        }

        const file_index : number = this.event.event_data.attachment.files.indexOf(file);

        if( file_index == -1 ){
            return;
        }

        this.event.event_data.attachment.files.splice(file_index, 1);

        // 새로 올리는 파일을 검사해서 같이 삭제한다
        const files_count : number = this.all_files.length;
        for( let i = (files_count - 1); i >= 0; i--) {
            
            // 삭제하는 인덱스보다 큰 객체는 index를 1 줄인다
            if( this.all_files[i].index > file_index ) {
                this.all_files[i].index--;
            }

            // 삭제할 인덱스가 보이면 삭제후 나간다
            else if( this.all_files[i].index == file_index ) {
                this.all_files.splice(i, 1);
                break;
            }
        }
    } 

    /**
     * 파일을 드래그해서 이미지 영역에 올려놨을때
     */
    fileDragOver(event) : void {
        event.dataTransfer.dropEffect = 'copy';
        this.file_drag = true;
    }

    /**
     * 파일을 드래그해서 이미지 영역에서 벗어났을때
     */
    fileDragLeave() : void {
        this.file_drag = false;
    }

    /**
     * 파일을 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    fileDrop(event) : void {
        this.file_drag = false;
        this.addFile(event.dataTransfer.files);
    }

    // 파일 다운로드 (조회)
    fileDownload(file : t_event_file) : void {
        this.hodu_download(`/app_images/${file.url}`, file.name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }
    
    /**
     * 카카오 링크를 이용한 카카오 공유   
     */
    async kakaoShareInit() : Promise<void> {
        if( !Kakao.isInitialized() ) { await Kakao.init('c18796cb68948dbecc66ea05f7fa7773'); }
    
        // 제목
        const title : string = `[${this.event.event_data.title}]`;

        let content : string = `\n\n`;

        // 일자 표시
        // 음력
        if( this.event.event_data.schedule_date.lunar_yn == true ) {
            content += "* 일시 : " + moment(this.event.event_data.schedule_date.lunar_start).format('YYYY/MM/DD');
        }

        else {
            const day_of_week : string = this.getDayOfWeekByDate(this.event.event_data.schedule_date.start);
            content += "* 일시 : " + moment(this.event.event_data.schedule_date.start).locale("ko").format(`YYYY/MM/DD (${day_of_week}) A hh:mm`);
        }

        // 장소 표시
        const location_count : number = this.event.event_data.location ? this.event.event_data.location.length : 0;
        for( let i = 0; i < location_count; i++ ) {
            if( this.event.event_data.location == null ) { break; }
            const location : any = this.event.event_data.location[i];

            // 장소명
            if( location.place && location.place.length > 0 ) {
                
                // 장소를 직접 찍어서 가져 왔을 때 장소 정보를 못 찾은 경우 장소와 주소가 주소로 똑같이 입력되는데 그런 경우는 주소 하나만 가도록 한다
                // 도로명 주소와 같은 경우
                if( location.address && location.address.length > 0 && location.address == location.place ) {
                    // DO NOTHING
                }

                // 지번 주소와 같은 경우
                else if ( location.address_old && location.address_old.length > 0 && location.address_old == location.place ) {
                    // DO NOTHING
                }

                // 도로명, 지번 주소와 다른 경우
                else {
                    content += "\n\n* 장소 : " + location.place;
                }
            }

            // 주소(도로명)
            if( location.address && location.address.length > 0 ) {
                content += "\n\n* 주소 : " + location.address;
            }

            // 주소(지번)
            else if( location.address_old && location.address_old.length > 0 ) {
                content += "\n\n* 주소 : " + location.address_old;
            }

            // 전화번호
            if( location.tel && location.tel.length > 0 ) {
                content += "\n\n* 전화 : " + location.tel;
            }
        }

        // 메모
        if( this.event.event_data.memo && this.event.event_data.memo.length > 0 ) {
            content += "\n\n* 메모 : " + this.event.event_data.memo;
        }

        const user_id  : number = this.event.user_id  == null ? 0 : this.event.user_id;
        const group_id : number = this.event.group_id == null ? 0 : this.event.group_id;
        const team_id  : number = this.event.team_id  == null ? 0 : this.event.team_id;

        const scope    : string = user_id > 0 ? OWNER_TYPE.PERSONAL 
                                              : group_id > 0 ? OWNER_TYPE.GROUP : OWNER_TYPE.TEAM;

        const scope_id : number = user_id > 0 ? user_id 
                                              : group_id > 0 ? group_id : team_id;

        const params : string = `event_id=${this.event.event_id}&event_sub_type=${this.event.event_type}&noti_type=PUSH&scope=${scope}&scope_id=${scope_id}&desc=${title} 일정이 공유되었습니다`;

        let send_text : string = title + content;

        // 200자를 넘어가면 잘라내고 ...을 붙인다
        const KAKAO_LINK_MAX_TEXT_LENGTH : number = 200;
        if( send_text.length > KAKAO_LINK_MAX_TEXT_LENGTH ) {
            send_text = send_text.substring(0, KAKAO_LINK_MAX_TEXT_LENGTH - 4) + " ...";
        }

        Kakao.Link.createDefaultButton({
            container: '#kakao_link_btn',
            objectType: 'text',
            text : send_text,
            link: {
                androidExecParams: params,
                iosExecParams: params,
            },
            buttons: [
                {
                    title: '호두 실행',
                    link: {
                        androidExecParams: params,
                        iosExecParams: params,
                    }
                },
            ]
        });
    }

    /**
     * 이메일 공유
     */
    emailShare() : void {
        let calendar_id : string = this.event.calendar_id ? this.event.calendar_id : "";
        let event_id : string = this.event.event_id ? this.event.event_id : "";
        let scope : string = this.share_info.share_scope;
        let scope_id : number = this.share_info.share_scope_id;
        
        const start_date = new Date(this.event.event_data.schedule_date.start);
        const end_date = new Date(this.event.event_data.schedule_date.end);

        const title = `${this.user_name}님이 ${this.event.event_data.title} 일정을 공유했습니다`;

        // this.doSetEventEmailShareModalInfo?.({
        //     show_modal : true,
        //     calendar_id : calendar_id,
        //     scope : scope,
        //     scope_id : scope_id,
        //     event_id : event_id,
        //     start : start_date,
        //     end : end_date,
        //     title : title,
        //     sender_email : this.user_email
        // });

        this.doSetCommonSelectFriendAndGroupTeamModalInfo?.({
            show_modal : true,
            title : "메일 보내기",
            event_calendar_id : calendar_id,
            event_scope : scope,
            event_scope_id : scope_id,
            event_id : event_id,
            event_start : start_date,
            event_end : end_date,
            event_title : title,
            use_need_user_email : true,
        });
    }

    /**
     * 공유 Modal 열기
     */
    async shareModalOpen() : Promise<void> {
        
        this.doSetCommonSelectFriendAndGroupTeamModalInfo?.({
            show_modal : true,
            title : this.isWork(this.event.event_sub_type as EVENT_SUB_TYPE) ? '공유' : '전송',
            event_sub_type : (this.event.event_sub_type as EVENT_SUB_TYPE),
            event_crud_type : (this.event_crud_type as CRUD_TYPE),
            share_option : this.share_option,
            use_share_option : true,
            selected_friend_user_ids : this.selected_friend_user_ids,
            group_user_selected_key : this.group_user_selected_key,
            team_user_selected_key : this.team_user_selected_key,
            selected_user_ids : this.selected_user_ids,
            selected_group_ids : this.selected_group_ids,
            selected_team_ids :this.selected_team_ids,
            callback : async(share_info) => {
                try {

                    console.log(share_info);

                    if( this.event_crud_type == CRUD_TYPE.CREATE ) {
                        this.share_option = share_info.share_option;
                        this.selected_friend_user_ids = share_info.selected_friend_user_ids;
                        this.group_user_selected_key = share_info.group_user_selected_key;
                        this.team_user_selected_key = share_info.team_user_selected_key;
                        this.selected_user_ids = share_info.selected_user_ids;
                        this.selected_group_ids = share_info.selected_group_ids;
                        this.selected_team_ids = share_info.selected_team_ids;

                        this.event.subscribe_users = share_info.selected_user_ids;
                        this.event.subscribe_groups = share_info.selected_group_ids;
                        this.event.subscribe_teams = share_info.selected_team_ids;
                        return;
                    }

                    // 일정 조회        
                    if( share_info.selected_user_ids.length < 1 && share_info.selected_group_ids.length < 1 && share_info.selected_team_ids.length < 1 ) {
                        return;
                    }
                    
                    // const vue = this;

                    let scope : string = "";
                    let scope_id : number | undefined = 0;

                    scope = this.event.event_type ? this.event.event_type : OWNER_TYPE.PERSONAL;

                    scope_id = scope == OWNER_TYPE.PERSONAL ? this.user_id 
                                                            : scope == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id;

                    let response : any = null;
                    let share_text : string = "";
                    
                    const body =  {
                        "subscribe_users"  : share_info.selected_user_ids,
                        "subscribe_groups" : share_info.selected_group_ids,
                        "subscribe_teams"  : share_info.selected_team_ids
                    };

                    if( share_info.share_option == SHARE_OPTION.SHARE ) {
                        response = await this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/${this.event.event_id}/share`, API_METHOD.POST, body);
                        share_text = "공유";
                    }

                    else if ( share_info.share_option == SHARE_OPTION.COPY ) {
                        response = await this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/${this.event.event_id}/share/copy`, API_METHOD.POST, body);
                        share_text = "복사";
                    }

                    else if ( share_info.share_option == SHARE_OPTION.MOVE ) {
                        response = await this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/${this.event.event_id}/share/move`, API_METHOD.POST, body);
                        share_text = "이동";
                    }

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("공유 처리 중 오류 발생");
                    }

                    this.hodu_show_dialog("success", `${share_text} 요청 완료`, ['확인'], [
                        () => {

                            // 그룹 또는 팀에 이동 시킨 경우
                            if( share_info.share_option == SHARE_OPTION.MOVE && (share_info.selected_group_ids.length + share_info.selected_team_ids.length > 0) ) {
                                this.movePrevPage();
                            }

                        }
                    ]);

                } catch(e) {
                    const additional_message = (e as any)?.response?.data ?? "";
                    this.hodu_show_dialog("cancel", "공유 처리 중 오류 발생" + (additional_message.length > 0 ? `\n${additional_message}` : ""), ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        });

    }

    /**
     * 공유 수신자보기 Modal 열기
     */
    shareStatusModalOpen() : void {

        // 작성자 본인만 공유 수신자 보기가 가능함
        if( this.isManager() == false ) {
            return;
        }

        let scope : string = "";
        let scope_id : number | undefined = 0;

        scope = this.event.event_type ? this.event.event_type : OWNER_TYPE.PERSONAL;

        scope_id = scope == OWNER_TYPE.PERSONAL ? this.user_id 
                                                : scope == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id;

        this.doSetEventShareStatusInfo({
            show_event_share_status : true,
            event_id : this.event.event_id,
            calendar_id : this.event.calendar_id,
            scope : scope,
            scope_id : scope_id
        });
    }

    /**
     * 공유 내용 저장
     */
    setShareInfo() : void {
        this.share_option           = this.event_share_info.share_option;
        this.event.subscribe_users  = this.event_share_info.user_ids;
        this.event.subscribe_groups = this.event_share_info.group_ids;
        this.event.subscribe_teams  = this.event_share_info.team_ids;
    }

    /**
     * 공유 값 삭제
     */
    deleteShare() : void {

        if( this.isUpdate(this.event_crud_type) == true ) return; 

        if( this.event.subscribe_users != null ) {
            this.event.subscribe_users.splice(0, this.event.subscribe_users.length)
        } 

        if( this.event.subscribe_groups != null ) {
            this.event.subscribe_groups.splice(0, this.event.subscribe_groups.length)
        }

        if( this.event.subscribe_teams != null ) {
            this.event.subscribe_teams.splice(0, this.event.subscribe_teams.length)
        }

        this.doSetEventShareInfo({
            share_option : SHARE_OPTION.SHARE,
            user_ids : [],
            group_ids : [],
            team_ids : [],
            group_user_ids : [],
            team_user_ids : []
        });

        this.share_option = SHARE_OPTION.SHARE;
        this.selected_friend_user_ids.splice(0, this.selected_friend_user_ids.length);
        this.group_user_selected_key.splice(0, this.group_user_selected_key.length);
        this.team_user_selected_key.splice(0, this.team_user_selected_key.length);
        this.selected_user_ids.splice(0, this.selected_user_ids.length);
        this.selected_group_ids.splice(0, this.selected_group_ids.length);
        this.selected_team_ids.splice(0, this.selected_team_ids.length);
    }

    /**
     * 조회에서 공유 toggle
     */
    readShareButtonGroupToggle() : void {
        this.read_share_flag = !this.read_share_flag;
    }

    /**
     * 참석여부 변경
     */
    clickAttend(event : any, attend_flag : string) {
        const vue = this;

        // 이미 종료된 일정이라면 불가능
        if( new Date(this.event.event_data.schedule_date.end).getTime() <= new Date().getTime() ) {
            this.hodu_show_dialog('alert', '이미 종료된 일정 입니다', ['확인']);
            event.preventDefault();
            return;
        }

        // 이미 해당 flag를 가지고 있다면 cancelAttend
        if( this.attend_data != null && attend_flag == this.attend_data.attend ) {
            vue.attend_data = null;
            
            this.hodu_api_call(`api/v1/attend/${this.event.event_id}`, API_METHOD.DELETE)
                .then((response) => {
                    console.log(response);
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                });
        }

        // 다른플래그 혹은 아무 플래그도 없다면 추가
        else {
            this.hodu_api_call(`api/v1/attend/${this.event.event_id}`, API_METHOD.POST, { attend : attend_flag })
                .then((response) => {
                    console.log(response);

                    vue.attend_data = {
                        event_id : vue.event.event_id == null ? "" : vue.event.event_id,
                        user_id : vue.user_id == null ? 0 : vue.user_id,
                        user_name : vue.user_name == null ? "" : vue.user_name,
                        attend : attend_flag,
                        audit_modified : new Date()
                    };
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                });
        }
    }

    /**
     * 참석여부 결과 Modal 열기 
     */
    attendResultModalOpen() : void {
        this.doSetVoteModifyIndex(0);
        this.doSetShowEventAttendResult(true);
    }

    /**
     * 투표 작성 Modal 열기
     */
    voteMakeModalOpen(index : number = -1) : void {
        if( index == -1 || this.event.event_data.vote == null || this.event.event_data.vote.length < 1) {
            this.doSetVote({
                title : "",
                end_date : new Date(this.event.event_data.schedule_date.end),
                items_type : "T",
                items : ["", "", "", ""],
                secret : true,
                multiple : false,
                ing_report : false,
                is_end : false
            });
        } else {
            this.doSetVote(JSON.parse(JSON.stringify(this.event.event_data.vote[index])));
        }

        this.doSetVoteEndDateLimit(new Date(this.event.event_data.schedule_date.end));
        this.doSetVoteModifyIndex(index);
        this.doSetShowEventVoteCreate(true);
    }

    /**
     * 투표 추가
     */
    createVote(vote : t_event_vote) : void {      
        if( this.event.event_data.vote == null ) {
            this.event.event_data.vote = [];
        }

        // 나머지 vote의 투표종료일도 똑같이 맞춘다
        const vote_length : number = this.event.event_data.vote.length;
        for( let i = 0; i < vote_length; i++ ) {
            this.event.event_data.vote[i].end_date = vote.end_date;
        }

        this.event.event_data.vote.push(JSON.parse(JSON.stringify(vote)));
        this.doSetShowEventVoteCreate(false);

        if( this.event_crud_type != CRUD_TYPE.CREATE && this.vote_result != null ) {
            if( this.vote_result.vote == null ) {
                this.vote_result.vote = [];
            }

            this.vote_result.vote.push([]);
            this.new_vote_index.push(this.vote_result.vote.length - 1);
        }
    }

    /**
     * 투표 수정
     */
    updateVote(index : number, vote : t_event_vote) {

        if( this.event.event_data.vote == null || this.event.event_data.vote.length < 1 ) {
            return;
        }

        // 나머지 vote의 투표종료일도 똑같이 맞춘다
        const vote_length : number = this.event.event_data.vote.length;
        for( let i = 0; i < vote_length; i++ ) {
            this.event.event_data.vote[i].end_date = vote.end_date;
        }

        this.event.event_data.vote.splice(index, 1, vote);
        this.doSetShowEventVoteCreate(false);

    }

    /**
     * 투표 삭제
     */
    deleteVote(index : number) : void {
        if( this.event.event_data.vote == null || this.event.event_data.vote.length < 1 ) {
            return;
        }
        
        // 투표리스트에서 제거
        this.event.event_data.vote.splice(index, 1);
        this.doSetShowEventVoteCreate(false);

        // 조회&수정에서 투표 아이템리스트 삭제
        if( this.event_crud_type != CRUD_TYPE.CREATE && this.vote_result != null ) {
            if( this.vote_result.vote == null ) {
                this.vote_result.vote = [];
            }

            this.vote_result.vote.splice(index, 1);

            // 새로운 투표임을 알렸던 index를 없앤다
            if( this.new_vote_index.indexOf(index) > -1 ) {
                this.new_vote_index.splice(this.new_vote_index.indexOf(index), 1);
            }

            // 새로운 투표임을 알리는 index가 방금 삭제한 index보다 높은 숫자일 경우 -1 해준다
            const new_vote_index_count : number = this.new_vote_index.length;
            for( let i = new_vote_index_count - 1; i >= 0; i-- ) {
                if( this.new_vote_index[i] > index ) {
                    this.new_vote_index.splice(i, 1, this.new_vote_index[i] - 1);
                } 
            }
        }
    }

    /**
     * 투표 결과 Modal 열기
     */
    voteResultModalOpen(index : number) : void {
        this.doSetVoteModifyIndex(index);
        this.doSetShowEventVoteResult(true);
    }

    /**
     * 투표 상태 반환
     */
    getVoteStatus(vote_object : any, index : number) : string {
        
        /**
         * 투표 진행 중 + 사용자가 투표한 결과 없음
         */
        if( vote_object.is_end == false && new Date().getTime() <  new Date(vote_object.end_date).getTime() && ( this.vote_result == null || this.vote_result.vote[index].length < 1 ) ) {
            return '진행 중';
        } 

        /**
         * 투표 진행 중 + 사용자가 투표한 결과 있음
         */
        else if( vote_object.is_end == false && new Date().getTime() <  new Date(vote_object.end_date).getTime() && 
                 this.vote_result != null && this.vote_result.vote[index].length > 0 ) {
            return '진행 중';
        }   
        
        /**
         * 투표 마감
         */
        else if( vote_object.is_end == true  || new Date().getTime() >= new Date(vote_object.end_date).getTime() ) {
            return '마감';
        } 

        return '';
    }

    /**
     * 투표한 아이템 선택 취소
     */
    voteItemDelete(vote_index : number, vote_item_index : number) : void {
        const vue = this;
        
        // 투표 값이 없는 경우, 사용자가 투표한 결과가 없는 경우
        if( this.event.event_data.vote == null || this.vote_result == null ) {
            return;
        }

        // 투표 수가 맞지 않는 경우
        if( this.event.event_data.vote.length < ( vote_index + 1 ) ) {
            return;
        }

        // 투표 아이템 수가 맞지 않는 경우
        if( this.event.event_data.vote[vote_index].items.length < ( vote_item_index + 1 )  ) {
            return;
        }

        // 투표가 이미 종료된 경우
        if( this.event.event_data.vote[vote_index].is_end == true || new Date(this.event.event_data.vote[vote_index].end_date).getTime() <= new Date().getTime() ) {
            return;
        }
        
        // body용 
        const items : Number[][] = JSON.parse(JSON.stringify(this.vote_result.vote));
        const item_index : number = items[vote_index].indexOf(vote_item_index);

        items[vote_index].splice(item_index, 1);

        this.hodu_api_call(`api/v1/vote/voting/${this.event.event_id}`, API_METHOD.POST, { vote : items })
            .then((response) => {
                console.log(response);

                if( vue.vote_result == null ) {
                    return;
                }

                vue.vote_result.vote[vote_index].splice(item_index, 1);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 댓글 창 열기
     */
    showEventReply() : void {
        this.doSetShowEventReply(true);
    }

    /**
     * 댓글 읽음 여부 조회 모달 열기
     */
    showEventRead() : void {

        const scope = this.event.event_type ? this.event.event_type : OWNER_TYPE.PERSONAL;
        const scope_id = scope == OWNER_TYPE.PERSONAL ? this.user_id 
                                                : scope == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id;

        this.doSetEventReadModalInfo?.({
            show_modal : true,
            event_id : (this.event.event_id ?? ""),
            repeat_seq : this.repeat_seq,
            calendar_id : (this.event.calendar_id ?? ""),
            owner_type : scope,
            owner_id : (scope_id ?? 0),
        });
    }

    /**
     * Message Modal
     */
    // exampleMsg(message_type : string, message : string, button_text : string[]) : void {
    //     this.hodu_show_dialog(message_type, message, button_text);
    // }

    /**
     * 일정 저장 (생성 및 수정)
     */
    async saveEvent() : Promise<void> {

        const vue = this;
        
        if( await this.checkValid() == false ) {
            return;
        }

        // 규칙에 따라서 값 세팅
        await this.setEventData();

        let scope : string = "";
        let scope_id : number | undefined = 0;

        // 작성할때는 event_type 기준으로 scope, scope_id 생성
        if( this.event_crud_type == CRUD_TYPE.CREATE ) {
            scope = this.event.event_type ? this.event.event_type : OWNER_TYPE.PERSONAL;
            scope_id = scope == OWNER_TYPE.PERSONAL ? this.user_id 
                                                    : scope == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id;
        }

        // 수정할떄는 share_info 기준으로 scope, scope_id 생성
        else {
            if( this.share_info == null ) { return }
            scope = this.share_info.share_scope;
            scope_id = this.share_info.share_scope_id;
            
            // OWNER 권한으로 체크
            const target = this.share_infos.filter(share_info => share_info.share_type == 'OWNER');
            if( target.length > 0 ) {
                scope = target[0].share_scope;
                scope_id = target[0].share_scope_id;
            }
        }
        
        // 이미지, 파일 임시 업로드
        try {
            await this.eventTempFileProcess();
        } catch(e) {
            this.hodu_error_process(e, true, false);
            return;
        }

        // 일정 생성
        if( this.event_crud_type == CRUD_TYPE.CREATE ) {

            let url : string = "";
            let query : string = ""; // subscribe_users, subscribe_groups, subscribe_teams

            // 일정 저장
            if( this.event.event_sub_type != EVENT_SUB_TYPE.WORK ) {

                query += `share_option=${this.share_option}`;

                // subscribe_users query
                if( this.event.subscribe_users != null && this.event.subscribe_users.length > 0 ) {
                    
                    const user_count : number = this.event.subscribe_users.length;
                    for( let i = 0; i < user_count; i++ ) {
                        if( query != '' ) {
                            query += '&';
                        }
                        query += `subscribe_users=${this.event.subscribe_users[i]}`;
                    }
                    
                }

                // subscribe_groups query
                if( this.event.subscribe_groups != null && this.event.subscribe_groups.length > 0 ) {
                    
                    const group_count : number = this.event.subscribe_groups.length;
                    for( let i = 0; i < group_count; i++ ) {
                        if( query != '' ) {
                            query += '&';
                        }
                        query += `subscribe_groups=${this.event.subscribe_groups[i]}`;
                    }
                    
                }

                // subscribe_teams query
                if( this.event.subscribe_teams != null && this.event.subscribe_teams.length > 0 ) {
                    
                    const team_count : number = this.event.subscribe_teams.length;
                    for( let i = 0; i < team_count; i++ ) {
                        if( query != '' ) {
                            query += '&';
                        }
                        query += `subscribe_teams=${this.event.subscribe_teams[i]}`;
                    }
                    
                }

                url = `api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}?${query}`;
            } 
            
            // 업무 저장
            else if ( this.event.event_sub_type == EVENT_SUB_TYPE.WORK ) {
                query = `event_type=${scope}`;

                if( this.assignment_user_list != null && this.assignment_user_list.length > 0 ) {
                    const assignment_user_list_count : number = this.assignment_user_list.length;
                    for( let i = 0; i < assignment_user_list_count; i++ ) {
                        query += `&assignment_users=${this.assignment_user_list[i]}`;
                    }
                }

                url = `api/v1/calendars/${this.event.calendar_id}/works/${scope}/${scope_id}?${query}`;
            }

            // 일정 or 업무 생성
            await this.hodu_api_call(url, API_METHOD.POST, this.event.event_data)
                .then(async(response) => {
                    console.log(response);

                    // 개인 캘린더에서 일반 일정 작성일때 마지막에 생성한 그룹&팀 기억
                    if( this.scope == OWNER_TYPE.PERSONAL && this.event.event_sub_type == EVENT_SUB_TYPE.SCHEDULE ) {
                        local_storage_info.last_event_created_scope = {
                            "scope" : scope,
                            "scope_id" : scope_id
                        };
                        local_storage_info.last_event_created_color = this.event.event_data.color ?? '#477FFF';
                        hodu_local_storage.setItem(`${this.user_id}`, JSON.stringify(local_storage_info)); 
                    }

                    if( vue.event.temp_id != null ) {
                        await vue.deleteTemp(vue.event.temp_id);
                    }
                    
                    vue.movePrevPage();
                    
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
        }

        // 일정 수정
        else {
        
            switch( this.repeat_modify_code ) {
                // 일반 일정 수정, 반복 전체 수정
                case "":
                case "A":
                    await this.updateAPICall();
                    break;

                // 반복 이후 일정 수정
                case "F":
                    await this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/${this.event.event_id}/repeat`, API_METHOD.POST, this.event.event_data)
                        .then(async(response) => {
                            console.log(response);
                            local_storage_info.last_event_created_color = this.event.event_data.color ?? '#477FFF';
                            hodu_local_storage.setItem(`${this.user_id}`, JSON.stringify(local_storage_info)); 
                            vue.movePrevPage();
                        })
                        .catch(async(e) => {
                            this.hodu_error_process(e, true, false);
                        });
                    break;

                // 반복 이 일정만 수정
                case "E":
                    this.event.event_data.schedule_date.isIgnore = false;
                    
                    // 일정 저장
                    await this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}`, API_METHOD.POST, this.event.event_data)
                        .then(async(response) => {
                            console.log(response);
                            local_storage_info.last_event_created_color = this.event.event_data.color ?? '#477FFF';
                            hodu_local_storage.setItem(`${this.user_id}`, JSON.stringify(local_storage_info)); 
                            vue.movePrevPage();
                        })
                        .catch(async(e) => {
                            this.hodu_error_process(e, true, false);
                        });
                    break;
            }

        }
    }

    /**
     * 일정 임시 저장
     */
    async tempSaveEvent() {

        await this.setEventData();

        let scope : string = "";
        let scope_id : number | undefined = 0;

        // 작성할때는 event_type 기준으로 scope, scope_id 생성
        if( this.event_crud_type == CRUD_TYPE.CREATE ) {
            scope = this.event.event_type ? this.event.event_type : OWNER_TYPE.PERSONAL;
            scope_id = scope == OWNER_TYPE.PERSONAL ? this.user_id 
                                                    : scope == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id;
        }

        // 수정할떄는 share_info 기준으로 scope, scope_id 생성
        else {
            if( this.share_info == null ) { return }
            scope = this.share_info.share_scope;
            scope_id = this.share_info.share_scope_id;
            
            // OWNER 권한으로 체크
            const target = this.share_infos.filter(share_info => share_info.share_type == 'OWNER');
            if( target.length > 0 ) {
                scope = target[0].share_scope;
                scope_id = target[0].share_scope_id;
            }
        }
        
        // 이미지, 파일 임시 업로드
        try {
            await this.eventTempFileProcess();
        } catch(e) {
            this.hodu_error_process(e, true, false);
            return;
        }

        this.event.is_temp = true;
        this.event.scope_group_id = this.scope_group_id;
        this.event.scope_team_id = this.scope_team_id;

        // 업무
        if ( this.event.event_sub_type == EVENT_SUB_TYPE.WORK && this.event.event_data.work != null && this.selected_work_template != null ) {
            this.event.event_data.work.assign_user_ids = this.assignment_user_list;
            this.event.event_data.work.template_id = this.selected_work_template.template_id;
            this.event.event_data.work.template_type = this.selected_work_template.template_type;
        }
        else {
            this.event.share_option = this.share_option;
            this.event.selected_friend_user_ids = this.selected_friend_user_ids;
            this.event.group_user_selected_key = this.group_user_selected_key;
            this.event.team_user_selected_key = this.team_user_selected_key;
            this.event.selected_user_ids = this.selected_user_ids;
            this.event.selected_group_ids = this.selected_group_ids;
            this.event.selected_team_ids = this.selected_team_ids;
        }

        try {

            let response : any = null;

            if( this.event.temp_id != null ) {
                response = await this.hodu_api_call(`api/v1/temp/${this.event.temp_id}`, API_METHOD.PUT, {
                    temp_type : this.event.event_sub_type,
                    temp_data : this.event,
                });
            } 
            else {
                response = await this.hodu_api_call(`api/v1/temp`, API_METHOD.POST, {
                    temp_type : this.event.event_sub_type,
                    temp_data : this.event,
                });
            }

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("임시 저장 실패");
            }

            this.movePrevPage();

        } catch(e) {
            this.hodu_error_process(e, true, false);
        }
        
    }

    async deleteTemp(temp_id) {

        try {
            
            const response = await this.hodu_api_call(`api/v1/temp/${temp_id}`, API_METHOD.DELETE);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("임시보관함 삭제 중 오류 발생");
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', "임시보관함 삭제 중 오류 발생", ['확인']);
        }
            
    }

    /**
     * 임시파일 업로드
     */
    async eventTempFileProcess() : Promise<void> {
        try {

            await this.hodu_show_indicator();

            /**
             * 이미지 파일이 있을때 => 이미지 임시파일 생성
             */
            if( this.image_files.length > 0 ) {
                await this.imageTempUpload(); 
            }

            /**
             * 첨부파일이 있을때 
             */
            if( this.all_files.length > 0 ) {
                await this.fileTempUpload();
            }

        } catch(e) {
            throw e;
        } finally {
            await this.hodu_hide_indicator();
        }
    }

    
    /**
     * 이미지 임시파일 업로드
     */
    async imageTempUpload() : Promise<void> {

        const vue = this;

        const form_data : FormData = new FormData();
        
        // FormData 내용 생성
        const image_files_count : number = this.image_files.length;
        for( let i = 0; i < image_files_count; i++ ) {
            form_data.append('file', this.image_files[i].file);
        }

        try {
            const response = await this.hodu_temp_upload(form_data);
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("이미지 임시 업로드 실패");
            }

            const temp_files : t_event_file[] = response.data.data.temp_files;

            // attachment null check
            if( vue.event.event_data.attachment == null ) {
                vue.event.event_data.attachment = {
                    imgs : [],
                    files : []
                };
            }

            // imgs null check
            if( vue.event.event_data.attachment.imgs == null ) {
                vue.event.event_data.attachment.imgs = [];
            }

            // 임시파일 데이터로 객체 대체하기
            const image_files_count : number = this.image_files.length;
            for( let i = 0; i < image_files_count; i++ ) {
                vue.event.event_data.attachment.imgs.splice(this.image_files[i].index, 1, temp_files[i]);    
            }

        } catch(e) {
            throw e;
        }    
    }

    /**
     * 파일 임시 업로드
     */
    async fileTempUpload() : Promise<void> {
        
        const vue = this;

        // await 파일 TEMP 업로드
        const form_data : FormData = new FormData();
        
        // FormData 내용 생성
        const all_files_count : number = this.all_files.length;
        for( let i = 0; i < all_files_count; i++ ) {
            form_data.append('file', this.all_files[i].file);
        }

        try {
            const response = await this.hodu_temp_upload(form_data);
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("파일 임시 업로드 실패");
            }

            const temp_files : t_event_file[] = response.data.data.temp_files;

            // attachment null check
            if( vue.event.event_data.attachment == null ) {
                vue.event.event_data.attachment = {
                    imgs : [],
                    files : []
                };
            }

            // files null check
            if( vue.event.event_data.attachment.files == null ) {
                vue.event.event_data.attachment.files = [];
            }

            // 임시파일 데이터로 객체 대체하기
            const all_files_count : number = this.all_files.length;
            for( let i = 0; i < all_files_count; i++ ) {
                vue.event.event_data.attachment.files.splice(this.all_files[i].index, 1, temp_files[i]);    
            }

        } catch(e) {
            throw e;
        }
        
    }

    /**
     * 일정 수정 API 
     */
    async updateAPICall() : Promise<void> {
        if( this.share_info == null ) { return; }

        const vue = this;

        let scope : string = this.share_info.share_scope;
        let scope_id : number = this.share_info.share_scope_id;

        // OWNER 권한으로 체크
        const target = this.share_infos.filter(share_info => share_info.share_type == 'OWNER');
        if( target.length > 0 ) {
            scope = target[0].share_scope;
            scope_id = target[0].share_scope_id;
        }

        // 일정 수정
        if( this.event.event_sub_type != EVENT_SUB_TYPE.WORK ) {
            this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/${this.event.event_id}`, API_METHOD.PUT, this.event.event_data)
                .then((response) => {
                    console.log(response);

                    // 투표 존재한다면 투표 수정
                    if( this.event.event_data.vote != null && this.event.event_data.vote.length > 0 ) {
                        this.hodu_api_call(`api/v1/vote/${this.event.event_id}`, API_METHOD.PUT, {
                            vote : JSON.parse(JSON.stringify(this.event.event_data.vote))
                        });
                    }

                    local_storage_info.last_event_created_color = this.event.event_data.color ?? '#477FFF';
                    hodu_local_storage.setItem(`${this.user_id}`, JSON.stringify(local_storage_info)); 

                    vue.movePrevPage();

                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                });
        }

        // 업무 수정
        else if ( this.event.event_sub_type == EVENT_SUB_TYPE.WORK ) {
            let query = "";

            if( this.assignment_user_list != null && this.assignment_user_list.length > 0 ) {
                query = "?";
                const assignment_user_list_count : number = this.assignment_user_list.length;
                for( let i = 0; i < assignment_user_list_count; i++ ) {
                    if( query != '?' ) {
                        query += '&';
                    }
                    query += `assignment_users=${this.assignment_user_list[i]}`;
                }
            }

            this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/works/${scope}/${scope_id}/${this.event.event_id}${query}`, API_METHOD.PUT, this.event.event_data)
                .then((response) => {
                    console.log(response);
                    local_storage_info.last_event_created_color = this.event.event_data.color ?? '#477FFF';
                    hodu_local_storage.setItem(`${this.user_id}`, JSON.stringify(local_storage_info)); 
                    vue.movePrevPage();
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                }); 
        }

        
    }

    /**
     * 반복 수정 옵션 선택
     */
    async repeatModify(code : string) : Promise<void> {
        this.repeat_modify_code = code;
        
        // 전체 반복 수정이라면 start, end 날짜 원본 날짜로 변경
        if( code == 'A' ) {
            this.event.event_data.schedule_date.start = this.event_original_date.original_start;
            this.event.event_data.schedule_date.end   = this.event_original_date.original_end;
        }

        // 이후 일정 수정, 이 일정만 수정인 경우 recurrence_id를 클릭해서 들어온 날짜로 설정한다
        else if( code == 'F' ) {
            this.event.event_data.schedule_date.recurrence_id = this.event.event_data.schedule_date.start;
            await this.downloadImageAndFileForCopy();
        }

        // 이 일정만 수정인 경우 recurrence_id를 클릭해서 들어온 날짜로 설정 & rrule 제거
        else {
            this.event.event_data.schedule_date.recurrence_id = this.event.event_data.schedule_date.start;
            this.event.event_data.schedule_date.rrule         = undefined;
            await this.downloadImageAndFileForCopy();
        }

        await this.makeDateString();
        await this.doSetEventCrudType(CRUD_TYPE.UPDATE);
        await this.setDatePicker();

        // @ts-ignore alarmDivScroll 스크롤
        $('.alarmDivScroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 200,
            autoDraggerLength : false,
        });
    }

    /**
     * 반복 삭제 옵션 선택
     */
    async repeatDelete(code : string) : Promise<void> {
        if( this.share_info == null ) { return; }

        const vue = this;
        
        let scope : string = this.share_info.share_scope;
        let scope_id : number = this.share_info.share_scope_id;

        if( scope_id == null ) {
            scope_id = 0;
        }

        switch( code ) {
            // 전체 일정 삭제
            case "A":
                await this.deleteEventModalCall(this.deleteEventAPICall);
                break;

            // 이후 일정 삭제 ( recurrence_end를 진입 날짜 -1로 설정 후 update )
            case "F":
                await this.deleteEventModalCall(async() => {
                    this.event.event_data.schedule_date.recurrence_end = new Date(this.event.event_data.schedule_date.end);
                    this.event.event_data.schedule_date.recurrence_end.setDate(this.event.event_data.schedule_date.recurrence_end.getDate() - 1);

                    this.event.event_data.schedule_date.start = this.event_original_date.original_start;
                    this.event.event_data.schedule_date.end   = this.event_original_date.original_end;

                    await this.updateAPICall();
                });

                break;

            // 이 일정만 삭제 ( isIgnore = true로 설정 후 create )
            case "E":
                await this.deleteEventModalCall(async() => {
                    this.event.event_data.schedule_date.recurrence_id = this.event.event_data.schedule_date.start;
                    this.event.event_data.schedule_date.isIgnore = true;
                    
                    // 일정 저장
                    await this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}`, API_METHOD.POST, this.event.event_data)
                        .then(async(response) => {
                            console.log(response);
                            vue.movePrevPage();
                        })
                        .catch(async(e) => {
                            this.hodu_error_process(e, true, false);
                        });
                });
                
                break;
        }

    }

    /**
     * 저장 전 일정 데이터가 올바른지 체크
     */
    async checkValid() : Promise<boolean> {

        // 업무일때 생성된 업무 템플릿이 없는 경우
        if( this.event.event_sub_type == EVENT_SUB_TYPE.WORK && this.selected_work_template == null ) {
            this.hodu_show_dialog('alert', '프로젝트 템플릿을 먼저 생성 해 주세요', ['확인']);
            return false;
        }

        // 제목 2글자 미만 체크
        if( this.event.event_data.title.trim().length < 2 ) {
            this.hodu_show_dialog('alert', '일정 제목을 입력 해 주세요(최소 2자 이상 입력)', ['확인']);
            return false;
        }

        // 제목 100글자 초과 체크
        if( this.event.event_data.title.trim().length > 100 ) {
            this.hodu_show_dialog('alert', '일정 제목은 100자 까지 입력이 가능합니다', ['확인']);
            return false;
        }

        // 시작일, 종료일 비교 (종료일이 시작일보다 빠른 경우)
        if( new Date(this.event.event_data.schedule_date.start).getTime() > new Date(this.event.event_data.schedule_date.end).getTime() ) {
            this.hodu_show_dialog('alert', '일정 종료시간을 확인하세요', ['확인']);
            return false;
        }

        // 반복종료일 & 시작일 체크
        if( this.event.event_data.schedule_date.rrule != null && this.getDateDiff(this.event.event_data.schedule_date.start, this.event.event_data.schedule_date.recurrence_end) > 0 &&
            new Date(this.event.event_data.schedule_date.start).getTime() > new Date(this.event.event_data.schedule_date.recurrence_end).getTime() ) {
            this.hodu_show_dialog('alert', '반복 종료일이 시작일 보다 빠를 수 없습니다', ['확인']);
            return false;
        }

        const is_premium = this.is_premium_group(this.event.group_id > 0 ? 'GROUP' : this.event!.team_id > 0 ? 'TEAM' : '', this.event.group_id > 0 ? this.event.group_id : this.event.team_id > 0 ? this.event.team_id : 0);

        let max_size : number = is_premium ? this.DEFAULT_FILE_MAX_SIZE : this.NORMAL_GROUP_FILE_MAX_SIZE;
        let max_size_text : string = is_premium ? this.DEFAULT_FILE_MAX_SIZE_TEXT : this.NORMAL_GROUP_FILE_MAX_SIZE_TEXT;

        // 다시 한 번 파일 용량 체크 (일반 그룹 등으로 변경 했을 경우에 저장하는것을 막기 위함)
        // for( const file_obj of this.all_files ) {
        //     if( file_obj.file.size > max_size ) {
        //         this.hodu_show_dialog('alert', `${max_size_text} 이하의 파일만 업로드 가능 합니다`, ['확인']);
        //         return false;
        //     }
        // }
        
        return true;
    }

    /**
     * 규칙에 맞게 event값 세팅
     */
    async setEventData() : Promise<void> {
        
        /**
         * 음력 설정시 lunar_start, lunar_end 설정
         */
        if( this.event.event_data.schedule_date.lunar_yn == true ) {
            const lunar_date : string = await this.hodu_solar_to_lunar(this.event.event_data.schedule_date.start);

            // 음력 문자열 포맷 결정 되어야함
            this.event.event_data.schedule_date.lunar_start = lunar_date;
            this.event.event_data.schedule_date.lunar_end   = lunar_date;
            this.event.event_data.schedule_date.isAllDay    = true;
            this.event.event_data.schedule_date.isContinuos = true;
        }

        /**
         * 양력 설정
         */
        else {

            /**
             * 종일
             */
            if( this.event.event_data.schedule_date.isAllDay == true ) {
                const start : Date = new Date(this.event.event_data.schedule_date.start);
                const end   : Date = new Date(this.event.event_data.schedule_date.end);

                start.setHours(0);
                start.setMinutes(0);
                start.setSeconds(0);

                end.setHours(23);
                end.setMinutes(59);
                end.setSeconds(59);

                this.event.event_data.schedule_date.start       = moment(start).format('YYYY-MM-DDTHH:mm:ssZ');
                this.event.event_data.schedule_date.end         = moment(end).format('YYYY-MM-DDTHH:mm:ssZ');
                this.event.event_data.schedule_date.isContinuos = true;

            }

            /**
             * 종일이 아닐 때
             */
            else {

                // 1일 이상 차이나는 경우 (연속 일정)
                if( this.date_term > 0 ) {
                    this.event.event_data.schedule_date.isContinuos = true;

                    // 종료일이 0시 0분일때 23:59:59로 세팅
                    if( new Date(this.event.event_data.schedule_date.end).getHours() == 0 && new Date(this.event.event_data.schedule_date.end).getMinutes() == 0 ) {
                        const end : Date = new Date(this.event.event_data.schedule_date.end);
                        end.setDate(end.getDate() - 1);
                        end.setHours(23);
                        end.setMinutes(59);
                        end.setSeconds(59);

                        this.event.event_data.schedule_date.end = end;
                    }
                }

                // 일반 일정인 경우
                else {
                    this.event.event_data.schedule_date.isContinuos = false;
                }

            }

        }

        // 반복일정이 아니라면 recurrence_end를 end와 동일하게 맞춘다
        if( this.event.event_data.schedule_date.rrule == null || this.event.event_data.schedule_date.rrule.length < 1 ) {
            this.event.event_data.schedule_date.recurrence_end = new Date(this.event.event_data.schedule_date.end);
        }

        // 반복일정이라면 참석 & 투표 데이터를 없앤다
        else {
            this.event.event_data.vote   = [];
            this.event.event_data.attend = false;

            const dtStart : Date = new Date(this.event.event_data.schedule_date.start);

            // 해당 요일이 주 반복에 포함되어있지 않다면 Rrule의 첫 날짜로 start & end를 변경
            const rruleSet : RRule | RRuleSet = rrulestr(`DTSTART:${this.formatDateForRruleDTSTART(dtStart)}\nRRULE:${this.event.event_data.schedule_date.rrule};UNTIL=20501231`);
            if( rruleSet.options.freq == RRule.WEEKLY && 
                rruleSet.options.byweekday.indexOf(new Date(this.event.event_data.schedule_date.start).getDay() -1 > -1 ? new Date(this.event.event_data.schedule_date.start).getDay() -1 : 6 ) == -1 ) {
                
                const term : number = new Date(this.event.event_data.schedule_date.end).getTime() - new Date(this.event.event_data.schedule_date.start).getTime();

                this.event.event_data.schedule_date.start = rruleSet.all()[0];
                this.event.event_data.schedule_date.end   = new Date(new Date(this.event.event_data.schedule_date.start).getTime() + term);
            }
        }

        // 전화번호 세팅
        if( this.event.event_data.contacts != null && this.event.event_data.contacts[0] != null ) {
            this.event.event_data.contacts[0].tel = this.event.event_data.contacts[0].tel == null ? "" : this.event.event_data.contacts[0].tel.trim();
        }

        // 노트 & 메모 세팅(trim)
        this.event.event_data.note = this.event.event_data.note == null ? "" : this.event.event_data.note.trim();
        this.event.event_data.memo = this.event.event_data.memo == null ? "" : this.event.event_data.memo.trim();
        this.event.event_data.event_push_yn = (this.push_yn == 'Y');

        if( this.mapSearchQuery != null && this.mapSearchQuery.trim().length > 0 && (this.event.event_data.location == null || this.event.event_data.location.length < 1) ) {
            if( this.event.event_data.location == null ) {
                this.event.event_data.location = [];
            }
            this.event.event_data.location.push({
                "geo": {
                    "latitude": "",
                    "longitude": ""
                },
                "tel": "",
                "place": this.mapSearchQuery.trim(),
                "address": "",
                "post_code": "",
                "address_old": ""
            });
        }

        try {
            let push_yn_preference = await this.get_user_preference("push_yn", false);

            if( push_yn_preference == null ) {
                push_yn_preference = { "user_id" : this.user_id, "cate" : "push_yn", "preference" : { "WEB" : { "report" : true, "meetinglog" : true, "work" : true, "basic" : true } }, "audit_modified" : new Date() };
            }

            let preference = push_yn_preference['preference'];

            if( preference == null ) {
                preference = { "WEB" : { "report" : true, "meetinglog" : true, "work" : true, "basic" : true } };
            }

            let web_push_yn_preference = preference['WEB'];

            if( web_push_yn_preference == null ) {
                web_push_yn_preference = { "report" : true, "meetinglog" : true, "work" : true, "basic" : true };
            }
            
            if( this.isReport(this.event.event_sub_type as EVENT_SUB_TYPE) ) {
                web_push_yn_preference['report'] = this.event.event_data.event_push_yn;
            }

            else if( this.isMeetingLog(this.event.event_sub_type as EVENT_SUB_TYPE) ) {
                web_push_yn_preference['meetinglog'] = this.event.event_data.event_push_yn;
            }

            else if( this.isWork(this.event.event_sub_type as EVENT_SUB_TYPE) ) {
                web_push_yn_preference['work'] = this.event.event_data.event_push_yn;
            }

            else if( this.isEvent(this.event.event_sub_type as EVENT_SUB_TYPE) ) {
                web_push_yn_preference['basic'] = this.event.event_data.event_push_yn;
            }

            preference['WEB'] = JSON.parse(JSON.stringify(web_push_yn_preference));

            this.set_user_preference('push_yn', preference, false);
            
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * DTSTART 제작 : 일정 시작일
     */
    formatDateForRruleDTSTART(date : Date) : string {
        return `${require('dateformat')(date, "UTC:yyyymmdd")}T${require('dateformat')(date, "UTC:HHMMss")}Z`;
    }

    /**
     * 이벤트 조회
     */
    async getEvent() : Promise<void> {
        const vue = this
        
        const user_id  : number = this.event.user_id  == null ? 0 : this.event.user_id;
        const group_id : number = this.event.group_id == null ? 0 : this.event.group_id;
        const team_id  : number = this.event.team_id  == null ? 0 : this.event.team_id;

        const scope    : string = user_id > 0 ? OWNER_TYPE.PERSONAL 
                                              : group_id > 0 ? OWNER_TYPE.GROUP : OWNER_TYPE.TEAM;

        const scope_id : number = user_id > 0 ? user_id 
                                              : group_id > 0 ? group_id : team_id;

        switch( this.event.event_sub_type ) {
            case EVENT_SUB_TYPE.SCHEDULE:
            case EVENT_SUB_TYPE.CARD:
            case EVENT_SUB_TYPE.REPORT:
            case EVENT_SUB_TYPE.MEETINGLOG:
                this.selectScheduleAndCard(scope, scope_id);
                break;

            case EVENT_SUB_TYPE.WORK:
                this.selectWork(scope, scope_id);
                break;
        }

    }

    /**
     * 일정 조회
     */
    async selectScheduleAndCard(scope : string, scope_id : number) : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/${this.event.event_id}`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);

                const response_data : any = response.data.data;
                vue.share_infos = response_data.event_share;
                
                const is_work = response_data.event_data.event_sub_type == EVENT_SUB_TYPE.WORK;
                const is_report = response_data.event_data.event_sub_type == EVENT_SUB_TYPE.REPORT 
                const is_meetinglog = response_data.event_data.event_sub_type == EVENT_SUB_TYPE.MEETINGLOG;

                // share_info로 공유 받은 일정이고 해당 캘린더의 삭제 권한 으로 deletable 업데이트
                const calendar_scope_id : number = this.scope == OWNER_TYPE.PERSONAL ? this.user_id : (this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id);
                for( let share of vue.share_infos ) {
                    if( share.share_scope == vue.scope && share.share_scope_id == calendar_scope_id  ) {
                        vue.share_info = share;

                        // 삭제 권한 업데이트
                        switch( share.share_scope ) {
                            case OWNER_TYPE.PERSONAL:
                                vue.is_deleteable = true;
                                break;

                            case OWNER_TYPE.GROUP:
                                vue.is_deleteable = await ((is_work ? vue.is_group_permmision(share.share_scope_id, 'work', 'delete') :
                                                            is_report ? vue.is_group_permmision(share.share_scope_id, 'report', 'delete') :
                                                            is_meetinglog ? vue.is_group_permmision(share.share_scope_id, 'meetinglog', 'delete') :  
                                                                            vue.is_group_permmision(share.share_scope_id, 'event', 'delete')) || vue.event.event_data.event_owner_id == vue.user_id);
                                break;
                            
                            case OWNER_TYPE.TEAM:
                                vue.is_deleteable = await ((is_work ? vue.is_team_permmision(share.share_scope_id, 'work', 'delete') : 
                                                            is_report ? vue.is_team_permmision(share.share_scope_id, 'report', 'delete') : 
                                                            is_meetinglog ? vue.is_team_permmision(share.share_scope_id, 'meetinglog', 'delete') : 
                                                                            vue.is_team_permmision(share.share_scope_id, 'event', 'delete')) || vue.event.event_data.event_owner_id == vue.user_id);
                                break;
                        }
                        this.is_shared = (share.share_type == 'SHARE'); // 공유 되어서 볼 수 있는 것인지 여부 등록
                        break;
                    }
                }

                // 보고있는 달력과는 다른 share_scope라면 첫번째 share 정보를 사용한다
                if( vue.share_info == null ) {
                    vue.share_info = vue.share_infos[0];

                    // 삭제 권한 업데이트
                    switch( vue.share_info.share_scope ) {
                        case OWNER_TYPE.PERSONAL:
                            vue.is_deleteable = true;
                            break;

                        case OWNER_TYPE.GROUP:
                            vue.is_deleteable = await ((is_work ? vue.is_group_permmision(vue.share_info.share_scope_id, 'work', 'delete') :
                                                        is_report ? vue.is_group_permmision(vue.share_info.share_scope_id, 'report', 'delete') :
                                                        is_meetinglog ? vue.is_group_permmision(vue.share_info.share_scope_id, 'meetinglog', 'delete') :  
                                                                        vue.is_group_permmision(vue.share_info.share_scope_id, 'event', 'delete')) || vue.event.event_data.event_owner_id == vue.user_id);
                            break;
                        
                        case OWNER_TYPE.TEAM:
                            vue.is_deleteable = await ((is_work ? vue.is_team_permmision(vue.share_info.share_scope_id, 'work', 'delete') : 
                                                        is_report ? vue.is_team_permmision(vue.share_info.share_scope_id, 'report', 'delete') : 
                                                        is_meetinglog ? vue.is_team_permmision(vue.share_info.share_scope_id, 'meetinglog', 'delete') : 
                                                                        vue.is_team_permmision(vue.share_info.share_scope_id, 'event', 'delete')) || vue.event.event_data.event_owner_id == vue.user_id);
                            break;
                    }
                }

                // 개인 설정 데이터를 제외하고 데이터 적용
                const new_event : t_event = response_data.event_data;
                new_event.event_data.schedule_date.start          = vue.event.event_data.schedule_date.start; 
                new_event.event_data.schedule_date.end            = vue.event.event_data.schedule_date.end; 
                new_event.event_data.schedule_date.recurrence_end = vue.event.event_data.schedule_date.recurrence_end; 

                // 노트가 아예 없다면 빈값이라도 넣어주도록 처리 undefined 라서 노트 입력이 아예 안됨
                new_event.event_data.note = new_event.event_data.note ? new_event.event_data.note : "";

                this.doSetEvent(new_event);

                if( this.event.event_data.schedule_date.lunar_yn == true ) {
                    this.day_repeat = false;
                    this.week_repeat = false;
                    this.month_repeat = false; 
                    this.year_repeat = true; 
                }
                
                // RGB값만 있다면 그대로 RGB를 사용, ARGB라면 ARGB를 RGB로 변환
                vue.event.event_data.color = vue.hodu_hex_color_process(vue.event.event_data.color);

                // 개인 설정 데이터가 있는 경우
                if( response_data.event_data_indi != null ) {
                        
                    // 노트
                    if( response_data.event_data_indi.note != null ) {
                        vue.event.event_data.note = response_data.event_data_indi.note;
                    }

                    // 알람
                    if( response_data.event_data_indi.alarm != null ) {
                        
                        // 알람이 없는 경우
                        if( vue.event.event_data.alarm == null ) {
                            vue.event.event_data.alarm = [];
                        }

                        vue.event.event_data.alarm.splice(0, vue.event.event_data.alarm.length);
                        vue.event.event_data.alarm = vue.event.event_data.alarm.concat(response_data.event_data_indi.alarm);
                    }

                    // 색상
                    if( response_data.event_data_indi.color != null ) {
                        vue.event.event_data.color = vue.hodu_hex_color_process(response_data.event_data_indi.color);
                    }

                }

                // 투표
                if( response_data.voted_item != null ) {
                    vue.vote_result = response_data.voted_item;
                }

                // 참석 데이터 있을때
                if( response_data.attend_data != null ) {
                    vue.attend_data = response_data.attend_data;
                }

                // 참석 데이터 없을때
                else {
                    vue.attend_data = null;
                }

                // 참석, 투표 가능 여부
                if( response_data.is_attendable != null ) {
                    vue.is_attendable = response_data.is_attendable;
                }

                // 댓글 존재 여부
                if( response_data.is_exist_reply != null ) {
                    vue.is_exist_reply = response_data.is_exist_reply;
                }

                // 참석&투표 참여 가능한 사람 수
                if( response_data.attendable_user_count != null ) {
                    vue.attendable_user_count = response_data.attendable_user_count;
                }

                // 참석 여부 참여 한 사람 수
                if( response_data.attend_user_count != null ) {
                    vue.attend_user_count = response_data.attend_user_count;
                }

                // 투표 참여 한 사람 수 배열
                if( response_data.vote_user_count != null ) {
                    vue.vote_user_count.splice(0, vue.vote_user_count.length);
                    vue.vote_user_count = vue.vote_user_count.concat(response_data.vote_user_count);
                }

                if( response_data.read_info != null ) {
                    vue.read_info.splice(0, vue.read_info.length);
                    vue.read_info = vue.read_info.concat(response_data.read_info);
                }

                this.is_premium_event = response_data.is_premium_event;

                this.date_term = this.getDateDiff(this.event.event_data.schedule_date.start, this.event.event_data.schedule_date.end);

                await this.permissionCheck();

                // 업무일지, 회의록은 반복일정 없으므로 0
                if( this.event.event_sub_type == EVENT_SUB_TYPE.MEETINGLOG || this.event.event_sub_type == EVENT_SUB_TYPE.REPORT ) {
                    this.repeat_seq = 0;
                }
                else {

                    // 음력을 제외한 반복일정
                    if( this.event.event_data.schedule_date.rrule != null && this.event.event_data.schedule_date.rrule.length > 0 && this.event.event_data.schedule_date.lunar_yn == false ) {
                        
                        const rrule = this.event.event_data.schedule_date.rrule;
                        const start = this.event.event_data.schedule_date.start;
                        const original_start = this.event_original_date.original_start;
                        const recurrence_end = this.event.event_data.schedule_date.recurrence_end;

                        const rrule_str = `DTSTART:${moment(original_start).utc().format('YYYYMMDDTHHmmss')}Z\nRRULE:${rrule};UNTIL=${moment(recurrence_end).utc().format('YYYYMMDDTHHmmss')}Z`;

                        const rrule_obj : RRule | RRuleSet = await rrulestr(rrule_str);

                        const repeat_dates = rrule_obj.all();

                        const start_yyyymmdd = moment(start).format('YYYYMMDD');
                        for( const repeat_date of repeat_dates ) {
                            const repeat_date_yyyymmdd = moment(repeat_date).format('YYYYMMDD');
                            if( start_yyyymmdd == repeat_date_yyyymmdd ) {
                                this.repeat_seq = repeat_dates.indexOf(repeat_date);
                                break;
                            }
                        }
                    }

                    // 음력 반복 일정인 경우
                    if( this.event.event_data.schedule_date.rrule != null && this.event.event_data.schedule_date.rrule.length > 0 && this.event.event_data.schedule_date.lunar_yn == true ) {
                        const start = this.event.event_data.schedule_date.start;
                        const original_start = this.event_original_date.original_start;

                        const lunar_start = await this.hodu_solar_to_lunar(start);
                        const lunar_original_start = await this.hodu_solar_to_lunar(original_start);

                        this.repeat_seq = (Number(lunar_start.replaceAll('-', '')) - Number(lunar_original_start.replaceAll('-', ''))) / 10000;
                    }

                }

                // 일정 읽기 처리
                if( this.event.event_data.event_owner_id != this.user_id ) {
                    await this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/read/${this.event.event_id}/${this.repeat_seq}`, API_METHOD.POST, null, false);
                }
                
            }).catch(async(e) => {
                this.hodu_error_process(e, true, true);
            });
    }

    /**
     * 업무 조회
     */
    async selectWork(scope : string, scope_id : number) : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/calendars/${ this.event.calendar_id }/works/${ scope }/${ scope_id }/${ this.event.event_id }`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);

                const response_data : any = response.data.data;

                // 노트가 아예 없다면 빈값이라도 넣어주도록 처리 undefined 라서 노트 입력이 아예 안됨
                response_data.event_data.event_data.note = response_data.event_data.event_data.note ? response_data.event_data.event_data.note : "";

                this.doSetEvent(response_data.event_data);
                vue.share_infos = response_data.event_share;

                // share_info로 공유 받은 일정이고 해당 캘린더의 삭제 권한 으로 deletable 업데이트
                const calendar_scope_id : number = this.scope == OWNER_TYPE.PERSONAL ? this.user_id : (this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id);
                for( let share of vue.share_infos ) {
                    if( share.share_scope == vue.scope && share.share_scope_id == calendar_scope_id  ) {
                        vue.share_info = share;

                        // 삭제 권한 업데이트
                        switch( share.share_scope ) {
                            case OWNER_TYPE.PERSONAL:
                                vue.is_deleteable = true;
                                if( share.share_type == 'SHARE' ) {
                                    vue.is_editable = false;
                                    vue.group_color = 'transparent';
                                }
                                break;

                            case OWNER_TYPE.GROUP:
                                vue.is_deleteable = await vue.is_group_permmision(share.share_scope_id, 'work', 'delete') || vue.event.event_data.event_owner_id == vue.user_id;
                                break;
                            
                            case OWNER_TYPE.TEAM:
                                vue.is_deleteable = await vue.is_team_permmision(share.share_scope_id, 'work', 'delete') || vue.event.event_data.event_owner_id == vue.user_id;
                                break;
                        }
                        this.is_shared = (share.share_type == 'SHARE'); // 공유 되어서 볼 수 있는 것인지 여부 등록
                        break;
                    }
                }

                // 보고있는 달력과는 다른 share_scope라면 첫번째 share 정보를 사용한다
                if( vue.share_info == null ) {
                    vue.share_info = vue.share_infos[0];

                    // 삭제 권한 업데이트
                    switch( vue.share_info.share_scope ) {
                        case OWNER_TYPE.PERSONAL:
                            vue.is_deleteable = true;
                            break;

                        case OWNER_TYPE.GROUP:
                            vue.is_deleteable = await vue.is_group_permmision(vue.share_info.share_scope_id, 'work', 'delete') || vue.event.event_data.event_owner_id == vue.user_id;
                            break;
                        
                        case OWNER_TYPE.TEAM:
                            vue.is_deleteable = await vue.is_team_permmision(vue.share_info.share_scope_id, 'work', 'delete') || vue.event.event_data.event_owner_id == vue.user_id;
                            break;
                    }
                }
                
                // RGB값만 있다면 그대로 RGB를 사용, ARGB라면 ARGB를 RGB로 변환
                vue.event.event_data.color = vue.hodu_hex_color_process(vue.event.event_data.color ? vue.event.event_data.color : "#477FFF");
                
                // 개인 설정 데이터가 있는 경우
                if( response_data.event_data_indi != null ) {
                        
                    // 노트
                    if( response_data.event_data_indi.note != null ) {
                        vue.event.event_data.note = response_data.event_data_indi.note;
                    }

                    // 알람
                    if( response_data.event_data_indi.alarm != null ) {
                        
                        // 알람이 없는 경우
                        if( vue.event.event_data.alarm == null ) {
                            vue.event.event_data.alarm = [];
                        }

                        vue.event.event_data.alarm.splice(0, vue.event.event_data.alarm.length);
                        vue.event.event_data.alarm = vue.event.event_data.alarm.concat(response_data.event_data_indi.alarm);
                    }

                    // 색상
                    if( response_data.event_data_indi.color != null ) {
                        vue.event.event_data.color = vue.hodu_hex_color_process(response_data.event_data_indi.color);
                    }

                }

                // 댓글 존재 여부
                if( response_data.is_exist_reply != null ) {
                    vue.is_exist_reply = response_data.is_exist_reply;
                }

                // 업무 관련 데이터
                // target_users - 업무 대상이 될수있는 그룹원or팀원 리스트
                if( response_data.target_users != null ) {
                    vue.target_user_list.splice(0, vue.target_user_list.length);
                    vue.target_user_list = vue.target_user_list.concat(response_data.target_users);

                    vue.all_member_count = vue.target_user_list.length;
                }

                // work_template - 업무 템플릿
                if( response_data.work_template != null ) {
                    vue.selected_work_template = response_data.work_template; 
                }

                // work_status - 업무자별 업무 상태
                vue.work_status_list.splice(0, vue.work_status_list.length);
                vue.user_work_status = null;
                if( response_data.work_status != null ) {
                    vue.work_status_list = vue.work_status_list.concat(response_data.work_status);

                    // assignment_user에 채우기
                    vue.assignment_user_list.splice(0, vue.assignment_user_list.length);
                    const work_status_list_length : number = vue.work_status_list.length;
                    for( let i = 0; i < work_status_list_length; i++ ) {
                        vue.assignment_user_list.push(vue.work_status_list[i].user_id);

                        // 유저가 업무자라면 업무진행상태를 저장한다
                        if( vue.work_status_list[i].user_id == vue.user_id ) {
                            vue.user_work_status = vue.work_status_list[i];
                        }
                    }
                }
                
                this.date_term = this.getDateDiff(this.event.event_data.schedule_date.start, this.event.event_data.schedule_date.end);

                // 반복 프로젝트는 없기 때문에 무조건 0
                this.repeat_seq = 0;

                if( this.event.event_data.event_owner_id != this.user_id ) {
                    await this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/read/${this.event.event_id}/${this.repeat_seq}`, API_METHOD.POST, null, false);
                } 
            })
            .catch((e) => {
                this.hodu_error_process(e, true, true);
            });
    }

    /**
     * 일정 권한 체크
     */
    async permissionCheck() : Promise<void> {

        if( this.user_id == null || this.event == null || this.event.user_id == null || this.event.group_id == null || this.event.team_id == null ) {
            return;
        }

        const is_work = this.event.event_sub_type == EVENT_SUB_TYPE.WORK;
        const is_report = this.event.event_sub_type == EVENT_SUB_TYPE.REPORT 
        const is_meetinglog = this.event.event_sub_type == EVENT_SUB_TYPE.MEETINGLOG;

        // 일정 생성 권한 체크
        this.is_createable = this.event.user_id  > 0 ? true :
                             this.event.group_id > 0 ? (is_work ? (this.is_group_permmision(this.event.group_id, 'work' , 'create')) : 
                                                        is_report ? (this.is_group_permmision(this.event.group_id, 'report', 'create')) :
                                                        is_meetinglog ? (this.is_group_permmision(this.event.group_id, 'meetinglog', 'create')) :
                                                                        (this.is_group_permmision(this.event.group_id, 'event', 'create')) ):
                             this.event.team_id  > 0 ? (is_work ? (this.is_team_permmision(this.event.team_id  , 'work' , 'create')) : 
                                                        is_report ? (this.is_team_permmision(this.event.team_id  , 'report' , 'create')) :
                                                        is_meetinglog ? (this.is_team_permmision(this.event.team_id  , 'meetinglog' , 'create')) :
                                                                        (this.is_team_permmision(this.event.team_id  , 'event', 'create')) ): false;

        this.is_copyable = this.scope == OWNER_TYPE.PERSONAL || 
                            ( this.scope != OWNER_TYPE.PERSONAL && this.scope_team_id > 0 ? this.is_team_permmision((this.event.is_temp == true ? this.event.scope_team_id : this.scope_team_id), 'event', 'create') 
                                                                                          : this.is_group_permmision((this.event.is_temp == true ? this.event.scope_group_id : this.scope_group_id), 'event', 'create') );
        

        // 작성자는 일정 수정, 삭제 권한이 무조건 존재한다
        if ( this.user_id == this.event.event_data.event_owner_id ) {
            this.is_editable   = true;
            this.is_deleteable = true;
        }

        // 작성자가 아니라면 수정, 삭제 권한 체크
        else {
            this.is_editable   = this.event.group_id > 0 ? (is_work ? (this.is_group_permmision(this.event.group_id, 'work' , 'modify')) : 
                                                            is_report ? (this.is_group_permmision(this.event.group_id, 'report' , 'modify')) : 
                                                            is_meetinglog ? (this.is_group_permmision(this.event.group_id, 'meetinglog' , 'modify')) : 
                                                                            (this.is_group_permmision(this.event.group_id, 'event', 'modify')) ): 
                                this.event.team_id  > 0 ? (is_work ? (this.is_team_permmision(this.event.team_id, 'work' , 'modify')) :
                                                           is_report ? (this.is_team_permmision(this.event.team_id, 'report' , 'modify')) : 
                                                           is_meetinglog ? (this.is_team_permmision(this.event.team_id, 'meetinglog' , 'modify')) :  
                                                                           (this.is_team_permmision(this.event.team_id, 'event', 'modify')) ): false;

            this.is_deleteable = this.event.group_id > 0 ? (is_work ? (this.is_group_permmision(this.event.group_id, 'work' , 'delete')) : 
                                                            is_report ? (this.is_group_permmision(this.event.group_id, 'report' , 'delete')) : 
                                                            is_meetinglog ? (this.is_group_permmision(this.event.group_id, 'meetinglog' , 'delete')) :         
                                                                      (this.is_group_permmision(this.event.group_id, 'event', 'delete')) || (this.event.subscribe_users != null && this.event.subscribe_users.indexOf(this.user_id) > -1 )) : 
                                this.event.team_id  > 0 ? (is_work ? (this.is_team_permmision(this.event.team_id, 'work' , 'delete')) :
                                                           is_report ? (this.is_group_permmision(this.event.group_id, 'report' , 'delete')) : 
                                                           is_meetinglog ? (this.is_group_permmision(this.event.group_id, 'meetinglog' , 'delete')) :  
                                                                           (this.is_team_permmision(this.event.team_id, 'event', 'delete')) || (this.event.subscribe_users != null && this.event.subscribe_users.indexOf(this.user_id) > -1 )) :
                                this.event.subscribe_users != null && this.event.subscribe_users.indexOf(this.user_id) > -1 ? true : false;
        }

        // 삭제 권한 업데이트
        if( this.share_info != null ) {
            switch( this.share_info.share_scope ) {
                case OWNER_TYPE.PERSONAL:
                    this.is_deleteable = true;
                    if( this.share_info.share_type == 'SHARE' ) {
                        this.is_editable = false;
                        this.group_color = 'transparent';
                    }
                    break;

                case OWNER_TYPE.GROUP:
                    this.is_deleteable = await ((is_work ? this.is_group_permmision(this.share_info.share_scope_id, 'work', 'delete') :
                                                 is_report ? this.is_group_permmision(this.share_info.share_scope_id, 'report', 'delete') : 
                                                 is_meetinglog ? this.is_group_permmision(this.share_info.share_scope_id, 'meetinglog', 'delete') : 
                                                                 this.is_group_permmision(this.share_info.share_scope_id, 'event', 'delete')) || this.event.event_data.event_owner_id == this.user_id || (this.event.subscribe_users != null && this.event.subscribe_users.indexOf(this.user_id) > -1 ));
                    break;
                
                case OWNER_TYPE.TEAM:
                    this.is_deleteable = await ((is_work ? this.is_team_permmision(this.share_info.share_scope_id, 'work', 'delete') :
                                                 is_report ? this.is_team_permmision(this.share_info.share_scope_id, 'report', 'delete') : 
                                                 is_meetinglog ? this.is_team_permmision(this.share_info.share_scope_id, 'meetinglog', 'delete') : 
                                                                 this.is_team_permmision(this.share_info.share_scope_id, 'event', 'delete')) || this.event.event_data.event_owner_id == this.user_id || (this.event.subscribe_users != null && this.event.subscribe_users.indexOf(this.user_id) > -1 ))
                    break;
            }
        }

        // 일정의 경우 그룹, 팀 일정은 작성자여도 이전 일정 수정, 삭제가 불가능한 경우 과거 일정을 삭제할수 없다
        if( (this.event.event_sub_type == EVENT_SUB_TYPE.SCHEDULE || this.event.event_sub_type == EVENT_SUB_TYPE.CARD) && this.event.event_type != OWNER_TYPE.PERSONAL ) {
            
            // 과거 일정 수정 금지, 과거 일정 삭제 금지 권한
            let is_modify_prev = false;
            let is_delete_prev = false;

            switch( this.event.event_type ) {
                case OWNER_TYPE.GROUP:
                    is_modify_prev = this.is_group_permmision(this.event.group_id, 'event', 'modify_prev');
                    is_delete_prev = this.is_group_permmision(this.event.group_id, 'event', 'delete_prev');
                    break;

                case OWNER_TYPE.TEAM:
                    is_modify_prev = this.is_team_permmision(this.event.team_id, 'event', 'modify_prev');
                    is_delete_prev = this.is_team_permmision(this.event.team_id, 'event', 'delete_prev');
                    break;
            }

            if( is_modify_prev || is_delete_prev ) {
                const current : Date = new Date();
                const created : Date = new Date(this.event.audit_created ? this.event.audit_created : 0);
                const end     : Date = new Date(this.event.event_data.schedule_date.recurrence_end);

                // 시간제한에 걸리는지 여부 (작성일 또는 종료일로부터 120시간 이내)
                const LIMIT_TIME : number = 5 * 24 * 60 * 60 * 1000;

                const is_unlimited : boolean = current.getTime() <= (created.getTime() + LIMIT_TIME) || current.getTime() <= (end.getTime() + LIMIT_TIME);

                // 과거 일정 편집 금지
                if( is_modify_prev == true && is_unlimited == false ) {
                    this.is_editable = false;
                }

                // 과거 일정 삭제 금지
                if( is_delete_prev == true && is_unlimited == false ) {
                    this.is_deleteable = false;
                }

            }
            
        }
        
    }

    /**
     * 일정 개인 데이터 업데이트 (컬러, 알람, 노트)
     */
    async updateEventIndividual() : Promise<void> {
        if( this.share_info == null ) { return; }
        
        const vue = this;

        let scope : string = this.share_info.share_scope;
        let scope_id : number = this.share_info.share_scope_id;

        this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/${this.event.event_id}/indi`, API_METHOD.PUT, {
            note : this.event.event_data.note == null ? '' : this.event.event_data.note.trim(),
            color : this.event.event_data.color,
            alarm : this.event.event_data.alarm
        }, false).then(async(response) => {
            console.log(response);
            local_storage_info.last_event_created_color = this.event.event_data.color ?? '#477FFF';
            hodu_local_storage.setItem(`${this.user_id}`, JSON.stringify(local_storage_info)); 
        }).catch(async(e) => {
            this.hodu_error_process(e, true, false);
        });
    }

    /**
     * 일정 수정 모드로 변경
     */
    async modifyEvent() : Promise<void> {
        // 음력을 제외한 반복일정
        if( this.event.event_data.schedule_date.rrule != null && this.event.event_data.schedule_date.rrule.length > 0 && this.event.event_data.schedule_date.lunar_yn == false ) {
            this.doSetRepeatModifyAction("수정");
            this.doSetShowEventRepeatModify(true);
            return;
        }

        // 음력 반복 일정인 경우
        if( this.event.event_data.schedule_date.rrule != null && this.event.event_data.schedule_date.rrule.length > 0 && this.event.event_data.schedule_date.lunar_yn == true ) {
            this.event.event_data.schedule_date.start = this.event_original_date.original_start;
            this.event.event_data.schedule_date.end   = this.event_original_date.original_end;
            await this.makeDateString();
        }

        await this.doSetEventCrudType(CRUD_TYPE.UPDATE);
        await this.setDatePicker();

        // @ts-ignore alarmDivScroll 스크롤
        $('.alarmDivScroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 200,
            autoDraggerLength : false,
        });
    }

    /**
     * 일정 삭제
     */
    async deleteEvent() : Promise<void> {

        if( this.event.event_data.schedule_date.rrule != null && this.event.event_data.schedule_date.rrule.length > 0 && this.event.event_data.schedule_date.lunar_yn == false ) {
            this.doSetRepeatModifyAction("삭제");
            this.doSetShowEventRepeatModify(true);
            return;
        }

        await this.deleteEventModalCall(this.deleteEventAPICall);
    }

    /**
     * 일정 삭제 Modal 
     */
    async deleteEventModalCall(func : Function) : Promise<void> {
        
        this.hodu_show_dialog("cancel", "일정을 삭제하시겠습니까?", ['아니오', '예'], [
            () => {},
            func
        ]);
    }

    /**
     * 일정 삭제 API 
     */
    async deleteEventAPICall() : Promise<void> {
        const vue = this;

        let calendar_id : string = "";
        let scope : string = "";
        let scope_id : number = 0;

        const api_promise_array : Promise<void>[] = [];

        // share_info가 없다면 빠져나가기
        if( this.share_infos == null || this.share_info == null ) { return; }

        const is_work = this.event.event_sub_type == EVENT_SUB_TYPE.WORK;
        const is_report = this.event.event_sub_type == EVENT_SUB_TYPE.REPORT 
        const is_meetinglog = this.event.event_sub_type == EVENT_SUB_TYPE.MEETINGLOG;
        
        const share_infos_count : number = this.share_infos.length;
        for( let i = 0; i < share_infos_count; i++ ) {
            const delete_share_info : any = this.share_infos[i];
            let is_share_deleteable : boolean = false;

            scope = delete_share_info.share_scope;
            scope_id = delete_share_info.share_scope_id;

            /**
             * 개인 일정 삭제
             */
            if( delete_share_info.share_scope == OWNER_TYPE.PERSONAL ) {
                is_share_deleteable = true;
                calendar_id = `personal-${delete_share_info.share_scope_id}`;
            }

            /**
             * 그룹 일정 삭제
             */
            else if( delete_share_info.share_scope == OWNER_TYPE.GROUP ) {
                is_share_deleteable = await ((is_work ? this.is_group_permmision(delete_share_info.share_scope_id, "work", "delete") : 
                                              is_report ? this.is_group_permmision(delete_share_info.share_scope_id, "report", "delete") : 
                                              is_meetinglog ? this.is_group_permmision(delete_share_info.share_scope_id, "meetinglog", "delete") : 
                                                              this.is_group_permmision(delete_share_info.share_scope_id, "event", "delete")) || this.event.event_data.event_owner_id == this.user_id);
                calendar_id = `group-${delete_share_info.share_scope_id}`;
            }

            /**
             * 팀 일정 삭제
             */
            else if( delete_share_info.share_scope == OWNER_TYPE.TEAM ) {
                is_share_deleteable = await ((is_work ? this.is_team_permmision(delete_share_info.share_scope_id, "work", "delete") :
                                              is_report ? this.is_team_permmision(delete_share_info.share_scope_id, "report", "delete") : 
                                              is_meetinglog ? this.is_team_permmision(delete_share_info.share_scope_id, "meetinglog", "delete") : 
                                                              this.is_team_permmision(delete_share_info.share_scope_id, "event", "delete")) || this.event.event_data.event_owner_id == this.user_id);
                calendar_id = `group-${delete_share_info.share_scope_id}`;
            }

            /**
             * 그 외의 경우는 없으므로 넘어간다
             */
            else { continue; }

            // 해당 스코프에 삭제권한이 없으면 넘어간다
            if( is_share_deleteable == false ) { continue; }

            api_promise_array.push(this.deleteEventApiCallPromise(calendar_id, scope, scope_id));
        }

        // 삭제 API 병렬 처리
        Promise.all(api_promise_array).then(() => { vue.movePrevPage(); });
    }
    
    /**
     * 일정 삭제 API 호출 
     */
    async deleteEventApiCallPromise(calendar_id : string, scope : string, scope_id : number) : Promise<void> {
        const url : string = `api/v1/calendars/${ calendar_id }/events/${ scope }/${ scope_id }/${ this.event.event_id }?push_yn=true`;

        await this.hodu_api_call(url, API_METHOD.DELETE)
            .then(async(response) => { console.log(response); })
            .catch(async(e) => { this.hodu_error_process(e, true, false); });
    }

    /**
     * 일정 복사 Modal
     */
    async copyEventModalCall() : Promise<void> {

        this.hodu_show_dialog("alert", "일정을 복사하시겠습니까?", ['아니오', '예'], [
            () => {},
            this.copyEvent
        ]);

    }

    /**
     * 일정 복사
     */
    async copyEvent() : Promise<void> {
        await this.downloadImageAndFileForCopy();
        await this.doSetEventCrudType(CRUD_TYPE.CREATE);
        await this.setDatePicker();

        // 날짜 및 시간 세팅
        const target_date : Date = new Date();
        const start_date  : Date = new Date(target_date.getTime());
        const end_date    : Date = new Date(target_date.getTime());

        // 시작 시간이 '정시' 거나 '23시' 일 경우는 그대로 사용한다 
        if( target_date.getMinutes() != 0 && target_date.getHours() != 23 ){
           start_date.setHours(target_date.getHours() + 1);
        }
        
        start_date.setMinutes(0);
        start_date.setSeconds(0);
        start_date.setMilliseconds(0);

        // 시작시간이 23시라면 23시 50분 고정, 아니라면 시작시간 + 1시간에 0분
        if( start_date.getHours() == 23 ){
            end_date.setHours(23);
            end_date.setMinutes(50);
        } else {
            end_date.setHours(start_date.getHours() + 1);
            end_date.setMinutes(0);
        }

        end_date.setSeconds(0);
        end_date.setMilliseconds(0);

        this.event.event_data.schedule_date.start = start_date;
        this.event.event_data.schedule_date.end   = end_date;
        if( this.event.event_data.schedule_date.rrule == null ) {
            this.event.event_data.schedule_date.recurrence_end = end_date;
        }
        this.date_term = 0;

        // 음력
        if( this.event.event_data.schedule_date.lunar_yn == true ) {
            const lunar_date : string = await this.hodu_solar_to_lunar(this.event.event_data.schedule_date.start);

            this.event.event_data.schedule_date.lunar_start = lunar_date;
            this.event.event_data.schedule_date.lunar_end   = lunar_date;
        }

        this.makeDateString();
        this.makeStringByRrule();

        // 공유 정보 초기화
        if( this.event.subscribe_users != null ) {
            this.event.subscribe_users.splice(0, this.event.subscribe_users.length); 
        }

        if( this.event.subscribe_groups != null ) {
            this.event.subscribe_groups.splice(0, this.event.subscribe_groups.length); 
        }

        if( this.event.subscribe_teams != null ) {
            this.event.subscribe_teams.splice(0, this.event.subscribe_teams.length); 
        }

        // 본인의 개인 일정이라면 그대로 리턴
        if( this.event.event_type == OWNER_TYPE.PERSONAL && this.event.event_data.event_owner_id == this.user_id ) {
            this.doSetEvent(this.event);
            return;
        }

        // 작성자 정보 사용자로 등록
        this.event.event_data.event_owner_id   = this.user_id; 
        this.event.event_data.event_owner_name = this.user_name;

        // 복사 가능 여부 체크 (해당 그룹, 팀에 가입 되었는지, 생성권한이 있는지 한번에 체크 됨)
        if( ( this.is_createable == false && this.scope != OWNER_TYPE.PERSONAL && this.is_copyable == true ) || this.is_createable == true ) {
            
            // 그룹, 팀 달력인데 해당 일정이 공유 받은 일정인 경우
            if( this.is_createable == false && this.scope != OWNER_TYPE.PERSONAL && this.is_copyable == true ) {
                if( this.scope_team_id > 0 ) {
                    this.event.event_type = OWNER_TYPE.TEAM;
                    // this.share_info.share_scope = 'TEAM';
                    // this.share_info.share_scope_id = this.scope_team_id;
                    this.event.user_id = 0;
                    this.event.group_id = 0;
                    this.event.team_id = this.scope_team_id;
                    this.event.event_data.event_owner_group_name = "";
                    this.event.event_data.event_owner_group_id = 0;
                    this.event.event_data.event_owner_team_id = this.scope_team_id;
                    this.event.event_data.event_owner_team_name = this.scope_group_team_option.group_team_name;
                } else {
                    this.event.event_type = OWNER_TYPE.GROUP;
                    // this.share_info.share_scope = 'GROUP';
                    // this.share_info.share_scope_id = this.scope_group_id;
                    this.event.user_id = 0;
                    this.event.group_id = this.scope_group_id;
                    this.event.team_id = 0;
                    this.event.event_data.event_owner_group_name = this.scope_group_team_option.group_team_name;
                    this.event.event_data.event_owner_group_id = 0;
                    this.event.event_data.event_owner_team_id = 0;
                    this.event.event_data.event_owner_team_name = "";
                }

                if( this.share_info == null || this.share_info.share_scope != OWNER_TYPE.PERSONAL || (this.is_shared == false && this.share_info.share_scope == OWNER_TYPE.PERSONAL) ) {
                    this.group_color = this.scope_group_team_option.group_team_color;
                }

                // this.share_info.share_type = 'OWNER';
                // this.share_info.share_created = new Date();
                // this.share_info.share_user_id = this.user_id;
                // this.share_info.share_scope_name = this.scope_group_team_option.group_team_name;
            }

            this.doSetEvent(this.event);
            return;
        }

        this.is_createable = true;

        // 리턴되지 않았다면 가입된 그룹이 아니므로 개인일정으로 세팅한다
        this.event.calendar_id = `personal-${this.user_id}`;
        this.event.event_type  = OWNER_TYPE.PERSONAL;
        
        this.event.user_id  = this.user_id;
        this.event.group_id = 0;
        this.event.team_id  = 0;

        this.event.event_data.event_owner_group_id   = 0;
        this.event.event_data.event_owner_group_name = "";
        this.event.event_data.event_owner_team_id    = 0;
        this.event.event_data.event_owner_team_name  = "";

        // 복사된 이후 최초값을 결과를 저장
        this.doSetEvent(this.event);
    }

    /**
     * 이 일정만 수정, 이후 일정 수정, 일정 복사를 위해서 등록된 이미지, 파일을 다운로드 받는다
     */
    async downloadImageAndFileForCopy() : Promise<void> {
        const vue = this;
        
        if( this.event.event_data.attachment == null ) {
            return;
        }

        this.hodu_show_indicator();

        /**
         * 이미지 복사
         */
        if( this.event.event_data.attachment.imgs != null ) {
            const image_count : number = this.event.event_data.attachment.imgs.length;
            for( let i = 0; i < image_count; i++ ) {
                const file : t_event_file = this.event.event_data.attachment.imgs[i];

                await this.$http({
                    url : `/app_images/${file.url}`,
                    method : 'GET',
                    responseType: 'blob'
                }).then(async(response) => {
                    console.log(response);
                    const blob : Blob = new Blob([response.data]);
                    const copy_file : File = new File([blob], file.name, { type: blob.type, lastModified : new Date().getTime()});

                    vue.image_files.push({
                        index : ( vue.image_files.length ), 
                        file : copy_file
                    });

                }).catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
            }
        }

        /**
         * 파일 복사
         */
        if( this.event.event_data.attachment.files != null ) {
            const file_count : number = this.event.event_data.attachment.files.length;
            for( let i = 0; i < file_count; i++ ) {
                const file : t_event_file = this.event.event_data.attachment.files[i];

                await this.$http({
                    url : `/app_images/${file.url}`,
                    method : 'GET',
                    responseType: 'blob'
                }).then(async(response) => {
                    console.log(response);
                    const blob : Blob = new Blob([response.data]);
                    const copy_file : File = new File([blob], file.name, { type: blob.type, lastModified : new Date().getTime()});

                    vue.all_files.push({
                        index : ( vue.all_files.length ), 
                        file : copy_file
                    });

                }).catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
            }
        }
        
        this.hodu_hide_indicator();

    }
    
    /**
     * 업무 템플릿창 ON / OFF
     */
    workTemplateOnOff() : void {
        this.show_work_template = !this.show_work_template;
        this.alarm_list_open    = false;
        this.time_from_open     = false;
        this.time_to_open       = false;
    }
    
    /**
     * 업무 템플릿 타입 변경
     */
    changeWorkType(type : string) : void {
        this.view_template_type = type;
    }

    /**
     * 업무 템플릿 생성 페이지로 이동
     */
    workTemplateAdd() : void {
        this.doSetEvent(this.event);
        this.doSetWorkTemplateId("");
        this.hodu_router_push(`/work/${ new Date().getTime() }/setting/template`);
    }

    /**
     * 기본 업무 선택
     */
    // selectDefaultWork() : void {
        // if( this.event.event_data.work == null ) {
        //     return;
        // }

        // this.event.event_data.work.template_id = "";
        // this.event.event_data.work.template_type = "DEFAULT";
        // this.selected_work_template = null;
        // this.show_work_template = false;
    // }

    /**
     * 업무 템플릿 선택
     */
    selectWorkTemplate(template : t_work_template) : void {
        if( this.event.event_data.work == null ) {
            return;
        }

        this.event.event_data.work.template_id = template.template_id;
        this.event.event_data.work.template_type = template.template_type;
        this.selected_work_template = template;
        this.show_work_template = false;          
    }

    /**
     * 업무 템플릿 조회
     */
    async getWorkTepmplate() : Promise<void> {
        const vue = this;

        let target_calendar_id = (this.event.is_temp != true ? this.calendar_id : ((this.event.scope_team_id ?? 0) > 0 ? `team-${this.event.scope_team_id}` : `group-${this.event.scope_group_id}`));
        let target_scope = (this.event.is_temp != true ? this.scope : ((this.event.scope_team_id ?? 0) > 0 ? OWNER_TYPE.TEAM : OWNER_TYPE.GROUP));
        
        await this.hodu_api_call(`api/v1/calendars/${ target_calendar_id }/works/${ target_scope }/${ (this.event.is_temp == true ? (this.event.scope_team_id ?? 0) : this.scope_team_id) > 0 ? 
                                                                                                  (this.event.is_temp == true ? this.event.scope_team_id : this.scope_team_id) : 
                                                                                                  (this.event.is_temp == true ? this.event.scope_group_id : this.scope_group_id) }/template`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                vue.work_template_list.splice(0, vue.work_template_list.length);
                vue.flow_template_list.splice(0, vue.flow_template_list.length);
                vue.check_template_list.splice(0, vue.check_template_list.length);

                vue.work_template_list = vue.work_template_list.concat(response.data.data.template_list);

                // 업무 템플릿이 없다면 무조건 생성해야함
                if( vue.work_template_list.length < 1 ) {
                    this.hodu_show_dialog("alert", "프로젝트를 생성하려면 프로젝트 템플릿이 필요합니다\n프로젝트 템플릿을 생성 하시겠습니까?", ["아니오", "예"], [
                        () => { this.movePrevPage(); },
                        () => { this.workTemplateAdd(); }
                    ]);
                    return;
                }

                // 마지막에 선택한 업무
                if( response.data.data.last_select_template != null && this.event.event_data.work != null ) { 
                    this.selected_work_template = response.data.data.last_select_template;

                    if( this.selected_work_template != null ) {
                        this.event.event_data.work.template_id = this.selected_work_template.template_id;
                        this.event.event_data.work.template_type = this.selected_work_template.template_type;
                    }
                }
                
                // 마지막에 선택한 업무가 없다면 첫번째
                else {
                    this.selectWorkTemplate(vue.work_template_list[0]);
                }

                let selected_work_index : number = -1;
                const work_template_list_count : number = vue.work_template_list.length;
                for( let i = 0; i < work_template_list_count; i++ ) {
                    if( vue.work_template_list[i].template_type == 'FLOW' ) { vue.flow_template_list.push(vue.work_template_list[i]); } 
                    else { vue.check_template_list.push(vue.work_template_list[i]); }
                }


            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 업무 배정 타입 선택
     */
    workAssignmentChange(event) : void {
        const vue = this;

        if( this.event.event_data.work == null ) {
            return;
        }

        // 업무자 배정이라면 선택하는 창을 띄우고 선택한 결과를 체크 하도록 함 
        if( event.target.value == 'ASSIGN' ) {
            event.target.value = this.event.event_data.work.assignment_type;
            this.workAssign();
            return;
        }

        // 모두라면 바로 적용후 정보를 가져온다
        this.event.event_data.work.assignment_type = event.target.value;
        vue.assignment_user_list.splice(0, vue.assignment_user_list.length);
        vue.getAllAssignmentUser();
    }

    /**
     * 업무 배정 모두 선택 했을때 대상 유저 구하는 API
     */
    async getAllAssignmentUser() : Promise<void> {
        const vue = this; 

        let target_scope = (this.event.is_temp != true ? this.scope : ((this.event.scope_team_id ?? 0) > 0 ? OWNER_TYPE.TEAM : OWNER_TYPE.GROUP));

        await this.hodu_api_call(`api/v1/${ target_scope == OWNER_TYPE.GROUP ? 'groups' : 'teams' }/${ target_scope == OWNER_TYPE.GROUP ? 'getGroupMember' : 'getTeamMember' }/${ 
            (this.event.is_temp == true ? this.event.scope_group_id : this.scope_group_id) 
        }${ (this.event.is_temp == true ? (this.event.scope_team_id ?? 0) : this.scope_team_id) > 0 ? `/${ (this.event.is_temp == true ? this.event.scope_team_id : this.scope_team_id) }` : ''
        }`, API_METHOD.GET)
                .then((response) => {
                    console.log(response);
                    const user_list : any[] = response.data.data.list;
                    for( let user of user_list ) {
                        vue.assignment_user_list.push(user.user_id);
                    }
                    vue.all_member_count = user_list.length;
                })
                .catch((e) => {
                    this.hodu_error_process(e, false, false);
                });
    }

    /**
     * 업무 배정 Modal
     */
    workAssign() : void {
        this.doSetEventWorkAssignmentInfo({
            show_event_work_assignment : true,
            selected_user_ids          : this.assignment_user_list,
            confirm_function           : (user_ids : number[], assignment_type : string, user_list_length : number) : void => {

                // 배정받은 유저 등록
                this.assignment_user_list.splice(0, this.assignment_user_list.length);
                const user_ids_count : number = user_ids.length;
                for( let i = 0; i < user_ids_count; i++ ) {
                    this.assignment_user_list.push(user_ids[i]);
                }

                // work 정보 배정(전부 선택하지 않았다면), 모두(전부 선택했다면)로 변경
                if( this.event.event_data.work == null ) {
                    return;
                }
                this.event.event_data.work.assignment_type = assignment_type;
                this.all_member_count = user_list_length;  

                // 모달 닫기
                this.doSetEventWorkAssignmentInfo({
                    show_event_work_assignment : false,
                    selected_user_ids          : [],
                    confirm_function           : () => {}
                })
            }
        });
    }

    /**
     * 사용자가 업무를 시작 했는지 여부 확인
     */
    isWorkStarted() : boolean {
        // 배정받지 않았다면 false
        if( this.user_work_status == null ) {
            return false;
        }

        // 하나라도 진행된게 있는지 확인
        for( let progress of this.user_work_status.work_status_info.progress ) {
            // 하나라도 WAIT가 아니라면 시작중인 업무
            if( progress.status != 'WAIT' ) {
                return true;
            }   
        }

        return false;
    }

    /**
     * 업무 상태에 따른 string 반환
     */
    getWorkStatusMessage() : string {
        if( this.event.event_data.work == null ) {
            return '';
        }

        // 업무 상태에 따라 string 반환
        switch( this.event.event_data.work.work_status_code ) {
            case "WAIT":
                return '대기 중인 프로젝트입니다';

            case "START":
                return '진행 중인 프로젝트입니다';

            case "CANCEL":
                return '중단된 프로젝트입니다';

            case "END":
                return '완료된 프로젝트입니다';
        }

        return '';
    }

    /**
     * 사용자의 업무 상태에 따라서 반환!
     */
    getWorkUserStatusMessage() : string {
        if( this.event.event_data.work == null ) {
            return '';
        }

        // 본인이 배정받지 않은 미정업무인 경우
        if( this.event.event_data.work.assignment_type == 'UNDEF' && this.user_work_status == null ) {
            
            // 기본, 단계식 미정 업무
            if( this.event.event_data.work.template_type != 'CHECK' ) {
                
                // 이미 배정받은 사람이 있는 경우
                if( this.work_status_list.length > 0 ) {
                    return '업무 권한이 없습니다';
                } 
                
                // 배정 받은 사람이 없는 경우
                else {
                    return '시작 전인 선착순 업무입니다';
                }
            }
            
            // 체크식 업무
            else {
                if( this.selected_work_template == null ) {
                    return '';
                }

                let check_flag : boolean = true;

                // 템플릿 단계 / 체크 수만큼 false 추가
                const check_flag_array : boolean[] = [];
                for( let content of this.selected_work_template.template_info.content ) {
                    check_flag_array.push(false);
                }
                
                // 사용자가 어떤 체크를 선택했는지 살핌
                const status_length : number = this.work_status_list.length;
                for( const work_status of this.work_status_list ) {

                    // 사용자들이 어디에 체크했는지 검사한다
                    const user_status_count : number = work_status.work_status_info.progress.length;
                    for( let i = 0; i < user_status_count; i++ ) {
                        // WAIT가 아니면 해당 체크를 선택한것이다
                        if( work_status.work_status_info.progress[i].status != 'WAIT' ) {
                            check_flag_array[i] = true;
                        }
                    }
                }

                // 전부 체크 되었다면 && 연산자에 의해서 true로 나온다
                for( let flag of check_flag_array ) {
                    check_flag = check_flag && flag;
                }

                return check_flag ? '업무 권한이 없습니다' : '시작 할 수 있는 선착순 업무입니다';
            }
        }

        // 배정받지 않았는데 미정업무가 아닌 경우
        else if( this.event.event_data.work.assignment_type != 'UNDEF' && this.user_work_status == null ) {
            return '업무 권한이 없습니다';
        }

        // 그 외 사용자의 업무 정보가 있다면
        else if( this.user_work_status != null ) {
            // is_end가 true라면 완료한 업무
            if( this.user_work_status.work_status_info.is_end == true ) {
                return '완료 한 업무입니다';
            }

            else {
                let all_wait_flag : boolean = true;
                for( let progress of this.user_work_status.work_status_info.progress ) {
                    if( progress.status != 'WAIT' ) {
                        all_wait_flag = false;
                        break;
                    }
                }
                
                // 전부 대기중이라면 시작 전, 하나라도 진행됐다면 업무를 진행 하는 중
                return all_wait_flag ? '프로젝트를 시작 하기 전입니다' : '프로젝트를 진행 하는 중입니다';
            }
        }

        return '';
    }

    /**
     * 업무 상태에 따른 색상 반환
     */
    getWorkStatusColor(isText : boolean = false) : string {
        if( this.event.event_data.work == null ) {
            return '#e1e4e6';
        }

        // 업무 상태에 따라 색상 반환
        switch( this.event.event_data.work.work_status_code ) {
            case "WAIT":
                return isText ? "#b1b4b6" : '#e1e4e6';

            case "START":
                return '#ffcd00';

            case "CANCEL":
                return '#ff6369';

            case "END":
                return '#477fff';
        }

        return '#e1e4e6';
    }

    /**
     * 해당 업무 단계 / 체크를 수행한 경우 (업무 상태가 WAIT가 아닌경우)
     */
    getWorkOn(index : number) : boolean {
        if( this.user_work_status == null ) {
            return false;
        }

        // 해당 아이템이 WAIT가 아닌 경우 해당 단계 / 체크를 수행한 것
        if( this.user_work_status.work_status_info.progress[index].status != 'WAIT' ) {
            return true;
        }

        return false;
    }

    /**
     * 단계식 업무일때 현재 체크가 마지막 진행점일때 true 반환
     */
    getStepNow(index : number) : boolean {

        if( this.user_work_status == null ) {
            return false;
        }

        // 해당 아이템이 START인 경우 || 해당 아이템이 마지막 아이템인데 END인 경우
        if( this.user_work_status.work_status_info.progress[index].status == 'START' ||
            (this.user_work_status.work_status_info.progress.length == index + 1 && this.user_work_status.work_status_info.progress[index].status == 'END') ) {
            return true;
        }

        return false;
    }

    /**
     * 업무 상태 정보 받아서 등록
     */
    async setEventWorkData(work : any, work_status_list : t_work_status[]) : Promise<void> {
        if( this.event.event_data.work == null ) {
            return;
        }

        this.event.event_data.work.work_status_code    = work.work_status_code;
        this.event.event_data.work.work_status_visible = work.work_status_visible;
        this.work_status_list.splice(0, this.work_status_list.length);
        this.work_status_list = this.work_status_list.concat(work_status_list ? work_status_list : []);

        this.user_work_status = null;
        for( let status of this.work_status_list) {
            if( status.user_id == this.user_id ) {
                this.user_work_status = status;
            }
        }

    }

    /**
     * 업무 시작 버튼 클릭
     */
    workStart() : void {

        // 기본업무
        if( this.event.event_data.work != null && this.event.event_data.work.template_type == 'DEFAULT' ) {
            this.defaultWorkStart();
        }

        // 단계, 체크식 업무
        else if ( this.event.event_data.work != null && this.event.event_data.work.template_type != 'DEFAULT' ) {
            this.workModalOpen();
        }
    }

    /**
     * 기본업무 시작
     */
    defaultWorkStart() : void {

        if( this.event.event_data.work == null ) {
            return;
        }

        // 미정이고 다른사람이 이미 시작했다면 시작 불가능
        if( this.event.event_data.work.assignment_type == 'UNDEF' && this.event.event_data.work.template_type == 'DEFAULT' && 
            this.user_work_status == null && this.work_status_list.length > 0 ) {
            return;
        }

        // 배정이고 배정되지 않았다면 시작 불가능
        if( this.event.event_data.work.assignment_type != 'UNDEF' && this.user_work_status == null ) {
            return;
        }

        this.user_work_status = {
            event_id : this.event.event_id ? this.event.event_id : '',
            user_id : this.user_id,
            scope : this.event.event_type ? this.event.event_type : '',
            scope_id : this.event.event_type == OWNER_TYPE.GROUP ? (this.event.group_id ? this.event.group_id : 0) : (this.event.team_id ? this.event.team_id : 0),
            last_update_tag : '0',
            audit_modified : new Date(),
            audit_delete_flag : false,
            audit_user_id : this.user_id,
            work_status_info : {
                is_end : false,
                progress : [{ seq : 0, status : 'START' }]
            }
        };

        this.insertOrUpdateWorkStatus();
    }

    /**
     * 단계식, 체크식 업무 Modal 열기
     */
    workModalOpen() : void {

        if( this.event.event_data.work == null || this.selected_work_template == null ) {
            return;
        }

        // 미정이고 사용자는 업무 상태 데이터가 없을때
        if( this.event.event_data.work.assignment_type == 'UNDEF' && this.user_work_status == null ) {
            
            // 단계식 미정 업무는 다른 누군가 시작했다면 시작할수 없다
            if( this.event.event_data.work.template_type == 'FLOW' && this.work_status_list.length > 0 ) {
                return;
            }

            // 체크식 미정 업무는 체크별 1명이기때문에 남아있는 체크가 없다면 시작할수 없다
            if( this.event.event_data.work.template_type == 'CHECK' ) {

                let check_flag : boolean = true;

                // 템플릿 단계 / 체크 수만큼 false 추가
                const check_flag_array : boolean[] = [];
                for( let content of this.selected_work_template.template_info.content ) {
                    check_flag_array.push(false);
                } 

                // 사용자가 어떤 체크를 선택했는지 살핌
                const status_length : number = this.work_status_list.length;
                for( const work_status of this.work_status_list ) {

                    // 사용자들이 어디에 체크했는지 검사한다
                    const user_status_count : number = work_status.work_status_info.progress.length;
                    for( let i = 0; i < user_status_count; i++ ) {
                        // WAIT가 아니면 해당 체크를 선택한것이다
                        if( work_status.work_status_info.progress[i].status != 'WAIT' ) {
                            check_flag_array[i] = true;
                        }
                    }
                }

                // 전부 체크 되었다면 && 연산자에 의해서 true로 나온다
                for( let flag of check_flag_array ) {
                    check_flag = check_flag && flag;
                }
                
                // 남은 체크가 없는 경우
                if( check_flag ) {
                    return;
                }

            }
            
        }

        // 배정이고 배정되지 않았다면 시작 불가능
        if( this.event.event_data.work.assignment_type != 'UNDEF' && this.user_work_status == null ) {
            return;
        }

        this.doSetWorkStatusInfo({
            calendar_id : this.event.calendar_id,
            event_id : this.event.event_id,
            scope : this.event.event_type,
            scope_id : this.event.event_type == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id
        });
        this.doSetShowEventWorkProcess(true);
    }

    /**
     * 업무 취소 (미정 업무였다면 status 삭제, 아니라면 insertOrUpdate)
     */
    workCancel() : void {
        if( this.event.event_data.work == null || this.user_work_status == null ) {
            return;
        }

        this.hodu_show_dialog('cancel', '진행하신 프로젝트를 취소하시겠습니까?', ['아니오', '예'], [
            () => {},
            () => {
                if( this.event.event_data.work == null || this.user_work_status == null ) {
                    return;
                }

                // 미정업무 (업무 삭제)
                if( this.event.event_data.work.assignment_type == 'UNDEF' ) {
                    this.deleteWorkUserStatus();
                } 

                // 배정업무 (진행 단계 초기화)
                else {

                    // 초기화
                    this.user_work_status.work_status_info.is_end = false;
                    const user_status_count : number = this.user_work_status.work_status_info.progress.length;
                    for( let i = 0; i < user_status_count; i++ ) {
                        const progress_fragment : any = JSON.parse(JSON.stringify(this.user_work_status.work_status_info.progress[i]));
                        progress_fragment.status = 'WAIT';
                        this.user_work_status.work_status_info.progress.splice(i, 1, progress_fragment);
                    }

                    // user_status를 work_status_list 에도 등록
                    const work_status_list_count : number = this.work_status_list.length;
                    for( let i = 0; i < work_status_list_count; i++ ) {
                        if( this.work_status_list[i].user_id == this.user_id ) {
                            this.work_status_list.splice(i, 1, this.user_work_status);
                        }
                    }

                    // 업무 전체 초기화 후 insertOrUpdate
                    this.insertOrUpdateWorkStatus();
                }
            }
        ]);
    }

    /**
     * 업무 완료 (기본, 단계식이면 전부 END로 채우고 완료API 실행, 체크라면 그대로 API를 실행한다)
     */
    workComplete() : void {
        if( this.event.event_data.work == null || this.user_work_status == null ) {
            return;
        }

        this.hodu_show_dialog("alert", "프로젝트를 완료하시겠습니까?", ["아니오", "예"], [
            () => {},
            () => {
                if( this.event.event_data.work == null || this.user_work_status == null ) {
                    return;
                }

                // 업무 완료 플래그 설정
                this.user_work_status.work_status_info.is_end = true;

                // 체크식이 아닌 경우 모든 단계를 END로 설정
                if( this.event.event_data.work.template_type != 'CHECK' ) {
                    const progress_count : number = this.user_work_status.work_status_info.progress.length;
                    for( let i = 0; i < progress_count; i++ ) {
                        this.user_work_status.work_status_info.progress[i].status = 'END';
                    }
                }

                this.insertOrUpdateWorkStatus();
            }
        ]);
    }

    /**
     * 사용자 업무 상태추가 또는 수정
     */
    async insertOrUpdateWorkStatus() : Promise<void> {
        const vue = this;

        if( this.user_work_status == null ) {
            return;
        }

        this.user_work_status.scope = (this.event.event_type ? this.event.event_type : "");
        this.user_work_status.scope_id = this.event.event_type == OWNER_TYPE.GROUP ? (this.event.group_id ? this.event.group_id : 0) : (this.event.team_id ? this.event.team_id : 0);
        this.user_work_status.event_id = (this.event.event_id ? this.event.event_id : "");

        let url : string = `api/v1/calendars/${this.event.calendar_id}/works/${this.user_work_status.scope}/${this.user_work_status.scope_id}/${this.user_work_status.event_id}/status/user?select_seq=-1`;
        await this.hodu_api_call(url, API_METHOD.PUT, this.user_work_status)
            .then(async(response) => {
                console.log(response);
                await vue.getEvent();
            })
            .catch(async(e) => {
                if( e.response && e.response.status == 406 ) {
                    await vue.getEvent();
                } 
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 사용자 업무 상태 삭제
     */
    async deleteWorkUserStatus() : Promise<void> {
        const vue = this;

        let url : string = `api/v1/calendars/${this.event.calendar_id}/works/${this.event.event_type}/${this.event.event_type == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id}/${this.event.event_id}/status/user`;
        await this.hodu_api_call(url, API_METHOD.DELETE)
            .then(async(response) => {
                console.log(response);
                await vue.getEvent();
            })
            .catch(async(e) => {
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 업무 현황 Modal 열기
     */
    workStatusModalOpen() : void {
        if( this.event.event_data.work == null) {
            return;
        }

        // 미정업무인데 아무도 시작 안한건 진입불가
        if( this.event.event_data.work.assignment_type == 'UNDEF' && this.work_status_list.length < 1 ) {
            return;
        } 

        // 기본업무 현황 MODAL
        else if( this.event.event_data.work.template_type == 'DEFAULT' ) {
            this.doSetWorkStatusInfo({
                calendar_id : this.event.calendar_id,
                event_id : this.event.event_id,
                scope : this.event.event_type,
                scope_id : this.event.event_type == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id
            });
            this.doSetShowWorkDefaultStatus(true);
        }

        // 단계, 체크식 업무 현황 MODAL
        else {
            this.doSetWorkStatusInfo({
                calendar_id : this.event.calendar_id,
                event_id : this.event.event_id,
                scope : this.event.event_type,
                scope_id : this.event.event_type == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id
            });
            this.doSetShowWorkStatus(true);
        }
        
    }

    /**
     * 업무 상태 변경
     */
    updateWorkEventStatus(js_event, status : string) {
        let scope : string = (this.event.event_type ? this.event.event_type : "");
        let scope_id : number = this.event.event_type == OWNER_TYPE.GROUP ? (this.event.group_id ? this.event.group_id : 0) : (this.event.team_id ? this.event.team_id : 0);

        this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/works/${scope}/${scope_id}/${this.event.event_id}/status`, API_METHOD.PUT, { work_status_type : status })
            .then((response) => {
                console.log(response);
                this.getEvent();
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 업무 시작버튼 보여야 하는가에 대한 boolean 값 반환
     */
    getWorkStartButtonVisibility() : boolean {
        if( this.event.event_data.work == null ) {
            return false;
        }

        // 중단 또는 완료된 업무는 안보인다
        if( this.event.event_data.work.work_status_code == 'CANCEL' || this.event.event_data.work.work_status_code == 'END' ) {
            return false;
        }

        let visibility_flag : boolean = false;

        // 미정일때 (기본, 단계는 누군가 수행 중이라면 안 보임, 체크는 남은 체크가 하나도 없다면 안 보임)
        if( this.event.event_data.work.assignment_type == 'UNDEF' ) {
            
            // 체크식 업무
            if( this.event.event_data.work.template_type == 'CHECK' ) {
                if( this.selected_work_template == null ) {
                    return false;
                }

                // 템플릿 단계 / 체크 수만큼 false 추가
                const check_flag_array : boolean[] = [];
                for( let content of this.selected_work_template.template_info.content ) {
                    check_flag_array.push(false);
                } 

                // 사용자가 어떤 체크를 선택했는지 살핌
                const status_length : number = this.work_status_list.length;
                for( const work_status of this.work_status_list ) {

                    // 사용자들이 어디에 체크했는지 검사한다
                    const user_status_count : number = work_status.work_status_info.progress.length;
                    for( let i = 0; i < user_status_count; i++ ) {
                        // WAIT가 아니면 해당 체크를 선택한것이다
                        if( work_status.work_status_info.progress[i].status != 'WAIT' ) {
                            check_flag_array[i] = true;
                        }
                    }
                }

                // 전부 체크 되었다면 && 연산자에 의해서 true로 나온다
                let check_flag = true;
                for( let flag of check_flag_array ) {
                    check_flag = check_flag && flag;
                }
                
                visibility_flag = !check_flag;
            } 
            
            // 기본, 단계식 업무
            else {
                visibility_flag = this.work_status_list.length < 1;
            }

        }

        // 배정일때 (배정 안되어있으면 안보임)
        else {
            visibility_flag = (this.user_work_status != null);
        }

        return visibility_flag;
    }

    /**
     * 업무 취소버튼 보여야 하는가에 대한 boolean 값 반환
     */
    getWorkCancelButtonVisibility() : boolean {
        if( this.event.event_data.work == null || this.user_work_status == null ) {
            return false;
        }

        // 중단 또는 완료된 업무는 안보인다
        if( this.event.event_data.work.work_status_code == 'CANCEL' || this.event.event_data.work.work_status_code == 'END' ) {
            return false;
        }

        // 사용자 업무 상태 체크
        let user_work_start_flag : boolean = false;
        for( let progress of this.user_work_status.work_status_info.progress ) {
            if( progress.status != 'WAIT' ) {
                user_work_start_flag = true;
                break;
            }
        }

        // 업무가 WAIT가 아니고 사용자가 업무 중일때 떠야한다
        return this.event.event_data.work.work_status_code != 'WAIT' && user_work_start_flag;
    }

    /**
     * 업무현황 버튼이 보여야하는가에 대한 boolean 값 반환
     */
    getWorkStatusButtonVisibility() : boolean {
        if( this.event.event_data.work == null ) {
            return false;
        }

        // 미정인데 업무 수행하는사람이 없는 경우 (return false)
        if( this.event.event_data.work.assignment_type == 'UNDEF' && this.work_status_list.length < 1 ) {
            return false;
        }
        
        // 업무 작성자이거나 업무 현황보이기인 경우 true 둘중 하나라도 만족 못하면 false
        return this.event.event_data.event_owner_id == this.user_id || (this.event.event_data.work != null && this.event.event_data.work.work_status_visible == true);
    }

    /**
     * 업무완료 버튼이 보여야하는가에 대한 boolean 값 반환
     */
    getWorkCompleteButtonVisibility() : boolean {
        if( this.event.event_data.work == null || this.user_work_status == null ) {
            return false;
        }

        // 중단 또는 완료된 업무는 안보인다
        if( this.event.event_data.work.work_status_code == 'CANCEL' || this.event.event_data.work.work_status_code == 'END' ) {
            return false;
        }

        // 사용자 업무 상태 체크
        let user_work_start_flag : boolean = false;
        for( let progress of this.user_work_status.work_status_info.progress ) {
            if( progress.status != 'WAIT' ) {
                user_work_start_flag = true;
                break;
            }
        }

        // 업무가 WAIT가 아니고 사용자가 업무 중이고 종료된 업무가 아닐때 떠야한다
        return this.event.event_data.work.work_status_code != 'WAIT' && user_work_start_flag && this.user_work_status.work_status_info.is_end == false;
    }
    
    /**
     * 화상 회의 시작
     */
    videoConferencing() : void {

        let query = `event_id=${encodeURIComponent(this.event.event_id ?? "")}`;
        query += `&title=${encodeURIComponent(this.event.event_data.title)}`;
        query += `&user_id=${encodeURIComponent(this.user_id)}`;
        query += `&user_name=${encodeURIComponent(this.user_name)}`;

        window.open(`/video-conferencing.html?${query}`, "jitsi", "toolbar=1, scrollbars=0, resizable=0, width=" + 1024 + ", height=" + 768);
    }

    /**
     * 화상회의 가능 여부
     * -- 1. 공유 되어 있을것 (그룹, 팀 일정이거나 공유된 일정) !!예전 조건임!!
     * 1. 프리미엄 그룹의 일정일것
     * 2. 시간 조건에 맞을것 (시작 30분 전 부터 종료 30분 후 까지 입장 가능)
     */
    isVideoConference() : boolean {
        
        // let share : boolean = false;
        // if( this.event.event_type == OWNER_TYPE.GROUP ) share = true;
        // if( this.event.event_type == OWNER_TYPE.TEAM ) share = true;
        // if( (this.event?.subscribe_users?.length ?? 0) > 0 ) share = true;
        // if( (this.event?.subscribe_groups?.length ?? 0) > 0 ) share = true;
        // if( (this.event?.subscribe_teams?.length ?? 0) > 0 ) share = true;

        // alert(share);

        let time : boolean = false;

        let start = new Date(this.event.event_data.schedule_date.start);
        let end = new Date(this.event.event_data.schedule_date.end);
        let limit = 30 * 60 * 1000; // 시작 30분 전, 종료 30분 이후까지만 열려있음

        if( (start.getTime() - limit) <= new Date().getTime() && new Date().getTime() <= (end.getTime() + limit) ) time = true;
        
        // alert(time);

        return this.is_premium_event && time;
    }

    /**
     * 상단 타이틀
     */
    getViewTitle() :string {
        
        // 생성
        if( this.isCreate((this.event_crud_type as CRUD_TYPE)) == true ) {
            if( this.isSchedule((  this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) {
                return this.isPersonalScope((this.event.event_type as OWNER_TYPE) ?? OWNER_TYPE.PERSONAL) ? "일정 등록" : 
                       this.isGroupScope((this.event.event_type as OWNER_TYPE) ?? OWNER_TYPE.PERSONAL) ? `[${this.event.event_data.event_owner_group_name}] 일정 등록` 
                                                                                                       : `[${this.event.event_data.event_owner_team_name}] 일정 등록`;
            }
            if( this.isCard((      this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "카드 등록";
            if( this.isWork((      this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "프로젝트 등록";
            if( this.isReport((    this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "업무일지 등록";
            if( this.isMeetingLog((this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "회의록 등록";
        }
        
        // 조회
        if( this.isRead((this.event_crud_type as CRUD_TYPE)) == true ) {
            if( this.isSchedule((  this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "일정";
            if( this.isCard((      this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "카드";
            if( this.isWork((      this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "프로젝트";
            if( this.isReport((    this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "업무일지";
            if( this.isMeetingLog((this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "회의록";
        }

        // 수정
        if( this.isUpdate((this.event_crud_type as CRUD_TYPE)) == true ) {
            if( this.isSchedule((  this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "일정 수정";
            if( this.isCard((      this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "카드 수정";
            if( this.isWork((      this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "프로젝트 수정";
            if( this.isReport((    this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "업무일지 수정";
            if( this.isMeetingLog((this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "회의록 수정";
        }
                
        return "";
    }

    /**
     * 메모의 타이틀을 이벤트 서브타입에 따라 반환
     */
    getMemoTitle(event_sub_type : EVENT_SUB_TYPE | string | undefined) : string {

        if( event_sub_type == EVENT_SUB_TYPE.REPORT ) {
            return "업무내용";
        }

        if( event_sub_type == EVENT_SUB_TYPE.MEETINGLOG ) {
            return "회의내용";
        }

        return "메모(공개)";
    }

    /**
     * 메모의 placeholder를 이벤트 서브타입에 따라 반환
     */
    getMemoPlaceholder(event_sub_type : EVENT_SUB_TYPE | string | undefined) : string {

        if( event_sub_type == EVENT_SUB_TYPE.REPORT ) {
            return "업무내용";
        }

        if( event_sub_type == EVENT_SUB_TYPE.MEETINGLOG ) {
            return "회의내용";
        }

        return "공개 (모두에게 보이는 메모)";
    }

    /**
     * 본인이 해당 일정에 대해서 관리지인지 여부 반환
     */
    isManager() : boolean {

        if( this.event == null ) return false;

        if( this.event.event_data.event_owner_id == this.user_id ) {
            return true;
        }

        if( this.event.event_type == OWNER_TYPE.GROUP ) {
            return this.get_group_role(this.event.group_id ? this.event.group_id : 0) == this.GroupAuth.GROUP_MANAGER ||
                   this.get_group_role(this.event.group_id ? this.event.group_id : 0) == this.GroupAuth.ADMIN;
        }

        if( this.event.event_type == OWNER_TYPE.TEAM ) {
            return this.get_team_role(this.event.team_id ? this.event.team_id : 0) == this.GroupAuth.TEAM_MANAGER ||
                   this.get_team_role(this.event.team_id ? this.event.team_id : 0) == this.GroupAuth.ADMIN;
        }
        
        return false;
    }

    /**
     * 문서화로 이동
     */
    workDocumentation() : void {

        if( this.isReport(this.event.event_sub_type as EVENT_SUB_TYPE) == false && this.isMeetingLog(this.event.event_sub_type as EVENT_SUB_TYPE) == false ) {
            this.hodu_show_dialog('cancel', "문서화 기능은 업무일지, 회의록에서만 가능합니다", ['확인']);
            return;
        }

        // popup open
        let query = "";
        query += `calendar_id=${this.event.calendar_id}`;
        query += `event_id=${this.event.event_id}`;
        query += `&event_sub_type=${this.event.event_sub_type}`;
        query += `&document_type=${ this.isReport(this.event.event_sub_type as EVENT_SUB_TYPE) ? 'daily_report_1' : 'meeting_log_1' }`;
        query += `&start=${moment(this.event.event_data.schedule_date.start).utc().format()}`;
        query += `&end=${moment(this.event.event_data.schedule_date.end).utc().format()}`;
        
        query += `&title=${encodeURIComponent(this.event.event_data.title)}`;
        // if( this.event.event_data.memo ) query += `&content=${encodeURIComponent(this.event.event_data.memo)}`;
        if( this.event.event_data.event_owner_name ) query += `&writer=${encodeURIComponent(this.event.event_data.event_owner_name)}`; 

        query += `&request_user_id=${this.user_id}`;
        query += `&scope=${this.event.event_type}`;
        query += `&scope_id=${this.event.event_type == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id}`;
        
        const timezone = -(new Date().getTimezoneOffset());
        query += `&timezone=${timezone ? timezone : 540}`;

        try {
            let type = navigator.appName;
            let country_code = 'ko-KR';
            if (type == 'Netscape') {
                country_code = navigator.language;
            } else {
                country_code = navigator['userLanguage'];
            }
            query += `&country_code=${country_code}`;
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            query += `&country_code=ko-KR`;
        }

        window.open(`/work_pdf.html?${query}`, "work_pdf", "toolbar=0, scrollbars=0, resizable=0, width=" + 1024 + ", height=" + 768);
    }

    /**
     * 반복 종료일 있음 첫 클릭시
     */
    setRepeatDefault() : void {
        if( this.event == null ) return;
        this.repeat_infinite = false;
    }

    /**
     * 반복 종료일 없음
     */
    setRepeatInfinite() : void {
        if( this.event == null ) return;
        this.repeat_infinite = true;
    }

    /**
     * 일정 히스토리
     */
    showEventHistoryModal() : void {
        this.doSetEventHistoryModalInfo?.({
            show_modal : true,
            event_id : this.event.event_id ?? "",
        });
    }

    inputCardContent(event) : void {

        const value = event.target.value;
        
        if( this.event.event_data.card != null ) {
            this.event.event_data.card.card_data.card_content = value;
        }
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('.alarmDivScroll').mCustomScrollbar('destroy');

        // 업무에서는 그룹TR 사용 안함
        if( this.event.event_sub_type != EVENT_SUB_TYPE.WORK ) {
            // @ts-ignore
            $('#schedule_group_div').mCustomScrollbar('destroy');
        }

        this.setScroll();
    }

}
