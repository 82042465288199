
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { approval_modal_interface } from '@/model/approval';

import OrganizationRecursion from '@/components/organization/OrganizationRecursion.vue';

@Component({
    components: {
        OrganizationRecursion
    },
})
export default class ApprovalApproverFavoriteModal extends Mixins(VueHoduCommon) {

    /**
     * 부서 정보
     */
    get computedOrganization() : any {

        const copy_departments = JSON.parse(JSON.stringify(this.departments));

        let max_level = 0;
        for( const department of copy_departments ) {
            if( department.dept_id_array.length > max_level ) max_level = department.dept_id_array.length;
            department['departments'] = [];
            department['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.department_closed_map.get(department.dept_id);
            if( is_closed != null ) {
                department['is_closed'] = is_closed;
            }
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_level);
            const next_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_next_level); 

            for( const next_search_department of next_search_departments ) {
                const next_search_department_id = next_search_department.dept_id;
                next_search_department.level = search_next_level;

                for( const current_search_department of current_search_departments ) {
                    const current_search_department_id = current_search_department.dept_id;
                    current_search_department.level = search_level;

                    if( current_search_department.dept_id_array.indexOf(next_search_department_id) > -1 ) {
                        next_search_department.departments.push(current_search_department);
                    }

                }
            }
            
            search_level--;
        }

        const top_organizations = copy_departments.filter(item => item.dept_id_array.length == 1);
        if( top_organizations == null || top_organizations.length < 1 ) return null;
        const top_organization = top_organizations[0];

        return top_organization;
    }

    /**
     * 직원 정보
     */
    get computedEmployee() : any[] {
        let employees = JSON.parse(JSON.stringify(this.employees));

        for( const employee of employees ) employee.approver_type = 'EMPLOYEE';

        employees.sort((o1, o2) => {

            const o1_name = o1.user_name;
            const o2_name = o2.user_name;

            const o1_pos_seq = this.getPositionSeq(o1.pos_id);
            const o2_pos_seq = this.getPositionSeq(o2.pos_id);

            // 같은 직급은 이름순
            if( o1_pos_seq == o2_pos_seq ) {
                if( o1_name > o2_name ) return 1;
                else if( o1_name < o2_name ) return -1;
                return 0
            }
            else if( o1_pos_seq > o2_pos_seq ) return  1;
            else if( o1_pos_seq < o2_pos_seq ) return -1;

            return 0;
        });

        return employees.filter(item => item.user_id != this.user_id && item.pos_id != null && item.dept_id != null);
    }

    /**
     * 선택된 것
     */
    get computedSelected() : any[]  {

        let selected : any[] = [];

        let temp_selected : any[] = [];
        if( this.tab == 'APPROVER' ) {
            temp_selected = this.approval_approver_favorite_modal_info.approver ?? [];
        }
        else if( this.tab == 'RECEIVE_REFERENCE' ) {
            temp_selected = this.approval_approver_favorite_modal_info.receive_reference ?? [];
        }

        selected = temp_selected;

        // 직급, 가나다순
        // selected = temp_selected.sort((o1, o2) : number => {

        //     const o1_name = o1.user_name;
        //     const o2_name = o2.user_name;

        //     const o1_pos_seq = this.getPositionSeq(o1.pos_id);
        //     const o2_pos_seq = this.getPositionSeq(o2.pos_id);

        //     // 같은 직급은 이름순
        //     if( o1_pos_seq == o2_pos_seq ) {
        //         if( o1_name > o2_name ) return 1;
        //         else if( o1_name < o2_name ) return -1;
        //         return 0
        //     }
        //     else if( o1_pos_seq > o2_pos_seq ) return  1;
        //     else if( o1_pos_seq < o2_pos_seq ) return -1;
            
        //     return 0;
        // });
        
        return selected;
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State approval_approver_favorite_modal_info !: approval_modal_interface.ApprovalApproverFavoriteModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetApprovalApproverFavoriteModalInfo ?: (params : approval_modal_interface.ApprovalApproverFavoriteModalInfo) => void;

    departments : any[] = [];
    selected_department : any = { dept_id : -1 }; 

    department_closed_map : Map<number, boolean> = new Map();
    department_height_map : Map<number, number> = new Map();

    position : any[] = [];
    employees : any[] = [];

    favorite_name : string = "";
    is_duplicate : boolean = false;

    tab : string = "APPROVER"; // APPROVER, RECEIVE_REFERENCE

    mounted() : void {
        // 버튼 클릭시 라인과 팀 보이기
        $(".arw").click(function(){
            // 리스트 인덱스 가져오기
            var getIndex = $(this).parent().index();
            
            // 버튼 클릭시 라인과 팀 보이기
            $(".fList").eq(getIndex).toggleClass("showTeam");

        });

        //결재자 수신참조 tab
        // $(".approverTapDiv a").on("click", function(){
        //     const num = $(".approverTapDiv a").index($(this));
        //     // 기존에 적용되어 있는 on class 삭제
        //     $(".approverTapDiv a").removeClass("active");
        //     $(".selected_show").removeClass("on");

        //     // 다음 요소 클릭시 on class 추가
        //     $('.approverTapDiv a:eq(' + num + ')').addClass("active");
        //     $('.selected_show:eq(' + num + ')').addClass("on");

        // });

        this.getTotalOrganizationData();
    }

    /**
     * 종합적인 조직도 데이터 조회
     */
     async getTotalOrganizationData() : Promise<void> {
        try {
            await this.getOrganizationPosition();
            await this.getOrganization();
            await this.getOrganizationEmp();

            // 결재자 즐겨찾기 수정인 경우
            if( this.approval_approver_favorite_modal_info.favorite_id != null && this.approval_approver_favorite_modal_info.favorite_id.trim().length > 0 ) {
                await this.getApprovalApproverFavorite();
            }

        } catch(e) {
            alert("결재자 즐겨찾기에 필요한 데이터 조회 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 조회 중 오류 발생");
            }

            // map에 담겨있는 vertical_height 정보로 업데이트
            for( const department of response.data.data.dept_info ) {
                const vertical_height = this.department_height_map.get(department.dept_id);
                if( vertical_height != null ) {
                    department.vertical_height = vertical_height;
                }
            }
            
            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            response.data.data.pos_info.sort((o1, o2) : number => {
                if( o1.pos_seq > o2.pos_seq ) return 1;
                else if( o1.pos_seq < o2.pos_seq ) return -1;
                return 0;
            })

            this.position.splice(0, this.position.length);
            this.position = this.position.concat(response.data.data.pos_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {

            if( this.selected_department == null || this.selected_department.dept_id == -1 ) {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                    throw new Error("조직도 전체 직원 조회 중 오류 발생");
                }

                this.employees.splice(0, this.employees.length);
                this.employees = this.employees.concat(response.data.data.emp_info);
                return;
            }

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/${this.selected_department.dept_id}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 부서 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);

        } catch(e) {
            throw e;
        }
    }

    async getApprovalApproverFavorite() {
        
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/approver/favorite/${this.approval_approver_favorite_modal_info.favorite_id}`, API_METHOD.GET);

            console.log(response);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.favorite ) {
                throw new Error("결재자 즐겨찾기 조회 오류");
            }

            const favorite = response.data.data.favorite;
            this.favorite_name = favorite.favorite_name;

        } catch(e) {
            throw e;
        }

    }

    /**
     * 부서 선택
     */
    async select(department : any) : Promise<void> {
        this.selected_department = department;
        this.$forceUpdate();
        this.hodu_show_indicator();
        await this.getTotalOrganizationData();
        this.hodu_hide_indicator();
    }

    /**
     * 직급 순서 반환
     */
    getPositionSeq(pos_id : number) : number {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return Number.MAX_SAFE_INTEGER;
            return target[0].pos_seq;
        } catch(e) {
            return Number.MAX_SAFE_INTEGER;
        }
    }

    /**
     * 부서 이름 반환
     * level 1 : 최상위 부서 이름 반환
     * level 2 : 최상위 부서 바로 아래의 부서 이름 하나만 반환
     * level n (n > 2) : level 2 / level 3 / level 4 ... 
     */
    getDepartmentName(dept_id : number) : string {
        try {
            
            // if( dept_id == 0 ) return "미배정";

            let dept_name = "";
            const target = this.departments.filter(item => item.dept_id == dept_id)[0];

            let target_departments : any[] = [];
            for( const department of this.departments ) {
                if( target.dept_id_array.indexOf(department.dept_id) > -1 ) target_departments.push(department);
            }

            target_departments.sort((o1, o2) : number => {
                const o1_length = o1.dept_id_array.length;
                const o2_length = o2.dept_id_array.length;

                if( o1_length > o2_length ) return 1;
                else if( o1_length < o2_length ) return -1;
                return 0;
            });

            for( const department of target_departments ) {
                if( target_departments.length >= 2 && target_departments.indexOf(department) == 0 ) continue;

                if( dept_name.length > 0 ) dept_name += " / ";
                dept_name += department.dept_name;
            }
        
            return dept_name;
        } catch(e) {
            console.log(e);
            return "-";
        }
    }

    /**
     * 직급 이름 반환
     */
    getPositionName(pos_id : number) : string {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return "-";
            return target[0].pos_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 선택된 부서에서 선택된 사람의 수 또는 선택된 직급 수
     */
     getSelectedCheck() : number {

        const ids : number[] = [];

        if( this.tab == 'APPROVER' ) {

            if( this.approval_approver_favorite_modal_info.approver == null ) return 0;

            for( const selected_emp of this.approval_approver_favorite_modal_info.approver ) {
                for( const emp of this.computedEmployee ) {
                    if( selected_emp.user_id == emp.user_id ) ids.push(selected_emp.user_id);
                }
            }
        }

        else if( this.tab == 'RECEIVE_REFERENCE' ) {

            if( this.approval_approver_favorite_modal_info.receive_reference == null ) return 0;

            for( const selected_emp of this.approval_approver_favorite_modal_info.receive_reference ) {
                for( const emp of this.computedEmployee ) {
                    if( selected_emp.user_id == emp.user_id ) ids.push(selected_emp.user_id);
                }
            }
        }

        return ids.length;
    }

    /**
     * 사람 선택
     */
     changeSelected(event, employee) : void {

        const checked : boolean = event.target.checked;

        if( this.tab == 'APPROVER' ) {

            if( this.approval_approver_favorite_modal_info.approver == null ) return;

            try {
                if( checked == true && this.approval_approver_favorite_modal_info.approver.filter(item => item.user_id == employee.user_id).length == 0 ) {
                    this.approval_approver_favorite_modal_info.approver.push(employee);
                }
                else if( checked == false ) {
                    while( this.approval_approver_favorite_modal_info.approver.filter(item => item.user_id == employee.user_id).length > 0 ) {
                        const target = this.approval_approver_favorite_modal_info.approver.filter(item => item.user_id == employee.user_id)[0];
                        this.approval_approver_favorite_modal_info.approver.splice(this.approval_approver_favorite_modal_info.approver.indexOf(target as any), 1);
                    }
                }
            } catch(e) {
                console.log(e);
            }
        }

        else if( this.tab == 'RECEIVE_REFERENCE' ) {

            if( this.approval_approver_favorite_modal_info.receive_reference == null ) return;

            try {
                if( checked == true && this.approval_approver_favorite_modal_info.receive_reference.filter(item => item.user_id == employee.user_id).length == 0 ) {
                    this.approval_approver_favorite_modal_info.receive_reference.push(employee);
                }
                else if( checked == false ) {
                    while( this.approval_approver_favorite_modal_info.receive_reference.filter(item => item.user_id == employee.user_id).length > 0 ) {
                        const target = this.approval_approver_favorite_modal_info.receive_reference.filter(item => item.user_id == employee.user_id)[0];
                        this.approval_approver_favorite_modal_info.receive_reference.splice(this.approval_approver_favorite_modal_info.receive_reference.indexOf(target as any), 1);
                    }
                }
            } catch(e) {
                console.log(e);
            }
        }

        this.$forceUpdate();
    }

    approverChecked(employee) {

        if( this.tab == 'APPROVER' ) {

            if( this.approval_approver_favorite_modal_info.approver == null ) return false;

            return this.approval_approver_favorite_modal_info.approver.filter(item => item.user_id == employee.user_id).length > 0;

        }
        else if( this.tab == 'RECEIVE_REFERENCE' ) {

            if( this.approval_approver_favorite_modal_info.receive_reference == null ) return false;

            return this.approval_approver_favorite_modal_info.receive_reference.filter(item => item.user_id == employee.user_id).length > 0;
        }

        return false;
    }

    /**
     * 모두삭제
     */
    deleteAllSelected() : void {
        if( this.tab == 'APPROVER' ) {
            this.approval_approver_favorite_modal_info?.approver?.splice(0, this.approval_approver_favorite_modal_info.approver.length);
        }
        else if( this.tab == 'RECEIVE_REFERENCE' ) {
            this.approval_approver_favorite_modal_info?.receive_reference?.splice(0, this.approval_approver_favorite_modal_info.receive_reference.length);
        }
        this.$forceUpdate();
    }

    /**
     * 순서 앞으로 당기기
     */
    up(select) : void {

        if( this.tab == 'APPROVER' ) {
            if( this.approval_approver_favorite_modal_info.approver == null ) return;

            const index = this.approval_approver_favorite_modal_info.approver.indexOf(select);
            const target_index = index - 1;
            const target_seq   = select.seq;
            
            const target = this.approval_approver_favorite_modal_info.approver[target_index];
            target.seq = target_seq;
            select.seq = select.seq - 1;

            this.approval_approver_favorite_modal_info.approver[target_index] = select;
            this.approval_approver_favorite_modal_info.approver[index] = target;
        }
        // 수신참조는 딱히 순서가 없음
        // else if( this.tab == 'RECEIVE_REFERENCE' ) {
        //     if( this.approval_approver_favorite_modal_info.receive_reference == null ) return;

        //     const index = this.approval_approver_favorite_modal_info.receive_reference.indexOf(select);
        //     const target_index = index - 1;
        //     const target_seq   = select.seq;
            
        //     const target = this.approval_approver_favorite_modal_info.receive_reference[target_index];
        //     (target as any).seq = target_seq;
        //     select.seq = select.seq - 1;

        //     this.approval_approver_favorite_modal_info.receive_reference[target_index] = select;
        //     this.approval_approver_favorite_modal_info.receive_reference[index] = target;
        // }

        this.$forceUpdate();
    } 

    /**
     * 순서 뒤로 밀기
     */
    down(select) : void {

        if( this.tab == 'APPROVER' ) {
            if( this.approval_approver_favorite_modal_info.approver == null ) return;

            const index = this.approval_approver_favorite_modal_info.approver.indexOf(select);
            const target_index = index + 1;
            const target_seq   = select.seq;
            
            const target = this.approval_approver_favorite_modal_info.approver[target_index];
            target.seq = target_seq;
            select.seq = select.seq + 1;

            this.approval_approver_favorite_modal_info.approver[target_index] = select;
            this.approval_approver_favorite_modal_info.approver[index] = target;
        }
        // 수신참조는 딱히 순서가 없음
        // else if( this.tab == 'RECEIVE_REFERENCE' ) {
        //     if( this.approval_approver_favorite_modal_info.receive_reference == null ) return;

        //     const index = this.approval_approver_favorite_modal_info.receive_reference.indexOf(select);
        //     const target_index = index + 1;
        //     const target_seq   = select.seq;
            
        //     const target = this.approval_approver_favorite_modal_info.receive_reference[target_index];
        //     (target as any).seq = target_seq;
        //     select.seq = select.seq + 1;

        //     this.approval_approver_favorite_modal_info.receive_reference[target_index] = select;
        //     this.approval_approver_favorite_modal_info.receive_reference[index] = target;
        // }
    } 

    /**
     * 삭제
     */
    remove(employee) : void {

        if( this.tab == 'APPROVER' ) {

            if( this.approval_approver_favorite_modal_info.approver == null ) return;

            while( this.approval_approver_favorite_modal_info.approver.filter(item => item.user_id == employee.user_id).length > 0 ) {
                const target = this.approval_approver_favorite_modal_info.approver.filter(item => item.user_id == employee.user_id)[0];
                this.approval_approver_favorite_modal_info.approver.splice(this.approval_approver_favorite_modal_info.approver.indexOf(target as any), 1);                
            }
        }
        else if( this.tab == 'RECEIVE_REFERENCE' ) {

            if( this.approval_approver_favorite_modal_info.receive_reference == null ) return;

            while( this.approval_approver_favorite_modal_info.receive_reference.filter(item => item.user_id == employee.user_id).length > 0 ) {
                const target = this.approval_approver_favorite_modal_info.receive_reference.filter(item => item.user_id == employee.user_id)[0];
                this.approval_approver_favorite_modal_info.receive_reference.splice(this.approval_approver_favorite_modal_info.receive_reference.indexOf(target as any), 1);                
            }
        }

        this.$forceUpdate();
    }

    getProfileImage(approver) {
        if( approver.user_pic != null ) {
            return `/app_images/${approver.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((approver.user_id ? approver.user_id : 0) / 10000)}/${approver.user_id}.jpg`;
    }

    /**
     * 유저 이미지 에러
     */
     userImageError(event) : void {
        event.target.src = require('@/assets/images/contents/ic_approval_user.png');
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetApprovalApproverFavoriteModalInfo?.({ show_modal : false });
    }
    
    async save() {

        // 결재선 이름 검사
        try {

            if( this.favorite_name == null || this.favorite_name.trim().length < 1 ) {
                alert("이름을 입력해주세요");
                $('#favorite_name').focus();
                return;
            }

            if( this.approval_approver_favorite_modal_info.approver == null || this.approval_approver_favorite_modal_info.approver.length < 1 ) {
                alert("결재자를 선택해주세요");
                return;
            }

            let body : any = null;
            if( this.approval_approver_favorite_modal_info.favorite_id != null && this.approval_approver_favorite_modal_info.favorite_id.trim().length > 0 ) {
                body = {
                    "favorite_id" : this.approval_approver_favorite_modal_info.favorite_id
                }
            }

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/approver/favorite/${this.favorite_name}`, API_METHOD.POST, body);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("결재자 즐겨찾기 이름 중복 검사 오류");
            }

            const favorite = response.data.data.favorite;

            if( favorite != null ) {
                this.is_duplicate = true;
                return;
            }

            this.approval_approver_favorite_modal_info.callback?.(this.favorite_name, this.approval_approver_favorite_modal_info.approver, this.approval_approver_favorite_modal_info.receive_reference);
            this.close();

        } catch(e) {
            alert("이름 중복검사 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }

        
    }

}
