
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import OrganizationRecursion2 from '@/components/organization/OrganizationRecursion2.vue';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        OrganizationRecursion2
    },
})
export default class OrganizationDepartmentSetting extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;

    /**
     * 부서 정보
     */
    get computedOrganization() : any {

        const copy_departments = JSON.parse(JSON.stringify(this.departments));

        let max_level = 0;
        for( const department of copy_departments ) {
            if( department.dept_id_array.length > max_level ) max_level = department.dept_id_array.length;
            department['departments'] = [];
            department['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.department_closed_map.get(department.dept_id);
            if( is_closed != null ) department['is_closed'] = is_closed;
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_level);
            const next_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_next_level); 

            for( const next_search_department of next_search_departments ) {
                const next_search_department_id = next_search_department.dept_id;
                next_search_department.level = search_next_level;

                for( const current_search_department of current_search_departments ) {
                    const current_search_department_id = current_search_department.dept_id;
                    current_search_department.level = search_level;

                    if( current_search_department.dept_id_array.indexOf(next_search_department_id) > -1 ) {
                        next_search_department.departments.push(current_search_department);
                    }

                }
            }
            
            search_level--;
        }

        const top_organizations = copy_departments.filter(item => item.dept_id_array.length == 1);
        if( top_organizations == null || top_organizations.length < 1 ) return null;
        const top_organization = top_organizations[0];

        return top_organization;
    }
    
    departments : any[] = [];
    search_query : string = "";

    department_closed_map : Map<number, boolean> = new Map();

    employees : any[] = [];

    async mounted() : Promise<void> {

        this.event_bus.$on('OrganizationDepartmentSettingResize', this.handleResize);

        $(".grp .leftBtn").click(function(){
            $(this).parent().parent("li").toggleClass("on");
        }); 

        await this.getTotalOrganizationData();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_box_height = $('#organization_setting .title_box').outerHeight();
        const title_box2_height = $('#organization_setting .title_box2').outerHeight();
        const content_top_height = $('#organization_setting .approval_content .filter_tap .content_top').outerHeight();

        // @ts-ignore
        $('#organization_department_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                           - (title_box2_height ? title_box2_height : 0)
                                           - (content_top_height ? content_top_height : 0)
        });

    }

    /**
     * 종합적인 조직도 데이터 조회
     */
    async getTotalOrganizationData() : Promise<void> {
        try {
            await this.getOrganization();
            await this.getOrganizationEmp();
        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 정보 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 부서 조회 중 오류 발생");
            }
            
            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 전체 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 부서 등록
     */
    add(target_department : any) : void {
        
        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "부서 추가",
            subtitle : "부서명",
            placeholder : "부서명을 입력해주세요",
            save : async(name, selected_radio, check_flag) => {
                try {

                    await this.getOrganization();

                    target_department.is_closed = false;
                    this.department_closed_map.set(target_department.dept_id, false);

                    const new_department = JSON.parse(JSON.stringify(target_department));
                    new_department.dept_name = name;

                    // 같은 레벨에 있는 부서 수를 구해서 dept_seq 세팅
                    let same_level_departments = JSON.parse(JSON.stringify(this.departments));
                    for( const id of target_department.dept_id_array ) {
                        same_level_departments = same_level_departments.filter(item => item.dept_id_array.indexOf(id) > -1);
                    }
                    same_level_departments = same_level_departments.filter(item => item.dept_id_array.length == new_department.dept_id_array.length);
                    new_department.dept_seq = same_level_departments.length;

                    // insert API
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.POST, { 
                        "dept_name"     : new_department.dept_name,
                        "dept_id_array" : new_department.dept_id_array,  
                        "dept_seq"      : new_department.dept_seq,
                        "team_sync"     : check_flag, 
                    });

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                        throw new Error("조직도 부서 정보 추가 중 오류 발생");
                    }

                    this.getTotalOrganizationData();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "조직도 부서 추가 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            },
            has_check : (target_department.dept_id == 0 && this.teamSyncChecked() == false) ? true : false,
            check_text : "부서 달력 생성"
        });

    }

    /**
     * 부서 수정
     */
    update(target_department : any) : void {
        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "부서 수정",
            subtitle : "부서명",
            placeholder : "부서명을 입력해주세요",
            content : target_department.dept_name,
            save : async(name, selected_radio, check_flag) => {
                try {

                    await this.getOrganization();

                    // update api
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/${target_department.dept_id}`, API_METHOD.PUT, { "dept_name" : name, "team_sync" : check_flag, }, false);

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                        throw new Error("조직도 부서 정보 수정 중 오류 발생");
                    }

                    this.getTotalOrganizationData();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "조직도 부서 수정 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            },
            has_check : (target_department.team_id > 0 && this.teamSyncChecked() == false),
            check_text : "부서 달력 수정"
        });
    }

    /**
     * 부서 삭제
     */
    remove(target_department : any) : void {
        this.hodu_show_dialog('cancel', `'${target_department.dept_name}' 부서를 삭제하시겠습니까?`, ['아니오', '예'], [
            () => {},
            async(check_flag) => {
                try {
                    // delete api
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/${target_department.dept_id}`, API_METHOD.DELETE, { "team_sync" : check_flag });

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("조직도 부서 정보 삭제 중 오류 발생");
                    }

                    await this.getTotalOrganizationData();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "조직도 부서 삭제 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        ], (target_department.team_id > 0 && this.teamSyncChecked() == false), "부서 달력 삭제");
    }

    /**
     * 부서 초기화 진행 (그룹환경 불러오기)
     */
    reset() : void {
        this.hodu_show_dialog("alert", "그룹환경 불러오기를 진행하면 기존에 입력한\n부서 및 직원의 부서, 직원의 기본결재자 정보가 전부 초기화 됩니다\n정말로 그룹환경 불러오기를 진행하시겠습니까?", ['아니오', '예'], [
            () => this.getTotalOrganizationData(),
            () => {
                this.hodu_show_dialog("alert", "최종 확인입니다\n정말로 그룹환경 불러오기를 진행하시겠습니까?", ['아니오', '예'], [
                    () => this.getTotalOrganizationData(),
                    async() => {
                        try {

                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/set_team_default`, API_METHOD.POST);

                            console.log(response);
                            
                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("그룹환경 불러오기 진행 중 오류 발생");
                            }

                            await this.getTotalOrganizationData();

                        } catch(e) {
                            this.hodu_error_process(e, false, false, true);
                            this.hodu_show_dialog('cancel', "그룹환경 불러오기 진행 중 오류 발생", ['확인']);
                        }
                    },
                ]);
                
            },
        ]);
    }

    /**
     * 팀&부서 동기화 체크
     */
    teamSyncChecked() : boolean {
        const feature = this.get_group_feature(this.scope_group_id);
        if( feature == null || feature.team_sync == null || feature.team_sync.enable == null ) return false;
        return feature.team_sync.enable;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#organization_department_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
